import React, { Component,useEffect,useState} from 'react'
import { BrowserRouter, useNavigate,useLocation,Link, Routes, Route,Outlet } from "react-router-dom" //引入路由模块
import { Form, Input, message,Button, Checkbox } from 'antd';
import "./home.css"
import { login } from "../api/api.js"; //logo列表
export default function Home() {

//   login(`page=1&pageSize=10`).then((res)=>{
//   console.log(res)
// })
const navigate = useNavigate();
const error = (a) => {
  message.error(a);
};

//使用生命周期的钩子
// console.log(useEffect)
useEffect(()=>{
  // console.log("挂载完成,更新完成")
  let totoken = window.localStorage.getItem("token");
  // console.log(totoken)
  if(totoken!=null && totoken!=""){
    // alert(1)
    // console.log(1)
    navigate(`/background/activity`)
  
  }

},[])

let [log,slog]=useState(false) //加载状态
const success = () => {
  message.success('登陆成功');
};
  const onFinish = (values) => {
    // console.log('Success:', values);
    slog(true)
    // return
      login(`name=${values.username}&psw=${values.password}`).then((res)=>{
      // console.log(res)
      if(res.code==0){
        success()
        slog(false)
        window.localStorage.setItem("token", res.data.token)
        navigate(`/background/activity`)
      }else{
        slog(false)
        error("账号或密码错误请重新输入")
      }
      })

    // setTimeout(()=>{
    // slog(false)
    // success()
    //   navigate(`/background/configuration`)
    // },2000)
    
  };

  return (
    <div className='bacc'>
      <div className='obscuration'>
      <div className='input'>
    <div className='titles'>题库后台管理</div>
    <img src={require('../assets/dl.png')} className="icons"/>
    <Form
    className='form'
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      onFinish={onFinish}
  
    >
      <Form.Item
        label="账号"
        name="username"
        rules={[
          {
            required: true,
            message: '账号不能为空',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="密码"
        name="password"
        rules={[
          {
            required: true,
            message: '密码不能为空',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>



      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary"  loading={log} htmlType="submit" className='btn'>
          登录
        </Button>
      </Form.Item>
    </Form>
    </div>
    </div>
    </div>
  );
};