import React, { Component } from 'react'
import "./background.css"
import {useNavigate,useLocation} from "react-router-dom";
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { Layout, Menu,Button } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  FormOutlined,
  MailOutlined,
  UnorderedListOutlined,
  HddOutlined
} from '@ant-design/icons';
import { BrowserRouter, Link, Routes, Route,Outlet } from "react-router-dom" //引入路由模块
import Na from "./subpage/configuration"
import Nas from "./subpage/rankingList"
import Nass from "./subpage/manage"
import Bna from "./subpage/compile"
import Cs from "./subpage/ces"
import Activity from "./subpage/activity"
import Theme from "./subpage/theme"
import Details from "./subpage/details"


const { SubMenu } = Menu;
const { Header, Sider, Content } = Layout;
// import { useHistory } from 'react-router-dom'
// const location = useLocation()
export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()}  location = {useLocation()}/>;
  };
  
 class background extends Component {
  state = {
    collapsed: false,
    huodonglb:[
      {
        name:"活动一",
        id:1,
      },
      {
        name:"活动二",
        id:2,
      },
      {
        name:"活动三",
        id:3,
      },      {
        name:"活动四",
        id:4,
      },      {
        name:"活动五",
        id:5,
      },
    ]
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  componentDidMount(){ //在这个生命周期里面才能获取到元素

    }
    
  render() {
    return (
      <div style={{height:'100vh',minWidth:"1300px"}}>
      <Layout style={{height:"100vh",minHeight:"650px"}}>
        <Sider style={{overflowY:"auto"}} trigger={null} collapsible collapsed={this.state.collapsed}>
          <div className="logo" ><div className='bttit'>题库</div></div>
          <Menu theme="dark" mode="inline" defaultOpenKeys={()=>{
 if(this.props.location.pathname.split("/")){
if(this.props.location.pathname.split("/")[2]=="details"){
return ["hd"]
  }else if(this.props.location.pathname.split("/")[2]=="manage"){
    return ['tk']
  }else if(this.props.location.pathname.split("/")[2]=="activity"){
    return ['hd']
  }
 }else{
  return ['hd']
 }

          }} defaultSelectedKeys={()=>{
            if(this.props.location.pathname.split("/")){
            //   // console.log(this.props.location.search.split("=")[1])
            //   if(this.props.location.pathname.split("/")[3]=="configuration"){
            //     // console.log(777)
            //     if(this.props.location.search.split("=")[1]){
            //       return [`${this.props.location.search.split("=")[1]}`]
            //     }else{
                  
            //       this.props.navigate("/background/activity")
            //     }
            //     // return ['1']
            // }else if(this.props.location.pathname.split("/")[3]=="rankingList"){
            //   if(this.props.location.search.split("=")[1]){
            //     return [`${this.props.location.search.split("=")[1]}`]
            //   }else{
                
            //     this.props.navigate("/background/activity")
            //   }
            //   }else if(this.props.location.pathname.split("/")[2]=="activity"){
            //     return ['4']
            //   }else 
            if(this.props.location.pathname.split("/")[2]=="manage"){
                return ['3']
              }else{
                return ['4']
              }
            //  }else{
            //   return ['1']
             }
          }}  onClick={(e)=>{
            e.keyPath[1]=="pz" ? this.props.navigate(`/background/configuration?key=${e.key}`) : e.keyPath[1]=="ph" ? this.props.navigate(`/background/rankingList?key=${e.key}`) : e.keyPath[1]=="tk" ? this.props.navigate("/background/manage") : this.props.navigate("/background/activity")
          }}>
       
            {/* <Menu.Item key="1" icon={<UserOutlined />}>   
              题库配置
            </Menu.Item> */}
           <SubMenu key="hd" icon={<FormOutlined />} title="活动配置">
            <Menu.Item key="4">活动列表</Menu.Item>
          </SubMenu>
          {/* <SubMenu key="pz" icon={<FormOutlined />} title="题目配置">
            {this.state.huodonglb.map((b,i)=>{return(
            <Menu.Item key={`pz${b.id}`}>{b.name}</Menu.Item>

            )})}
          </SubMenu> */}
            {/* <Menu.Item key="2" icon={<VideoCameraOutlined />}>
              排行榜
            </Menu.Item> */}
            {/* <SubMenu key="ph" icon={<UnorderedListOutlined />} title="数据看板">
            {this.state.huodonglb.map((b,i)=>{return(
            <Menu.Item key={`ph${b.id}`}>{b.name}</Menu.Item>

            )})}
          </SubMenu> */}
            {/* <Menu.Item key="3" icon={<UploadOutlined />}>
              题库管理
            </Menu.Item> */}
           <SubMenu key="tk" icon={<HddOutlined />} title="题库管理">
            <Menu.Item key="3">题库列表</Menu.Item>
          </SubMenu>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: this.toggle,
            })}
            <div className='portraits'>
              <div className='portrait'>
                <img src={require("../../assets/gly.png")} />
              </div>
              <div style={{marginLeft:"2px",fontWeight:"bold"}}>管理员</div>
              <div className='tcbtn' onClick={()=>{
               window.localStorage.clear();
               window.location.reload();
              }}>退出</div>
            </div>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
       

            {/* <BrowserRouter> */}
        <Routes>
            {/* <Route path='/configuration/*' element={<Na/>}/> */}
            {/* <Route path='/compile' element={<Bna/>}/> */}
            {/* <Route path='/rankingList' element={<Nas/>}/> */}
            <Route path='/manage' element={<Nass/>}/>
            <Route path='/cs' element={<Cs/>}/>
            <Route path='/activity' element={<Activity/>}/>
            {/* <Route path='/theme' element={<Theme/>}/> */}
            <Route path='/details/*' element={<Details/>}/>


            
        </Routes>
      {/* </BrowserRouter> */}
 <Outlet/>

    
          </Content>
        </Layout>
      </Layout>
      </div>
    )
  }
}
// ReactDOM.render(<SiderDemo />, mountNode);
export default withNavigation(background)