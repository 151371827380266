import React, { Component, useEffect, useState } from 'react'
import { Divider, Table, Modal, Button, ConfigProvider, Form, Input,Col,Space} from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined, MinusCircleOutlined,PlusCircleOutlined,PlusOutlined } from '@ant-design/icons';
import { BrowserRouter, useNavigate, useLocation, Link, Routes, Route, Outlet } from "react-router-dom" //引入路由模块
import zhCN from 'antd/lib/locale/zh_CN';
import './configuration.css'
export default function Question() {
  const navigate = useNavigate();

  let [gao, sgao] = useState(500)
  let [gaos, sgaos] = useState(500)
  window.onresize = function () {
    let boss = document.getElementsByClassName("kgd")[0].offsetHeight - 160
    let bosss = document.getElementsByClassName("kgd")[0].offsetHeight - 270
    sgao(boss)
    sgaos(bosss)
  }//设置表格的高度


  //使用生命周期的钩子
  // console.log(useEffect)
  useEffect(() => {
    // console.log("挂载完成,更新完成")

    setTimeout(() => {
      let boss = document.getElementsByClassName("kgd")[0].offsetHeight - 160
      let bosss = document.getElementsByClassName("kgd")[0].offsetHeight - 270
      sgao(boss)
      sgaos(bosss)
    }, 1)
    hqlb(1)

  }, [])








  const columns = [
    {
      title: `序号`,
      align: "center",
      width: 70,
      render: (e, x, i) => (
        <div>{i + 1}</div>
      )
      // dataIndex: 'name',
    },
    {
      title: "类型",
      dataIndex: '',
      width: 100,
      filters: [
        {
          text: '单选',
          value: 'dx',
        },
        {
          text: '多选',
          value: 'dxx',
        },
        {
          text: '判断',
          value: 'pd',
        },
      ],
      // filterDropdown:(e)=>{
      //   console.log(e)
      // },
      // onFilter: (value, record) =>{console.log(value,record)},

      // onFilter: (value, record) => {console.log(value)},

      // sorter: (a, b) => a.name.length - b.name.length,
      // sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      ellipsis: true,


      render: (e) => (
        <div>{e.name.length > 20 ? '多选' : "单选"}</div>
      )
    },
    {
      title: '问题',
      // align:"center",

      dataIndex: 'name',
      // width: 150,
    },
    {
      title: '编辑',
      align: "center",
      dataIndex: '',
      width: 130,
      key: "name",
      // sorter: true,
      render: (a, b, c) => (
        <div size="middle" >
          <Button type="primary" ghost onClick={() => { stc(true); stcnr(a) }}>查看选项</Button>&nbsp;
        </div>
      ),

    },

  ];//定义表格的title




  let [sj, usesj] = useState([])//所有数据
  let [jz, usejz] = useState(true) //加载状态
  let [ys, uys] = useState([1]) //页数是否存储过


  let [tc, stc] = useState(false) //  题目预览弹窗显示或隐藏
  let [tcnr, stcnr] = useState([]) //  题目预览弹窗内容

  function hqlb(i) {

    //  return
    let xsj = [
      {
        ke: 1,
        name: `王佳规范的股份多付付付付付付付付付付付付付付付付付付付付发给乐`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 2,
        name: `王佳风格的广泛地鬼地方个师傅的乐`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 3,
        name: `王焚烧发电撒个梵蒂冈发动机各环节的慷慨福建省克己奉公佳乐`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 4,
        name: `王范德萨发噶数据库发过来的世界观看了大家可积分卡拉斯加发快递佳乐`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 5,
        name: `王佳乐发的设计开发框架的思考了附近的历史就分开了电视剧分开了`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 6,
        name: `王佳乐`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 7,
        name: `王佳乐`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 8,
        name: `王佳乐`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 9,
        name: `王佳规范的股份多付付付付付付付付付付付付付付付付付付付付发给乐`,

      },
      {
        ke: 10,
        name: `王佳规范的股份多付付付付付付付付付付付付付付付付付付付付发给乐`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },


    ]
    let xsjs = [
      {
        ke: 11,
        name: `王付付发乐`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 12,
        name: `王傅的乐`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 13,
        name: `王焚乐`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 14,
        name: `发过来`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 15,
        name: `的`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 16,
        name: `乐`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 17,
        name: `王`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 18,
        name: `王`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 19,
        name: `王`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },
      {
        ke: 20,
        name: `乐`,
        option: [
          '我是选项A啦啦啦啦啦啦啦啦啦啦',
          '我是选项B啦啦啦啦啦啦啦啦啦啦',
          '我是选项C啦啦啦啦啦啦啦啦啦啦',
          '我是选项D啦啦啦啦啦啦啦啦啦啦',
          '我是选项E啦啦啦啦啦啦啦啦啦啦',
        ],
        da: "A",
        zt: "no",
      },


    ]
    if (i == 1) {
      usesj(xsj)
    } else {
      usesj(xsjs)

    }

    // 必须用深拷贝来改变赋值
    // let sjs = JSON.parse(JSON.stringify(sj))
    // console.log(sj)
    // usesj(sjs)  
    setTimeout(() => {
      usejz(false)
    }, 1000)

  }

  // 筛选或分页的时候触发
  function onChanges(e, i, s, c) {
    // console.log(e,i,s,c)
    if (c.action == "paginate") {
      // let ysj=[1]
      ssel([])
      // if(ys.indexOf(e.current)==-1){
      hqlb(e.current)
      //   ys.push(e.current)
      //   uys(ys)
      //   console.log(ys)
      // }else{
      //   usejz(false)
      // }
      // console.log(666,e.current,i)
    } else {
      // console.log("筛选")
      usejz(false)
      // console.log(66666,sj)
    }
  }
  let [sel, ssel] = useState([]) //选中的数据
  // let []=useState()
  //多选是的时候触发
  function onSelectChange(i, w) {
    // console.log(i,w)
    ssel(i)


  }

  // 点击确认按钮
  function start() {
    // console.log(sel)
    usejz(true)
    setTimeout(() => {
      usejz(false)
      navigate(`/background/configuration`, { replace: true, state: "true" })
    }, 1000);
  }



  const rowSelection = {
    selectedRowKeys: sel,
    onChange: onSelectChange,
    getCheckboxProps: (e) => ({
      disabled: e.zt === "no"
    })
  };








  return (
    <Form
      onFinish={(values) => {
        console.log(values);
      }}
      style={{width:'500px'}}
    >
      <Form.List name="users">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <div key={field.key} style={{width:'500px'}}>
                <br />
                <br />

              
                <div style={{display:'flex',alignItems:'center',width:'500px'}}>

                
                <Form.Item
style={{width:'500px'}}
                  {...field}
                  name={[field.name, 'name']}
                  fieldKey={[field.fieldKey, 'name']}
                  rules={[{ required: true, message: `公司${field.name+1}不能为空` }]}
                  label={`公司${field.name+1}:`}
                >
                  <Input placeholder={`请输入公司${field.name+1}`} />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} style={{marginLeft:'5px',position: 'relative',top:'-5px'}}/>
                </div>
                <Col span={20} offset={3} style={{ color: 'red',}}>
                <Form.List name={[field.name, 'hobbies']}>
                  {(hobbyFields, { add: addHobby, remove: removeHobby }) => (
                    <>
                      {hobbyFields.map((hobbyField) => (
                        <div key={hobbyField.key} style={{display:'flex',alignItems:'center',}}>
                         
                            <Form.Item
                              {...hobbyField}
                              name={[hobbyField.name, 'hobby']}
                              fieldKey={[hobbyField.fieldKey, 'hobby']}
                              rules={[{ required: true, message: `部门${hobbyField.name+1}不能为空` }]}
                              label={`部门${hobbyField.name+1}:`}
                            >
                              <Input placeholder={`请输入部门${hobbyField.name+1}`} />
                            </Form.Item>
                            {/* <Button onClick={() => removeHobby(hobbyField.name)}>删除此部门</Button> */}
                            <MinusCircleOutlined onClick={() => removeHobby(hobbyField.name)} style={{marginLeft:'5px',position: 'relative',top:'-5px'}} />
                          
                        </div>
                      ))}
                      <Col span={20} offset={5} >
                      <Button onClick={() => addHobby()} >+新增部门</Button>
                      </Col>
                      {/* <PlusCircleOutlined  onClick={() => addHobby()}/>      */}
                    </>
                    
                  )}
                  
                </Form.List>
                </Col>
                {/* <MinusCircleOutlined onClick={() => remove(field.name)} /> */}
                {/* <Button onClick={() => remove(field.name)}>删除此公司</Button> */}
              </div>
            ))}
            <br />
            <Col span={20} offset={3} >

            <Button onClick={() => add()} style={{width:'300px'}}>+ 新增公司</Button>
            </Col>
                

            <br />
            <br />
          </>
        )}
      </Form.List>
      <Button htmlType="submit">保存</Button>
    </Form>
  )
}
