import {get, post } from "../utils/request.js"

//  获取配置作品列表
export function getQuestionsLists(param) {
    return post(`/getQuestionsLists`, param)
}

//  配置列表作品新增
export function questionCreate(param) {
    return post(`/questionCreate`, param)
}

//  获取题目详情
export function questionContent(param) {
    return post(`/questionContent`, param)
}

//  配置列表作品修改
export function questionUpdate(param) {
    return post(`/questionUpdate`, param)
}

//  配置列表作品删除
export function questionDelete(param) {
    return post(`/questionDelete`, param)
}


//  获取题库列表
export function getWarehouseLists(param) {
    return post(`/getWarehouseLists`, param)
}
//  题库列表作品删除
export function warehouseDelete(param) {
    return post(`/warehouseDelete`, param)
}

//  获取排行榜列表接口
export function getRankList(param) {
    return post(`/getRankList`, param)
}

//  清空排行榜列表接口
export function clearRank(param) {
    return post(`http://h5api.xiaoxxx.cn/h5/ANT_RT/clearRank`, param)
}


//  题库列表导入配置列表
export function Import(param){
    return post(`/import`, param)
}

//  题库文件导入
export function excelImport(param){
    return post(`/excelImport`, param)
}

//  登录
export function login(param){
    return post(`/login`, param)
}

// 修改配置的分数
export function questionsScoreUpdate(param){
    return post(`/questionsScoreUpdate`, param)
}

// 获取活动规则
export function getExplain(param){
    return post(`/getExplain`, param)
}

// 修改活动规则
export function explainUpdate(param){
    return post(`/explainUpdate`, param)
}

// 活动列表
export function getActivityLists(param){
    return post(`/getActivityLists`, param)
}


// 新增活动
export function ActivityCreate(param){
    return post(`/ActivityCreate`, param)
}

// 修改活动
export function ActivityUpdate(param){
    return post(`/ActivityUpdate`, param)
}

// 活动的参与人数
export function getData(param){
    return post(`/getData`, param)
}

// 数据看版答题详情
export function userLogs(param){
    return post(`/userLogs`, param)
}



// 是否启用活动
export function activityDrawStatus(param){
    return post(`/activityDrawStatus`, param)
}

// 跳转信息配置
export function activityDraw(param){
    return post(`/activityDraw`, param)
}

// 获取背景图
export function getBackground(param){
    return post(`/getBackground`, param)
}

// 修改背景图
export function backgroundUpdate(param){
    return post(`/backgroundUpdate`, param)
}

// 导出数据
export function exports(param){
    return get(`/export`, param)
}

// 获取活动详情
export function getActivityInfo(param){
    return post(`/getActivityInfo`, param)
}

// 保存基本配置
export function activityBasicUpdate(param){
    return post(`/activityBasicUpdate`, param)
}


// 抽奖信息提交
export function updateDeploy(param){
    return post(`/updateDeploy`, param)
}

// 获取奖品列表
export function getDrawList(param){
    return post(`/getDrawList`, param)
}


// 修改抽奖信息
export function updateDraw(param){
    return post(`/updateDraw`, param)
}

// 新增抽奖信息
export function createDraw(param){
    return post(`/createDraw`, param)
}

// 获取中奖记录列表
export function getDrawLog(param){
    return post(`/getDrawLog`, param)
}

// 获取证书设置
export function certificateGet(param){
    return post(`/certificateGet`, param)
}

// 修改证书设置
export function certificateUpdate(param){
    return post(`/certificateUpdate`, param)
}


// 获取分享设置
export function posterGet(param){
    return post(`/posterGet`, param)
}

// 修改分享设置
export function posterUpdate(param){
    return post(`/posterUpdate`, param)
}



//获取部门列表
export function getDepartmentLists(param){
    return post(`/getDepartmentLists`, param)
}