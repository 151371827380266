// import { } from 'antd';
import React, { Component, useEffect, useState } from 'react'
import zhCN from 'antd/lib/locale/zh_CN';
import moment from "moment";
import "moment/locale/zh-cn";
// moment.locale("zh-cn");
import { Menu, Switch, Form,Spin, DatePicker, Input, Button, ConfigProvider, message, Space, Radio, Breadcrumb, Checkbox, Row, Col } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
// import "./details.css"
import { getActivityInfo } from "../../../api/api.js"; //获取活动详情
import { activityBasicUpdate } from "../../../api/api.js"; //保存基本配置
import { ActivityUpdate } from "../../../api/api.js"; //保存基本配置
import { BrowserRouter, Link, Routes, Route,Outlet } from "react-router-dom" //引入路由模块
// import Na from "./configuration"

import Nas from "./rankingList"

import Record from "./set/record"

// import {  } from '@ant-design/icons';
import { useNavigate, useLocation } from "react-router-dom" //引入路由模块
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const formItemLayout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 20,
  },
};
export default function Details() {
  const navigate = useNavigate();
  const location = useLocation()
  let totoken = window.localStorage.getItem("token");
  // console.log(totoken)
  if (totoken == null || totoken == "") {
    // alert(1)
    navigate("/")
  }
  const error = (a) => {
    message.error(a);
  };
  const success = (e) => {
    message.success(e);
  };


  




    // 获取页面参数
    function hqcorid(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      // console.log(666666,arr)
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      // console.log("1234564,", obj[key]);
      return obj[key];
    }
    let pageid=hqcorid("keid",location.search)
let paname=hqcorid("name",location.search)
//使用生命周期的钩子

useEffect(()=>{
  // console.log(location.pathname.split("/")[4])
//   acquire()
},[])

function moude(){
  console.log(12)
         if(location.pathname.split("/")[4]=="rankingList"){
          return 'a'
         }  
         if(location.pathname.split("/")[4]=="record"){
          return 'b'
         } 
}

let [module,smodule]=useState(moude())//题目模块顺序数据

  // 模块切换
  function onChange(e) {
    if(e.target.value=="a"){
      navigate(`/background/details/database/rankingList?keid=${pageid}&name=${paname}`)
    }
    if(e.target.value=="b"){
      navigate(`/background/details/database/record?keid=${pageid}&name=${paname}`)
    }
    // console.log(`radio checked:${e.target.value}`);
    smodule(e.target.value)
  }


 

  return (
    
    <div className='bodys' style={{height:"100%"}}>
        {/* <Button style={{marginBottom:0,marginTop:4}} type="primary"  ghost onClick={()=>{
            navigate(`/background/details/database/rankingList?keid=${pageid}&name=${paname}`)
          }} >用户信息</Button>
          <Button style={{marginBottom:0,marginTop:4,marginLeft:4}} type="primary"  ghost onClick={()=>{
            navigate(`/background/details/database/record?keid=${pageid}&name=${paname}`)
          }} >中奖信息</Button> */}
    <Radio.Group onChange={onChange}  value={module} style={{marginTop:'8px'}}>
      <Radio.Button value="a">用户数据</Radio.Button>
      <Radio.Button value="b" style={{ marginLeft: 20 }}>中奖记录</Radio.Button>

    </Radio.Group>

    <div className='boss'>
        
    
<Routes>

 <Route path='/record' element={<Record/>}/>

    <Route path='/rankingList' element={<Nas/>}/>
    {/* <Route path='/manage' element={<Nass/>}/> */}
    {/* <Route path='/cs' element={<Cs/>}/> */}
    {/* <Route path='/activity' element={<Activity/>}/> */}
    {/* <Route path='/theme' element={<Theme/>}/> */}
    {/* <Route path='/details' element={<Details/>}/> */}
</Routes>
<Outlet/>
</div>
    </div>
   
  );
}

