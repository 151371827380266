import React, { Component,useEffect,useState} from 'react'
import { BrowserRouter, useNavigate,useLocation,Link, Routes, Route,Outlet } from "react-router-dom" //引入路由模块
import zhCN from 'antd/lib/locale/zh_CN';
import './configuration.css'
import { Divider, Select,Table ,Modal,Button,ConfigProvider, Popconfirm, message  } from 'antd';
import Question from "./ces"
import { getWarehouseLists } from "../../../api/api.js"; //获取列表
import { warehouseDelete } from "../../../api/api.js"; //删除接口
import { excelImport } from "../../../api/api.js"; //文件导入
const { Option } = Select;
export default function Na() { 
  
const navigate = useNavigate();
let totoken = window.localStorage.getItem("token");
// console.log(totoken)
if(totoken==null || totoken==""){
  // alert(1)
  navigate("/")
}

const location = useLocation()
const columns = [
  {
    title: `序号`,
    align:"center",
    width: 70,
    render:(e,x,i)=>(
      <div>      {i+1!=10 && dqym-1==0 && (<div>{i+1}</div>) }
      {i+1!=10 && dqym-1!=0 && (<div>{dqym-1}{i+1}</div>) }
      {i+1==10 && (<div>{dqym}0</div>) }</div>
    )
    // dataIndex: 'name',
  },
  {
    title: '主题',
    align:"center",
    width: 100,
    dataIndex: 'theme',

    // width: 150,
  },
  {
    title:"类型",
    dataIndex: '',
    width: 100,
    filters: [
      {
        text: '单选',
        value: '1',
      },
      {
        text: '多选',
        value: '2',
      },
      {
        text: '判断',
        value: '3',
      },
    ],
    // filterDropdown:(e)=>{
    //   console.log(e)
    // },
    // onFilter: (value, record) =>{console.log(value,record)},

    // onFilter: (value, record) => {console.log(value)},

    // sorter: (a, b) => a.name.length - b.name.length,
    // sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
    ellipsis: true,


    render:(e)=>(
      <div>{e.type==1 ? '单选' : e.type==2 ? "多选" : "判断"}</div>
    )
  },
  {
    title: '问题',
    // align:"center",

    dataIndex: 'title',
    // width: 150,
  },
  {
    title: '编辑',
    align:"center",
    dataIndex: '',
    width: 250,
    key: "name",
    // sorter: true,
    render: (a,b,c) => (
      <div size="middle" >
          <Button type="primary"  ghost onClick={()=>{stc(true);stcnr(a)}}>查看选项</Button>&nbsp;
          <Popconfirm
    title={`确认删除吗?`}
    onConfirm={()=>{
      warehouseDelete(`id=${a.id}`).then((res)=>{
        // console.log(res)
        if(res.code==0){
          refresh()
          success("删除成功")
        }
      })
    }}
    onCancel={()=>{}}
    okText="确认"
    cancelText="取消"
  >
          <Button type="primary" danger ghost onClick={()=>{}} >删除</Button>
          </Popconfirm>
      </div>
    ),
    
  },

];//定义表格的title

const success = (e) => {
  message.success(e);
};
const error = (a) => {
  message.error(a);
};
  let [gao,sgao]=useState(500)
    window.onresize = function(){
        let boss=document.getElementsByClassName("body")[0].offsetHeight-158
        sgao(boss)
    }//设置表格的高度

// 刷新页面
function  refresh() {

hqlb(dqym)
usejz(true);
ssel([])
}   


//使用生命周期的钩子
// console.log(useEffect)
useEffect(()=>{
    // console.log("挂载完成,更新完成")
    window.sessionStorage.clear(); //清除缓存
    setTimeout(()=>{
        let boss=document.getElementsByClassName("body")[0].offsetHeight-158
    sgao(boss)},1)
    hqlb(1)
    
// console.log(location) 
return function(){
  // console.log("即将卸载")
  window.sessionStorage.clear(); //清除缓存

  }
  
  },[])

 let [sj,usesj]=useState([])//所有数据
 let [jz,usejz]=useState(true) //加载状态
 let [ys,uys]=useState([1]) //页数是否存储过


let [tc,stc]=useState(false) //  题目预览弹窗显示或隐藏
let [tcnr,stcnr]=useState([]) //  题目预览弹窗内容

let [math,smath]=useState({})//页数及数量

// var leix=""
// var zhut=""
// window.sessionStorage.setItem("leix", "");
// console.log(window.sessionStorage.getItem("leix"))
function hqlb(i){

//  return
// 
    // if(i==1){
    //   usesj(xsj)  
    // }else{
    //   usesj(xsjs)  

    // }
    getWarehouseLists(`page=${i}&pageSize=10&type=${window.sessionStorage.getItem("leix")==null ? "" : window.sessionStorage.getItem("leix")}&theme=${window.sessionStorage.getItem("zhut")==null ? "" : window.sessionStorage.getItem("zhut")}`).then((res)=>{
      // console.log(res)
      usesj(res.data.list)  
      smath(res.data)
      usejz(false)
      if(res.data.list.length==0 && i!=1){
        hqlb(1)
      }
    })
// leix=leix

// 必须用深拷贝来改变赋值
// let sjs = JSON.parse(JSON.stringify(sj))
// console.log(sj)
// usesj(sjs)  
// setTimeout(()=>{
// usejz(false)
// },1000)

}

let [dqym,sdqym]=useState(1) //当前页面的索引

// 筛选或分页的时候触发
function onChanges(e,i,s,c){
  // console.log(e,i,s,c)
  if(c.action=="paginate"){
    sdqym(e.current)
// let ysj=[1]
ssel([])

// if(ys.indexOf(e.current)==-1){
  hqlb(e.current)
//   ys.push(e.current)
//   uys(ys)
//   console.log(ys)
// }else{
//   usejz(false)
// }
// console.log(666,e.current,i)
  }else{
    // console.log("筛选")
    // console.log(i)
    if(i[2]==null){
      window.sessionStorage.setItem("leix", "");
      
    }else{
// leix=i[2].toString()
window.sessionStorage.setItem("leix", i[2].toString());

    }
    sdqym(1)
    hqlb(1)
    ssel([])
    // console.log(66666,i[2].toString())
  }
}
let [sel,ssel]=useState([]) //选中的数据

let [scjz,sscjz]=useState(false) //选中的数据
// let []=useState()
//多选是的时候触发
function onSelectChange(i,w){
  // console.log(i,w)
  ssel(i)


}

// 批量导入
function batch(){
        //点击添加图片按钮，触发type:"file"的input标签
        let fileDom = document.getElementById("file");
        fileDom.click();

}
function getPicture(e){
  // console.log(e.target.files[0])
  let forms = new FormData();
  forms.append("file", e.target.files[0]);
  // return
  excelImport(forms).then((res)=>{
    console.log(res)
    if(res.code==0){
      refresh()
      success("导入成功")
      
    }
  }) .catch(function () {error("未知错误导入失败,请检查文件格式，稍后在试")})
  
}


// 排序
      function handleChange(value) {
        // console.log(leix)
        // console.log(`selected ${value}`);
        if(value!=undefined){
          window.sessionStorage.setItem("zhut", value);
        }else{
          window.sessionStorage.setItem("zhut", "");

        }

        

            sdqym(1)
            hqlb(1)
            ssel([])
            usejz(true); 
            
      }
// 点击删除按钮
function start(){
    // console.log(sel)
    // console.log(sel.toString())
  // return
  warehouseDelete(`id=${sel.toString()}`).then((res)=>{
        // console.log(res)
        if(res.code==0){
          refresh()
          success("批量删除成功")
          
        }
      }
      )



sscjz(true)
  setTimeout(() => {
    sscjz(false)

  }, 1000);
}



const rowSelection = {
  selectedRowKeys:sel,
  onChange:onSelectChange,

}; 
const hasSelected = sel.length > 0;
  return (
      <div className='body'>
      {/* 弹窗 */}
        <Modal
          title={tcnr.title}
          style={{ top: '10%' }}
          visible={tc}
          closable={false}
          footer={[<div key={1} style={{padding:0}}>
            <Button type="primary" style={{  }} onClick={() => stc(false)}>
            关闭
          </Button>  
          </div>]}
        >
    

                {tcnr.option_a!=null && <div  style={{marginBottom:"6px"}}>A:{tcnr.option_a}</div>} 
                {tcnr.option_b!=null && <div  style={{marginBottom:"6px"}}>B:{tcnr.option_b}</div>}       
                {tcnr.option_c!=null && <div  style={{marginBottom:"6px"}}>C:{tcnr.option_c}</div>}       
                {tcnr.option_d!=null && <div  style={{marginBottom:"6px"}}>D:{tcnr.option_d}</div>}       
                {tcnr.option_e!=null && <div  style={{marginBottom:"6px"}}>E:{tcnr.option_e}</div>}       
  

        <div style={{marginTop:"18px",fontWeight:"bold"}}>正确答案：{tcnr.true}</div>
        <div style={{marginTop:"18px"}}><span style={{fontWeight:"bold"}}>解析：</span>{tcnr.analysis}</div>

        </Modal>
      {/* 顶部的按钮 */}
          <div style={{ marginBottom: 16,position:"relative" }}>
          <Button type="primary" onClick={start} disabled={!hasSelected} loading={scjz}>
            删除
          </Button>

          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `选中了${sel.length}条` : ''}
          </span>
          <input
        type="file"
        onChange={($event)=>{getPicture($event)}}
        // accept="image/*"
        style={{display: "none"}}
        id="file"/>
          <Button type="primary" style={{ marginLeft: 50 }} onClick={()=>{batch()}}>
            批量导入
          </Button>   
          <Select placeholder="全部主题" allowClear style={{ width: 120, marginLeft: 50 }} onChange={(w)=>{handleChange(w);}}>
          {/* <Option value="qb">全部</Option> */}

            {/* <Option value="1">财富</Option>
            <Option value="2">保险</Option>
            <Option value="3">数据保护</Option>
            <Option value="4">征信</Option> */}
            {/* {math.theme.map((b,i)=>
            <Option value="1">财富</Option>
            )} */}
            {math.theme && math.theme.map((b,i)=>{return(
         <Option key={i} value={`${b.theme}`}>{b.theme}</Option>

            )})}
          </Select>
        <div style={{position:"absolute",left:152,top:'-43px',color:'red',marginTop:'10px',fontSize:"13px",}}><span style={{fontWeight:"bold",fontSize:'20px',position:'relative',top:"5px",}}>↓↓↓</span>&nbsp;注意：导入前请认真检查确认无误后再导入。&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;题目导入文件模板找不到了怎么办 ？<span style={{fontWeight:"bold",fontSize:'20px',position:'relative',top:'1px'}}>→</span>    <Button type="link" href='https://res.xiaoxxx.com/urlGather/2023050607f59a7a00c7466a341092.xls' style={{padding:'4px 5px',}} >点我下载</Button></div>

          <h2 style={{position:"absolute",right:0,top:0,fontWeight:"bold",color:""}}>题库列表</h2>
        </div>
        {/* 表格数据 */}
<ConfigProvider  locale={zhCN}>
    <Table 
    columns={columns} 
    dataSource={sj} 
    scroll={{ y:gao}}  
    className="fromt"
    loading={jz}
    pagination={{ pageSize:10,total:math.allCounts,showTotal:total=> `共 ${math.allCounts} 条数据`,
    current:dqym
  }}
    onChange={(e,i,s,c)=>{usejz(true);  onChanges(e,i,s,c)}}
    rowSelection={rowSelection} 

    rowKey="id"
    />
    </ConfigProvider >







    </div>
      )
}

// ReactDOM.render(
  
//   mountNode,
// );

