import React, { Component,useEffect,useState} from 'react'
import { BrowserRouter, useNavigate,useLocation,Link, Routes, Route,Outlet } from "react-router-dom" //引入路由模块
import zhCN from 'antd/lib/locale/zh_CN';
import './configuration.css'
import { Divider, Table ,Form,Input,Switch,Modal,Button,ConfigProvider,Checkbox, Popconfirm, message,Space  } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Question from "./ces"

import { getActivityLists } from "../../../api/api.js"; //活动列表接口
import { ActivityUpdate } from "../../../api/api.js"; //修改活动接口

import {ActivityCreate } from "../../../api/api.js"; //新增活动接口

import { activityDrawStatus } from "../../../api/api.js"; //是否开启跳转
import { activityDraw } from "../../../api/api.js"; //跳转信息配置


const { TextArea } = Input;

export default function Activity() { 
  
const navigate = useNavigate();
let totoken = window.localStorage.getItem("token");
// console.log(totoken)
if(totoken==null || totoken==""){
  // alert(1)
  navigate("/")
}

const location = useLocation()
const columns = [
  {
    title: `序号`,
    align:"center",
    width: 60,
    render:(e,x,i)=>(
      <div>
              {i+1!=10 && dqym-1==0 && (<div>{i+1}</div>) }
      {i+1!=10 && dqym-1!=0 && (<div>{dqym-1}{i+1}</div>) }
      {i+1==10 && (<div>{dqym}0</div>) }
      </div>
    )
    // dataIndex: 'name',
  },
 
  {
    title: '活动名称',
    align:"center",

    dataIndex: 'title',
    width: 200,
  },
  {
    title: '活动链接',
    align:"center",
    // sorter:true,
    // dataIndex: '',
    render:(e,x,i)=>(
      <div><a  href={x.url} target="_blank">{x.url}</a></div>
    )
    // width: 400,
  },
  {
    title: '开启活动',
    align:"center",
    // sorter:true,
    // dataIndex: 'score',
    width: 100,
    render:(a,b,c)=>(
      <Switch checkedChildren="启用中" onChange={(e)=>{
        // console.log(e)
        let zt=0
        // console.log(a,b,c)
        if(e==true){
          zt=2
        }else{
          zt=1
        }
        // sqy(true)
        ActivityUpdate(`status=${zt}&id=${b.id}`).then((res=>{
          // console.log(res)
        
            if(res.code==0){
              refresh()
              success(zt==1? "关闭成功" : "启用成功")
              stcf(false)
          }
        }))
      }} unCheckedChildren="关闭中"  checked={b.status==2 && true} />
    )
  },
  // {
  //   title: '开启跳转',
  //   align:"center",
  //   // sorter:true,
  //   dataIndex: 'score',
  //   width: 100,
  //   render:(a,b,c)=>(
  //     <Switch checkedChildren="启用中" onChange={(e)=>{
  //       // console.log(e)
  //       let zt=0
  //       // console.log(a,b,c)
  //       if(e==true){
  //         zt=1
  //       }else{
  //         zt=0
  //       }
  //       // console.log(zt)
  //       // sqy(true)
  //       activityDrawStatus(`draw_status=${zt}&id=${b.id}`).then((res=>{
  //         // console.log(res)
        
  //           if(res.code==0){
  //             refresh()
  //             success(zt==0? "关闭成功" : "启用成功")
  //             stcf(false)
  //         }
  //       }))
  //     }} unCheckedChildren="关闭中"  checked={b.draw_status==1 && true} />
  //   )
  // },
  // {
  //   title: '题目随机',
  //   align:"center",
  //   // sorter:true,
  //   dataIndex: 'score',
  //   width: 100,
  //   render:(a,b,c)=>(
  //     <div>

     
  //     <Switch checkedChildren={`启用中`} onChange={(e)=>{
  //       // console.log(e)
  //       let zt=1
  //       // console.log(a,b,c)
  //       if(e==true){
  //         zt=2

  //       }else{
  //         zt=1

  //       }
  //       ActivityUpdate(`random=${zt}&id=${b.id}`).then((res=>{
  //         // console.log(res)
        
  //           if(res.code==0){
  //             refresh()
  //             success(zt==1? "关闭成功" : "启用成功")
  //             stcf(false)
  //         }
  //       }))
     
  //     }} unCheckedChildren="关闭中"  checked={b.random!=1 && true} />
  //     {/* <div>({b.single_choice},{b.multiple_choice},{b.judge})</div> */}
  //      </div>
  //   )
  // },
  // {
  //   title: '部门配置',
  //   align:"center",
  //   // sorter:true,
  //   dataIndex: 'score',
  //   width: 100,
  //   render:(a,b,c)=>(
  //     <div>

     
  //     <Switch checkedChildren={`启用中`} onChange={(e)=>{
  //       // console.log(e)
  //       let zt=1
  //       // console.log(a,b,c)
  //       if(e==true){
  //         zt=2

  //       }else{
  //         zt=1

  //       }
  //       ActivityUpdate(`department_type=${zt}&id=${b.id}`).then((res=>{
  //         // console.log(res)
        
  //           if(res.code==0){
  //             refresh()
  //             success(zt==1? "关闭成功" : "启用成功")
  //             stcf(false)
  //         }
  //       }))
     
  //     }} unCheckedChildren="关闭中"  checked={b.department_type!=1 && true} />
  //     {/* <div>({b.single_choice},{b.multiple_choice},{b.judge})</div> */}
  //      </div>
  //   )
  // },
  // {
  //   title: '次数限制',
  //   align:"center",
  //   // sorter:true,
  //   dataIndex: 'score',
  //   width: 100,
  //   render:(a,b,c)=>(
  //     <div>

     
  //     <Switch checkedChildren={`启用中`} onChange={(e)=>{
  //       // console.log(e)
  //       let zt=1
  //       // console.log(a,b,c)
  //       if(e==true){
  //         zt=2

  //       }else{
  //         zt=1

  //       }
  //       ActivityUpdate(`count_status=${zt}&id=${b.id}`).then((res=>{
  //         // console.log(res)
        
  //           if(res.code==0){
  //             refresh()
  //             success(zt==1? "关闭成功" : "启用成功")
  //             stcf(false)
  //         }
  //       }))
     
  //     }} unCheckedChildren="关闭中"  checked={b.count_status!=1 && true} />
  //     {/* <div>({b.single_choice},{b.multiple_choice},{b.judge})</div> */}
  //      </div>
  //   )
  // },
  // {
  //   title: '排行榜',
  //   align:"center",
  //   // sorter:true,
  //   dataIndex: 'score',
  //   width: 100,
  //   render:(a,b,c)=>(
  //     <div>

     
  //     <Switch checkedChildren={`启用中`} onChange={(e)=>{
  //       // console.log(e)
  //       let zt=1
  //       // console.log(a,b,c)
  //       if(e==true){
  //         zt=2

  //       }else{
  //         zt=1

  //       }
  //       ActivityUpdate(`ranking=${zt}&id=${b.id}`).then((res=>{
  //         // console.log(res)
        
  //           if(res.code==0){
  //             refresh()
  //             success(zt==1? "关闭成功" : "启用成功")
  //             stcf(false)
  //         }
  //       }))
     
  //     }} unCheckedChildren="关闭中"  checked={b.ranking!=1 && true} />
  //     {/* <div>({b.single_choice},{b.multiple_choice},{b.judge})</div> */}
  //      </div>
  //   )
  // },
  //   {
  //   title: '答题后标语',
  //   align:"center",
  //   // sorter:true,
  //   dataIndex: 'score',
  //   width: 102,
  //   render:(a,b,c)=>(
  //     <div>

     
  //     <Switch checkedChildren={`启用中`} onChange={(e)=>{
  //       // console.log(e)
  //       let zt=1
  //       // console.log(a,b,c)
  //       if(e==true){
  //         zt=2

  //       }else{
  //         zt=1

  //       }
  //       ActivityUpdate(`prompt_status=${zt}&id=${b.id}`).then((res=>{
  //         // console.log(res)
        
  //           if(res.code==0){
  //             refresh()
  //             success(zt==1? "关闭成功" : "启用成功")
  //             stcf(false)
  //         }
  //       }))
     
  //     }} unCheckedChildren="关闭中"  checked={b.prompt_status!=1 && true} />
  //     {/* <div>({b.single_choice},{b.multiple_choice},{b.judge})</div> */}
  //      </div>
  //   )
  // },
  // {
  //   title: '姓名输入',
  //   align:"center",
  //   // sorter:true,
  //   dataIndex: 'score',
  //   width: 102,
  //   render:(a,b,c)=>(
  //     <div>

     
  //     <Switch checkedChildren={`启用中`} onChange={(e)=>{
  //       // console.log(e)
  //       let zt=1
  //       // console.log(a,b,c)
  //       if(e==true){
  //         zt=2

  //       }else{
  //         zt=1

  //       }
  //       ActivityUpdate(`name_status=${zt}&id=${b.id}`).then((res=>{
  //         // console.log(res)
        
  //           if(res.code==0){
  //             refresh()
  //             success(zt==1? "关闭成功" : "启用成功")
  //             stcf(false)
  //         }
  //       }))
     
  //     }} unCheckedChildren="关闭中"  checked={b.name_status!=1 && true} />
  //     {/* <div>({b.single_choice},{b.multiple_choice},{b.judge})</div> */}
  //      </div>
  //   )
  // },
  // {
  //   title: '解析',
  //   align:"center",
  //   // sorter:true,
  //   dataIndex: 'score',
  //   width: 102,
  //   render:(a,b,c)=>(
  //     <div>

     
  //     <Switch checkedChildren={`启用中`} onChange={(e)=>{
  //       // console.log(e)
  //       let zt=1
  //       // console.log(a,b,c)
  //       if(e==true){
  //         zt=2

  //       }else{
  //         zt=1

  //       }
  //       ActivityUpdate(`analysis_status=${zt}&id=${b.id}`).then((res=>{
  //         // console.log(res)
        
  //           if(res.code==0){
  //             refresh()
  //             success(zt==1? "关闭成功" : "启用成功")
  //             stcf(false)
  //         }
  //       }))
     
  //     }} unCheckedChildren="关闭中"  checked={b.analysis_status!=1 && true} />
  //     {/* <div>({b.single_choice},{b.multiple_choice},{b.judge})</div> */}
  //      </div>
  //   )
  // },
  // {
  //   title: '答题时间',
  //   align:"center",
  //   // sorter:true,
  //   dataIndex: 'score',
  //   width: 102,
  //   render:(a,b,c)=>(
  //     <div>

     
  //     <Switch checkedChildren={`启用中`} onChange={(e)=>{
  //       // console.log(e)
  //       let zt=1
  //       // console.log(a,b,c)
  //       if(e==true){
  //         zt=2

  //       }else{
  //         zt=1

  //       }
  //       ActivityUpdate(`time_status=${zt}&id=${b.id}`).then((res=>{
  //         // console.log(res)
        
  //           if(res.code==0){
  //             refresh()
  //             success(zt==1? "关闭成功" : "启用成功")
  //             stcf(false)
  //         }
  //       }))
     
  //     }} unCheckedChildren="关闭中"  checked={b.time_status!=1 && true} />
  //     {/* <div>({b.single_choice},{b.multiple_choice},{b.judge})</div> */}
  //      </div>
  //   )
  // },
  {
    title: '编辑',
    align:"center",
    width: 400,

    // sorter:true,
    dataIndex: 'score',
    render: (a,b,c) => (
      <div size="middle" > 
               <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
            navigate(`/background/details/basic?keid=${b.id}&name=${b.title}`)
          }} >基本设置</Button>&nbsp;
           <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
            navigate(`/background/details/configuration?keid=${b.id}&name=${b.title}`)
          }} >题目设置</Button>&nbsp;
           <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
            navigate(`/background/details/advanced?keid=${b.id}&name=${b.title}`)
          }} >高级设置</Button>&nbsp;
           <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
            navigate(`/background/details/draw?keid=${b.id}&name=${b.title}`)
          }} >抽奖设置</Button>&nbsp;
          <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
            navigate(`/background/details/certificate?keid=${b.id}&name=${b.title}`)
          }} >证书设置</Button>&nbsp;
          <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
            navigate(`/background/details/share?keid=${b.id}&name=${b.title}`)
          }} >分享设置</Button>&nbsp;
           <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
            navigate(`/background/details/theme?keid=${b.id}&name=${b.title}`)
          }} >样式设置</Button>&nbsp;
           <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
            // navigate(`/background/details/rankingList?keid=${b.id}&name=${b.title}`)
            navigate(`/background/details/database/rankingList?keid=${b.id}&name=${b.title}`)

          }} >数据看板</Button>
       
          {/* <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
            stcf(true);sgrade(b.title);sclick(b)
          }}>编辑活动名称</Button>&nbsp;
          <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
            navigate(`/background/configuration?keid=${b.id}&name=${b.title}`)
          }}>题目配置</Button>&nbsp;
          <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
            shdgz(true);shdnr(b.explain);sclick(b)
          }}>活动规则</Button>&nbsp;
          <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
            navigate(`/background/rankingList?keid=${b.id}&name=${b.title}`)
          }} >数据看板</Button>&nbsp;
            <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
            scollocate(true);sinformation(b);
            forms.setFieldsValue({      
              ...b
            })
          }} >跳转链接配置</Button>&nbsp;
           <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
            navigate(`/background/theme?keid=${b.id}&name=${b.title}`)
          }} >编辑首页图</Button>&nbsp;
          <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
                      sclick(b)
                      stochastic(true)
                      stochasticnumA(b.single_choice)
                      stochasticnumB(b.multiple_choice)
                      stochasticnumC(b.judge)
          }}>题目随机配置</Button>&nbsp;
          <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
                      sclick(b)
                      sdepartment(true)
                      let datas=[]
                      if(b.department!=null && b.department.length!=0){
                        for(var i=0;i<b.department.length;i++){
                          datas.push(b.department[i].name)
                        }
                        // console.log(6666,datas)
                         forma.setFieldsValue({
                        users:datas
                      })
                      }else{
                        forma.setFieldsValue({
                          users:[]
                        })
                      }
                      
                     
                      setTimeout(()=>{
                        document.getElementById('footers').parentElement.style.display="none"
                      },1)
                      console.log(b.else,111112)
                      if(b.else==1){
                        // sdangqian(false)
                        srests(false)
                      }else{
         

                        srests(true)
                        // srests(2)
                      }
                 
                      // stochasticnumA(b.single_choice)
                      // stochasticnumB(b.multiple_choice)
                      // stochasticnumC(b.judge)
          }}>部门配置</Button>&nbsp;
          <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
                      sclick(b)
                      stimes(true)
                      snumbe(b.count)
                      // stochasticnumA(b.single_choice)
                      // stochasticnumB(b.multiple_choice)
                      // stochasticnumC(b.judge)
          }}>答题次数配置</Button>&nbsp;
          <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
                      sclick(b)
                      shint(true)
                      shinttit1(b.mztx)
                      shinttit2(b.jgxz)
                      shinttit3(b.jgtx)
          }}>提示文案配置</Button>&nbsp;
          <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
                      sclick(b)
                      statement(true)
                      statementnum(b.prompt_score)
                      statementj(b.prompt_arrive)
                      statementb(b.prompt_not)
          }}>答题后标语</Button>&nbsp;
          <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
                      sclick(b)
                      stime(true)
                      stimetit(b.time)
                      // statementnum(b.prompt_score)
                      // statementj(b.prompt_arrive)
                      // statementb(b.prompt_not)
          }}>答题时间</Button> */}
          {/* <Button type="primary"  ghost >启用</Button>&nbsp; */}

          {/* <Button type="primary"  ghost >编辑</Button>&nbsp; */}
      </div>
    ),
  },

];//定义表格的title

const success = (e) => {
  message.success(e);
};

  let [gao,sgao]=useState(500)
    window.onresize = function(){
        let boss=document.getElementsByClassName("body")[0].offsetHeight-158
        sgao(boss)
    }//设置表格的高度

// 刷新页面
function  refresh() {

hqlb(dqym)
usejz(true);
ssel([])
}   


//使用生命周期的钩子
// console.log(useEffect)
useEffect(()=>{
    // console.log("挂载完成,更新完成")

    setTimeout(()=>{
        let boss=document.getElementsByClassName("body")[0].offsetHeight-158
    sgao(boss)},1)
    hqlb(1)
    
// console.log(location) 
  },[])


 let [sj,usesj]=useState([])//所有数据
 let [jz,usejz]=useState(true) //加载状态
//  let [ys,uys]=useState([1]) //页数是否存储过


// let [tc,stc]=useState(false) //  题目预览弹窗显示或隐藏
// let [tcnr,stcnr]=useState([]) //  题目预览弹窗内容

let [math,smath]=useState({})//页数及数量

// let sjx=2
function hqlb(i){

//  return
// 
    // if(i==1){
    //   usesj(xsj)  
    // }else{
    //   usesj(xsjs)  

    // }

    getActivityLists(`page=${i}&pageSize=10`).then((res)=>{
      console.log(res)
      usesj(res.data.lists)  
      smath(res.data)
      usejz(false)
      if( i!=1  && res.data.lists.length==0){
        hqlb(1)
      }
    })

// 必须用深拷贝来改变赋值
// let sjs = JSON.parse(JSON.stringify(sj))
// console.log(sj)
// usesj(sjs)  
// setTimeout(()=>{
// usejz(false)
// },1000)

}
let [click,sclick]=useState([]) //  当前点击的

const [forma] = Form.useForm();

let [hdgz,shdgz]=useState(false) //  活动规则弹窗
let [hdnr,shdnr]=useState("") //  活动规则内容

let [tcf,stcf]=useState(false) //编辑活动的显示和隐藏  
let [grade,sgrade]=useState('') //  编辑活动时候的活动名

let [tcfs,stcfs]=useState(false) //添加活动的显示和隐藏  
let [grades,sgrades]=useState('') //  添加活动时候的活动名


let [times,stimes]=useState(false) //答题次数的显示和隐藏  
let [numbe,snumbe]=useState(false) //答题次数的次数  


let [hint,shint]=useState(false) //启动题目随机的显示和隐藏  
let [hinttit1,shinttit1]=useState('') //启动题目随机时候的数量选择
let [hinttit2,shinttit2]=useState('') //启动题目随机时候的数量选择
let [hinttit3,shinttit3]=useState('') //启动题目随机时候的数量选择


let [tochastic,stochastic]=useState(false) //提示文案的显示和隐藏  
let [tochasticnumA,stochasticnumA]=useState('') //姓名提示文案
let [tochasticnumB,stochasticnumB]=useState('') //部门选择提示文案
let [tochasticnumC,stochasticnumC]=useState('') //部门填写提示文案

let [tatement,statement]=useState(false) //答题后标语的显示和隐藏  
let [tatementnum,statementnum]=useState('') //答题后标语的分数
let [tatementj,statementj]=useState('') //答题后标语的及格文案
let [tatementb,statementb]=useState('') //答题后标语不及格文案

let [time,stime]=useState(false) //答题时间显示和隐藏
let [timetit,stimetit]=useState(false) //答题时间 




let [department,sdepartment]=useState(false) //部门配置的显示和隐藏  

let [rests,srests]=useState(false) //是否开启其他
// let [dangqian,sdangqian]=useState(false) //是否开启其他




let [collocate,scollocate]=useState(false) //跳转链接配置的显示和隐藏  
let [information,sinformation]=useState({}) // 跳转链接配置的数据

let [dqym,sdqym]=useState(1) //当前页面的索引

// 筛选或分页的时候触发
function onChanges(e,i,s,c){
  // console.log(e,i,s,c)
  if(c.action=="paginate"){
    sdqym(e.current)
// let ysj=[1]
ssel([])
// if(ys.indexOf(e.current)==-1){
  hqlb(e.current)
  
//   ys.push(e.current)
//   uys(ys)
//   console.log(ys)
// }else{
//   usejz(false)
// }
// console.log(666,e.current,i)
  }
}
let [sel,ssel]=useState([]) //选中的数据

let [scjz,sscjz]=useState(false) //选中的数据

const [forms] = Form.useForm();

// let []=useState()
//多选是的时候触发
function onSelectChange(i,w){
  // console.log(i,w)
  ssel(i)


}


const error = (a) => {
  message.error(a);
};
const onFinishs = (values) => {
  console.log('Received values of form:', values);
  let datas=[]
  for(var i=0;i<values.users.length;i++){
    datas.push({
      id:i+1,
      name:values.users[i]
    })
  }
  console.log(datas)
        let ssa                // srests(1)
    if(rests==false){
      ssa=1
    }else{
      ssa=2
    }
   ActivityUpdate(`id=${click.id}&department=${encodeURIComponent(JSON.stringify(datas))}&else=${ssa}`).then((res=>{
    // console.log(res)
  
      if(res.code==0){
        refresh()
        success("部门配置成功")
        sdepartment(false);

    }else{
  error("配置失败,请刷新后重试")

    }
  }))
};

const onFinish = (values) => {
  // console.log('Success:', values);
               activityDraw(`id=${information.id}&draw_url=${values.draw_url}&draw_fraction=${values.draw_fraction}&draw_button=${values.draw_button}`).then((res=>{
                // console.log(res)
              
                  if(res.code==0){
                    refresh()
                    success("修改成功")
                                  scollocate(false)

                }else{
              error("保存失败,请刷新后重试")

                }
              }))
};
const onChas = (e) => {
  console.log(`checked = ${e.target.checked}`);
  // if(e.target.checked==true){
    srests(e.target.checked)
  // }else{
  //   srests(1)

  // }
  // console.log(rests)
};
const hasSelected = sel.length > 0;
  return (
      <div className='body'>

      {/* 配置答题后标语弹窗 */}
      <Modal
          title={`配置答题后标语`}
          style={{ top: '10%' }}
          visible={tatement}
          closable={false}
          footer={[<div key={1} style={{padding:0}}>
           <Button type="primary" style={{  }} onClick={() => {
            //  console.log(grades)
             if(tatementnum==""){
              error("及格分数不能为空，保存失败")
              return
             }
             if(tatementj==""){
              error("及格文案不能为空，保存失败")
              return
             }
             if(tatementb==""){
              error("不及格文案不能为空，保存失败")
              return
             }

              ActivityUpdate(`prompt_score=${tatementnum}&prompt_arrive=${tatementj}&prompt_not=${tatementb}&id=${click.id}`).then((res=>{
                // console.log(res)
              
                  if(res.code==0){
                    refresh()
                    success("保存成功")
                    statement(false);statementnum("");statementj("");statementb("")
                }else{
                 

              error(`${res.message}`)
                }
              }))

           }}>
            保存
          </Button>  
            <Button type="primary" style={{  }} onClick={() => { statement(false);statementnum("");statementj("");statementb("")}}>
            关闭
          </Button>  
          </div>]}
        >
          <div className='xuanx'>
            <div style={{width:"116px"}}>
              <span style={{display: "inline-block", marginRight: "4px",color: "#ff4d4f",fontSize: "14px",lineHeight: 1,}}>*</span>
           及格分值：
            </div>
            <Input type="number" placeholder="请输入及格分数" value={tatementnum} onChange={(e)=>{if(e.target.value>=0){ statementnum(e.target.value)}
            }}  />
          </div>
          <div className='xuanx'>
            <div style={{width:"130px"}}>
            <span style={{display: "inline-block", marginRight: "4px",color: "#ff4d4f",fontSize: "14px",lineHeight: 1,}}>*</span>
            及格文案：
            </div>
            <Input  placeholder="请输入及格文案" showCount maxLength={20}   value={tatementj} onChange={(e)=>{statementj(e.target.value)
            }}  />
          </div>
          <div className='xuanx'>
            <div style={{width:"130px"}}>
            <span style={{display: "inline-block", marginRight: "4px",color: "#ff4d4f",fontSize: "14px",lineHeight: 1,}}>*</span>
            不及格文案：
            </div>
            <Input  placeholder="请输入不及格文案" showCount maxLength={20} value={tatementb} onChange={(e)=>{ statementb(e.target.value)
            }}  />
          </div>
        {/* <div  style={{marginTop:"18px"}}>分值{tcnr.title}</div> */}
        
        </Modal>


        {/* ------------------------------------------------------------------------ */}
              {/* 提示文案配置的弹窗 */}
              <Modal
          title={`提示文案配置`}
          style={{ top: '10%' }}
          visible={hint}
          closable={false}
          footer={[<div key={1} style={{padding:0}}>
           <Button type="primary" style={{  }} onClick={() => {
            //  console.log(grades)
             if(hinttit1==""){
              error("姓名填写提示文案不能为空，保存失败")
              return
             }
             if(hinttit2==""){
              error("部门选择提示文案不能为空，保存失败")
              return
             }
             if(hinttit3==""){
              error("部门填写提示文案不能为空，保存失败")
              return
             }

              ActivityUpdate(`mztx=${hinttit1}&jgxz=${hinttit2}&jgtx=${hinttit3}&id=${click.id}`).then((res=>{
                // console.log(res)
              
                  if(res.code==0){
                    refresh()
                    success("保存成功")
                    shint(false);shinttit1("");shinttit2("");shinttit3("")
                }else{
                 

              error(`${res.message}`)
                }
              }))

           }}>
            保存
          </Button>  
            <Button type="primary" style={{  }} onClick={() => {shint(false);shinttit1("");shinttit2("");shinttit3("")}}>
            关闭
          </Button>  
          </div>]}
        >
          <div className='xuanx'>
            <div style={{width:"195px"}}>
              <span style={{display: "inline-block", marginRight: "4px",color: "#ff4d4f",fontSize: "14px",lineHeight: 1,}}>*</span>
           姓名填写提示文案：
            </div>
            <Input placeholder="姓名填写提示文案" value={hinttit1} onChange={(e)=>{shinttit1(e.target.value)}}  />
          </div>
          <div className='xuanx'>
            <div style={{width:"195px"}}>
            <span style={{display: "inline-block", marginRight: "4px",color: "#ff4d4f",fontSize: "14px",lineHeight: 1,}}>*</span>
            部门选择提示文案：
            </div>
            <Input  placeholder="部门选择提示文案" value={hinttit2} onChange={(e)=>{shinttit2(e.target.value)
            }}  />
          </div>
          <div className='xuanx'>
            <div style={{width:"195px"}}>
            <span style={{display: "inline-block", marginRight: "4px",color: "#ff4d4f",fontSize: "14px",lineHeight: 1,}}>*</span>
            部门填写提示文案：
            </div>
            <Input  placeholder="部门填写提示文案" value={hinttit3} onChange={(e)=>{ shinttit3(e.target.value)
            }}  />
          </div>
        {/* <div  style={{marginTop:"18px"}}>分值{tcnr.title}</div> */}
        
        </Modal>
        {/* ------------------------------------------------------------------------ */}



      {/* 配置跳转链接弹窗 */}
      <Modal
          title={`跳转链接配置`}
          style={{ top: '10%' }}
          visible={collocate}
          closable={false}
          footer={null}

        >
          {/* <Input placeholder="活动名称" value={information.draw_url} onChange={(e)=>{
            let wa=JSON.parse(JSON.stringify(information))
            wa.draw_url=e.target.value
            console.log(wa)

            sinformation(wa)
            }}  /> */}
              <Form
    name="wrap"
    onFinish={onFinish}
    form={forms}
    labelCol={{
      flex: '80px',
    }}
    labelAlign="right"
    // labelWrap
    wrapperCol={{
      flex: 1,
    }}
    colon={false}
  >
    <Form.Item
      label="跳转链接"
      name="draw_url"
      rules={[
        {
          required: true,
          message: '跳转链接不能为空'
        },
      ]}
    >
      <Input placeholder='请输入跳转链接'/>
    </Form.Item>
    <Form.Item
      label="分值配置"
      name="draw_fraction"
      rules={[
        {
          required: true,
          message: '分值不能为空'
        },
      ]}
    >
      <Input placeholder='请输入大于等于多少分值才可跳转'/>
    </Form.Item>
    <Form.Item
      label="按钮文案"
      name="draw_button"
      rules={[
        {
          required: true,
          message: '文案不能为空'
        },
      ]}
    >
      <Input  showCount maxLength={7} placeholder='请输入按钮文案'/>
    </Form.Item>
    <Form.Item label=" ">
      <Button type="primary" htmlType="submit" style={{marginRight:"10px"}}>
        保存
      </Button>
         <Button type="primary" onClick={()=>{
           scollocate(false)
         }}>
        取消
      </Button>
    </Form.Item> 

</Form>
        {/* <div  style={{marginTop:"18px"}}>分值{tcnr.title}</div> */}
        
        </Modal>


              {/* 添加活动弹窗 */}
      <Modal
          title={`正在添加活动`}
          style={{ top: '10%' }}
          visible={tcfs}
          closable={false}
          footer={[<div key={1} style={{padding:0}}>
           <Button type="primary" style={{  }} onClick={() => {
            //  console.log(grades)
             if(grades!=""){
              ActivityCreate(`title=${grades}`).then((res=>{
                // console.log(res)
              
                  if(res.code==0){
                    refresh()
                    success("添加活动成功")
                    stcfs(false)
                    sgrades("")
                }else{
                 

              error(`${res.message}`)
                }
              }))
             }else{
              error("未输入，保存失败")
             }
           }}>
            保存
          </Button>  
            <Button type="primary" style={{  }} onClick={() => {stcfs(false);sgrades("")}}>
            关闭
          </Button>  
          </div>]}
        >
          <Input placeholder="活动名称" value={grades} onChange={(e)=>{ sgrades(e.target.value)
            }}  />
        {/* <div  style={{marginTop:"18px"}}>分值{tcnr.title}</div> */}
        
        </Modal>


        {/* ------------------------------------------------------------------------ */}
              {/* 启用题目随机的弹窗 */}
              <Modal
          title={`随机题目配置`}
          style={{ top: '10%' }}
          visible={tochastic}
          closable={false}
          footer={[<div key={1} style={{padding:0}}>
           <Button type="primary" style={{  }} onClick={() => {
            //  console.log(grades)
             if(tochasticnumA==""&&tochasticnumB==""&&tochasticnumC==""){
              error("随机题目数量不能为空，保存失败")
              return
             }else if(tochasticnumA==0&&tochasticnumB==0&&tochasticnumC==0){
              error("随机题目数量不得小于1")
              return
             }
             if(tochasticnumA==""){
              stochasticnumA("0");
             }
             if(tochasticnumB==""){
              stochasticnumB("0");
             }
             if(tochasticnumC==""){
              stochasticnumC("0");
             }
              ActivityUpdate(`random=2&single_choice=${tochasticnumA}&multiple_choice=${tochasticnumB}&judge=${tochasticnumC}&id=${click.id}`).then((res=>{
                // console.log(res)
              
                  if(res.code==0){
                    refresh()
                    success("保存成功")
                    stochastic(false)
                    stochasticnumA("");stochasticnumB("");stochasticnumC("")
                }else{
                 

              error(`${res.message}`)
                }
              }))

           }}>
            保存
          </Button>  
            <Button type="primary" style={{  }} onClick={() => {stochastic(false);stochasticnumA("");stochasticnumB("");stochasticnumC("")}}>
            关闭
          </Button>  
          </div>]}
        >
          <div className='xuanx'>
            <div>
           单选：
            </div>
            <Input type="number" placeholder="单选题随机题目数量" value={tochasticnumA} onChange={(e)=>{if(e.target.value>=0){ stochasticnumA(e.target.value)}
            }}  />
          </div>
          <div className='xuanx'>
            <div>
            多选：
            </div>
            <Input type="number" placeholder="多选题随机题目数量" value={tochasticnumB} onChange={(e)=>{if(e.target.value>=0){ stochasticnumB(e.target.value)}
            }}  />
          </div>
          <div className='xuanx'>
            <div>
            判断：
            </div>
            <Input type="number" placeholder="判断题随机题目数量" value={tochasticnumC} onChange={(e)=>{if(e.target.value>=0){ stochasticnumC(e.target.value)}
            }}  />
          </div>
        {/* <div  style={{marginTop:"18px"}}>分值{tcnr.title}</div> */}
        
        </Modal>
        {/* ------------------------------------------------------------------------ */}


        {/* ------------------------------------------------------------------------ */}
              {/* 答题时间限制弹窗 */}
              <Modal
          title={`答题时间配置 单位（秒）`}
          style={{ top: '10%' }}
          visible={time}
          closable={false}
          footer={[<div key={1} style={{padding:0}}>
           <Button type="primary" style={{  }} onClick={() => {
            //  console.log(grades)
             if(timetit==""){
              error("答题时间不能为空，保存失败")
              return
             }
      
              ActivityUpdate(`time=${timetit}&id=${click.id}`).then((res=>{
                // console.log(res)
              
                  if(res.code==0){
                    refresh()
                    success("保存成功")
                    
                   stime(false);stimetit("");
                }else{
                 

              error(`${res.message}`)
                }
              }))

           }}>
            保存
          </Button>  
            <Button type="primary" style={{  }} onClick={() => {stime(false);stimetit("");}}>
            关闭
          </Button>  
          </div>]}
        >

            <Input type="number" placeholder="请输入答题时间单位（秒）" value={timetit} onChange={(e)=>{if(e.target.value>=0){ stimetit(e.target.value)}
            }}  />
      
  
        
        </Modal>
        {/* ------------------------------------------------------------------------ */}



        {/* ------------------------------------------------------------------------ */}
              {/* 答题次数限制弹窗 */}
              <Modal
          title={`答题次数配置`}
          style={{ top: '10%' }}
          visible={times}
          closable={false}
          footer={[<div key={1} style={{padding:0}}>
           <Button type="primary" style={{  }} onClick={() => {
            //  console.log(grades)
             if(numbe==""){
              error("答题次数不能为空，保存失败")
              return
             }
      
              ActivityUpdate(`count=${numbe}&id=${click.id}`).then((res=>{
                // console.log(res)
              
                  if(res.code==0){
                    refresh()
                    success("保存成功")
                    
                   stimes(false);snumbe("");
                }else{
                 

              error(`${res.message}`)
                }
              }))

           }}>
            保存
          </Button>  
            <Button type="primary" style={{  }} onClick={() => {stimes(false);snumbe("");}}>
            关闭
          </Button>  
          </div>]}
        >

            <Input type="number" placeholder="请输入答题次数" value={numbe} onChange={(e)=>{if(e.target.value>=0){ snumbe(e.target.value)}
            }}  />
      
  
        
        </Modal>
        {/* ------------------------------------------------------------------------ */}




        {/* ------------------------------------------------------------------------ */}
              {/* 部门编辑弹窗 */}
              <Modal
          title={`部门配置`}
          style={{ top: '10%' }}
          visible={department}
          closable={false}
          footer={[<div key={1} style={{padding:0}} className='footers' id='footers'>
           {/* <Button type="primary" style={{  }} onClick={() => {
            
            //  if(tochasticnumA==""&&tochasticnumB==""&&tochasticnumC==""){
            //   error("随机题目数量不能为空，保存失败")
            //   return
            //  }else if(tochasticnumA==0&&tochasticnumB==0&&tochasticnumC==0){
            //   error("随机题目数量不得小于1")
            //   return
            //  }
            //  if(tochasticnumA==""){
            //   stochasticnumA("0");
            //  }
            //  if(tochasticnumB==""){
            //   stochasticnumB("0");
            //  }
            //  if(tochasticnumC==""){
            //   stochasticnumC("0");
            //  }
            //   ActivityUpdate(`random=2&single_choice=${tochasticnumA}&multiple_choice=${tochasticnumB}&judge=${tochasticnumC}&id=${click.id}`).then((res=>{
            //     // console.log(res)
              
            //       if(res.code==0){
            //         refresh()
            //         success("随机题目开启成功")
            //         stochastic(false)
            //         stochasticnumA("");stochasticnumB("");stochasticnumC("")
            //     }else{
                 

            //   error(`${res.message}`)
            //     }
            //   }))

           }}>
            保存
          </Button>  
            <Button type="primary" style={{  }} onClick={() => {sdepartment(false);}}>
            关闭
          </Button>   */}
          </div>]}
        >
          <div>
          <Form name="dynamic_form_nest_item" form={forma} onFinish={onFinishs} autoComplete="off">
            <div className='departmentHeight'>
      <Form.List name="users">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  display: 'flex',
                  marginBottom: 8,
                  width: '100%',

                }}
                align="baseline"
                className='inps'
              >
  
                <Form.Item
                  {...restField}
    
                  name={[name]}
                  rules={[{ required: true, message: `部门${name+1}不能为空` }]}
                  label={`部门${name+1}`}
                
                >
                  <Input placeholder={`请输入部门${name+1}`} />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                添加部门
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      </div>
      <Form.Item style={{position: "relative",top:"20px",width:"calc(100% + 48px)",left:"-24px",      borderTop:"1px solid #f0f0f0"}}>
      <Checkbox onChange={onChas}  checked={rests} style={{position: 'absolute',left:"16px",top:"8px"}}>用户可手动填写</Checkbox>
        <Button type="primary" htmlType="submit" style={{position: 'absolute',right:"88px",top:"8px"}}>
         保存
        </Button>
        <Button type="primary" onClick={() => {sdepartment(false);}} style={{position: 'absolute',right:"16px",top:"8px"}}>
         取消
        </Button>

      </Form.Item>
    </Form>
          </div>
        {/* <div  style={{marginTop:"18px"}}>分值{tcnr.title}</div> */}
        
        </Modal>
        {/* ------------------------------------------------------------------------ */}
              {/* 活动规则弹窗3 */}
              <Modal
          title={`活动规则`}
          style={{ top: '10%' }}
          visible={hdgz}
          closable={false}
          footer={[<div key={1} style={{padding:0}}>
           <Button type="primary" style={{  }} onClick={() => {
            //  console.log(hdnr)
            //  console.log(click)
             if(hdnr!=""){
              ActivityUpdate(`explain=${hdnr}&id=${click.id}`).then((res=>{
                // console.log(res)
              
                  if(res.code==0){
                    refresh()
                    success("活动规则修改成功")
                    shdgz(false)
                }
              }))
             }else{
              error("未输入，保存失败")
             }
           }}>
            保存
          </Button>  
            <Button type="primary" style={{  }} onClick={() => shdgz(false)}>
            关闭
          </Button>  
          </div>]}
        >
            <TextArea rows={4} placeholder="请输入活动规则" value={hdnr} onChange={(e)=>{ shdnr(e.target.value)
            }} />
        
        </Modal>
      {/* 更改活动名称 */}
      <Modal
          title={`正在更改活动名称`}
          style={{ top: '10%' }}
          visible={tcf}
          closable={false}
          footer={[<div key={1} style={{padding:0}}>
           <Button type="primary" style={{  }} onClick={() => {
            //  console.log(grade)
             if(grade!=""){
              ActivityUpdate(`title=${grade}&id=${click.id}`).then((res=>{
                // console.log(res)
              
                  if(res.code==0){
                    refresh()
                    success("修改成功")
                    stcf(false)
                }
              }))
             }else{
              error("未输入，保存失败")
             }
           }}>
            保存
          </Button>  
            <Button type="primary" style={{  }} onClick={() => stcf(false)}>
            关闭
          </Button>  
          </div>]}
        >
          <Input placeholder="活动名称" value={grade} onChange={(e)=>{ sgrade(e.target.value)
            }}  />
        {/* <div  style={{marginTop:"18px"}}>分值{tcnr.title}</div> */}
        
        </Modal>
      {/* 顶部的按钮 */}
          <div style={{ marginBottom: 16,position:"relative" }}>


          {/* <Button type="primary"  style={{ marginLeft: 0 }} onClick={()=>{ start()}}>
            清空排行榜
          </Button>      */}


          <Button type="primary" onClick={()=>{
            stcfs(true)
          }}  style={{ marginLeft: 0 }}>
            添加活动
          </Button>     
      

          <h2 style={{position:"absolute",right:0,top:0,fontWeight:"bold",color:""}}>活动列表</h2>
        </div>
        {/* 表格数据 */}
<ConfigProvider  locale={zhCN}>
    <Table 
    columns={columns} 
    dataSource={sj} 
    scroll={{ y:gao}}  
    className="fromt"
    loading={jz}
    pagination={{ pageSize:10,total:math.allcounts,showTotal:total=> `共 ${math.allcounts} 条数据`,
    current:dqym
  }}
    onChange={(e,i,s,c)=>{usejz(true);  onChanges(e,i,s,c)}}
    // rowSelection={rowSelection} 

    rowKey="id"
    />
    </ConfigProvider >







    </div>
      )
}

// ReactDOM.render(
  
//   mountNode,
// );

