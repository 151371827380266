import React, { Component, useEffect, useState } from 'react'

import { Form, Input,Spin, Button, message, Space, Radio, Upload, Breadcrumb, Modal, Checkbox, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import "./theme.css"
import { questionCreate } from "../../../api/api.js"; //新增
import { questionUpdate } from "../../../api/api.js"; //编辑
import { useNavigate, useLocation } from "react-router-dom" //引入路由模块
import { backgroundUpdate } from "../../../api/api.js"; //修改背景图
import { getBackground } from "../../../api/api.js"; //获取背景图
// 引入 axios
import axios from 'axios';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
    });

export default function Bna() {
    const navigate = useNavigate();
    const location = useLocation()
    let totoken = window.localStorage.getItem("token");
    // console.log(totoken)
    if (totoken == null || totoken == "") {
        // alert(1)
        navigate("/")
    }


    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([

    ]);
    const [fileList2, setFileList2] = useState([

    ]);
    const [fileList3, setFileList3] = useState([

    ]);
    const [fileList4, setFileList4] = useState([

    ]);
    const [fileList5, setFileList5] = useState([

    ]);
    const [fileList6, setFileList6] = useState([

    ]);
    const [fileList7, setFileList7] = useState([

    ]);
    const [fileList8, setFileList8] = useState([

    ]);

    const [xrimg, sxrimg] = useState(require('../../../assets/xr.png'));
    const [bgimg, sbgimg] = useState(require('../../../assets/k2.png'));
    
    const [loadas, sloadas] = useState(false);

    const [tk, stk] = useState(4);

    const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList); console.log(newFileList);
        form.setFieldsValue({
            file1: newFileList
        })
        if (newFileList[0].response != null && newFileList[0].response != undefined) {
            document.getElementById("phone").style.backgroundImage = `url('${newFileList[0].response.data.url}')`
        }
    };
    const handleChange2 = ({ fileList: newFileList }) => {
        setFileList2(newFileList); console.log(newFileList);
        form.setFieldsValue({
            file2: newFileList
        })
        if (newFileList[0].response != null && newFileList[0].response != undefined) {
            document.getElementById("btn2").src = newFileList[0].response.data.url
        }
    };
    const handleChange3 = ({ fileList: newFileList }) => {
        setFileList3(newFileList); console.log(newFileList);
        form.setFieldsValue({
            file3: newFileList
        })
        if (newFileList[0].response != null && newFileList[0].response != undefined) {
            document.getElementById("btn3").src = newFileList[0].response.data.url
        }
    };
    const handleChange4 = ({ fileList: newFileList }) => {
        setFileList4(newFileList); console.log(newFileList);
        form.setFieldsValue({
            file4: newFileList
        })
        if (newFileList[0].response != null && newFileList[0].response != undefined) {
            document.getElementById("btn1").src = newFileList[0].response.data.url
        }
    };
    const handleChange5 = ({ fileList: newFileList }) => {
        setFileList5(newFileList); console.log(newFileList);
        form.setFieldsValue({
            file5: newFileList
        })
        if (newFileList[0].response != null && newFileList[0].response != undefined) {
            sxrimg(newFileList[0].response.data.url)
        }
    };
    const handleChange6 = ({ fileList: newFileList }) => {
        setFileList6(newFileList); console.log(newFileList);
        form.setFieldsValue({
            file6: newFileList
        })
        if (newFileList[0].response != null && newFileList[0].response != undefined) {
            sbgimg(newFileList[0].response.data.url)
        }
    };
    const handleChange7 = ({ fileList: newFileList }) => {
        setFileList7(newFileList); console.log(newFileList);
        form.setFieldsValue({
            file7: newFileList
        })
        if (newFileList[0].response != null && newFileList[0].response != undefined) {
            document.getElementById("btn7").src = newFileList[0].response.data.url
        }
    };
    const handleChange8 = ({ fileList: newFileList }) => {
        setFileList8(newFileList); console.log(newFileList);
        form.setFieldsValue({
            file8: newFileList
        })
        if (newFileList[0].response != null && newFileList[0].response != undefined) {
            document.getElementById("btn8").src = newFileList[0].response.data.url
        }
    };

    //使用生命周期的钩子
    // console.log(useEffect)
    useEffect(() => {
        hqlb()
    }, [])

    // 获取页面参数
    function hqcorid(key, Url) {
        var str = Url;
        str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
        // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
        var arr = str.split("&");
        // console.log(666666,arr)
        var obj = new Object();
        // 将每一个数组元素以=分隔并赋给obj对象
        for (var i = 0; i < arr.length; i++) {
            var tmp_arr = arr[i].split("=");
            obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
        }
        // console.log("1234564,", obj[key]);
        return obj[key];
    }
    let pageid = hqcorid("keid", location.search)
    let paname = hqcorid("name", location.search)

    function hqlb(i) {
        sloadas(true)

        getBackground(`activity_id=${pageid}`).then((res) => {
            sloadas(false)
            console.log(res)
            if (res.code == 0) {
                setFileList([{
                    url: res.data.lists.background,
                    status: "done",
                }])
                setFileList2([{
                    url: res.data.lists.ranking,
                    status: "done",
                }])
                setFileList3([{
                    url: res.data.lists.rule,
                    status: "done",
                }])
                setFileList4([{
                    url: res.data.lists.open,
                    status: "done",
                }])
                setFileList5([{
                    url: res.data.lists.ip_img,
                    status: "done",
                }])
                setFileList6([{
                    url: res.data.lists.ending_img,
                    status: "done",
                }])
                if( res.data.lists.win_record&&res.data.lists.win_record!='' ){
                     setFileList7([{
                         url: res.data.lists.win_record,
                         status: "done",
                     }])
                }
                if(res.data.lists.score_details_img&&res.data.lists.score_details_img!=''){
                    setFileList8([{
                        url: res.data.lists.score_details_img,
                        status: "done",
                    }])
                }
                form.setFieldsValue({
                    file1: [{ url: res.data.lists.background, status: "done", }],
                    file2: [{ url: res.data.lists.ranking, status: "done", }],
                    file3: [{ url: res.data.lists.rule, status: "done", }],
                    file4: [{ url: res.data.lists.open, status: "done", }],
                    file5: [{ url: res.data.lists.ip_img, status: "done", }],
                    file6: [{ url: res.data.lists.ending_img, status: "done", }],
                    file7:res.data.lists.win_record&&res.data.lists.win_record!=''?[{ url: res.data.lists.win_record, status: "done", }]:[],
                    file8:res.data.lists.score_details_img&&res.data.lists.score_details_img!=''? [{ url: res.data.lists.score_details_img, status: "done", }]:[],



                })
                document.getElementById("phone").style.backgroundImage = `url('${res.data.lists.background}')`
                document.getElementById("btn2").src = res.data.lists.ranking
                document.getElementById("btn3").src = res.data.lists.rule
                document.getElementById("btn1").src = res.data.lists.open
                if( res.data.lists.win_record&&res.data.lists.win_record!='' ){
                    document.getElementById("btn7").src = res.data.lists.win_record 
                }
                if(res.data.lists.score_details_img&&res.data.lists.score_details_img!=''){
                    document.getElementById("btn8").src = res.data.lists.score_details_img
                }
                
                

                sxrimg(res.data.lists.ip_img)
       
                sbgimg(res.data.lists.ending_img)


            }
        })

    }



    const onFinish = (val) => {
        // console.log(val);
        // return
        if (val.file1[0].status != 'done') {
            message.error('背景图上传失败请重新上传');
            return
        }
        if (val.file2[0].status != 'done') {
            message.error('查看排行榜按钮上传失败请重新上传');
            return
        }
        if (val.file3[0].status != 'done') {
            message.error('规则按钮上传失败请重新上传');
            return
        }
        if (val.file4[0].status != 'done') {
            message.error('开启按钮上传失败请重新上传');
            return
        }
        if (val.file5[0].status != 'done') {
            message.error('IP图片上传失败请重新上传');
            return
        }
        if (val.file6[0].status != 'done') {
            message.error('答题后成绩弹窗上传失败请重新上传');
            return
        }
        // if (val.file7[0].status != 'done') {
        //     message.error('中奖记录弹窗上传失败请重新上传');
        //     return
        // }
        // if (val.file8[0].status != 'done') {
        //     message.error('分数详情弹窗上传失败请重新上传');
        //     return
        // }

        let background, ranking, rule, open, ip_img,ending_img,win_record,score_details_img
        if (val.file1[0].response) {
            background = val.file1[0].response.data.url
        } else {
            background = val.file1[0].url
        }

        if (val.file2[0].response) {
            ranking = val.file2[0].response.data.url
        } else {
            ranking = val.file2[0].url
        }
        if (val.file3[0].response) {
            rule = val.file3[0].response.data.url
        } else {
            rule = val.file3[0].url
        }
        if (val.file4[0].response) {
            open = val.file4[0].response.data.url
        } else {
            open = val.file4[0].url
        }
        if (val.file5[0].response) {
            ip_img = val.file5[0].response.data.url
        } else {
            ip_img = val.file5[0].url
        }
        if (val.file6[0].response) {
            ending_img = val.file6[0].response.data.url
        } else {
            ending_img = val.file6[0].url
        }


        // console.log(val.file7)
        if(val.file7!=undefined){
            if (val.file7.length!=0 && val.file7[0].response) {
                win_record = val.file7[0].response.data.url
            }else if(val.file7.length!=0 && val.file7[0].url&&val.file7[0].url!=undefined && val.file7[0].url!=null){
                win_record = val.file7[0].url
            }else{
                win_record = null
            }
        }else{
            win_record = null
        }
     
        if(val.file8!=undefined){
            if (val.file8.length!=0 && val.file8[0].response) {
                score_details_img = val.file8[0].response.data.url
            }else if(val.file8.length!=0 && val.file8[0].url&&val.file8[0].url!=undefined && val.file8[0].url!=null){
                score_details_img = val.file8[0].url
            }else{
                score_details_img = null
            }
        }else{
            score_details_img = null
        }
       
        
        backgroundUpdate(`activity_id=${pageid}&background=${background}&ranking=${ranking}&rule=${rule}&open=${open}&ip_img=${ip_img}&ending_img=${ending_img}&win_record=${win_record}&score_details_img=${score_details_img}`).then((res) => {
            console.log(res)
            if (res.code == 0) {

                message.success("保存成功");
                hqlb()
                // navigate(`/background/activity`)
            } else {

                message.error(res.message);

            }
        })
    }

    const onChanges = (e) => {
        console.log('radio checked', e.target.value);
        stk(e.target.value);
    };

    const [form] = Form.useForm();

    const uploadButton = (
        <div>
            <UploadOutlined style={{

                fontSize: "30px",
                color: "rgb(0 99 246)",
            }} />
            <div
                style={{
                    // marginTop: 8,
                    fontSize: "10px",
                    color: "#6a9eec",
                    textAlign: "justify"
                }}
            >
                类型:.jpg,.png<br />
                大小:500kb以内<br />
                尺寸:750*1624
            </div>
        </div>
    );
    const uploadButton2 = (
        <div>
            <UploadOutlined style={{

                fontSize: "30px",
                color: "rgb(0 99 246)",
            }} />
            <div
                style={{
                    // marginTop: 8,
                    fontSize: "10px",
                    color: "#6a9eec",
                    textAlign: "justify"
                }}
            >
                类型:.png<br />
                大小:20kb以内<br />
                尺寸:80*214
            </div>
        </div>
    );
    const uploadButton3 = (
        <div>
            <UploadOutlined style={{

                fontSize: "30px",
                color: "rgb(0 99 246)",
            }} />
            <div
                style={{
                    // marginTop: 8,
                    fontSize: "10px",
                    color: "#6a9eec",
                    textAlign: "justify"
                }}
            >
                类型:.png<br />
                大小:20kb以内<br />
                尺寸:80*164
            </div>
        </div>
    );
    const uploadButton4 = (
        <div>
            <UploadOutlined style={{

                fontSize: "30px",
                color: "rgb(0 99 246)",
            }} />
            <div
                style={{
                    // marginTop: 8,
                    fontSize: "10px",
                    color: "#6a9eec",
                    textAlign: "justify"
                }}
            >
                类型:.png<br />
                大小:20kb以内<br />
                尺寸:420*121
            </div>
        </div>
    );
    const uploadButton5 = (
        <div>
            <UploadOutlined style={{

                fontSize: "30px",
                color: "rgb(0 99 246)",
            }} />
            <div
                style={{
                    // marginTop: 8,
                    fontSize: "10px",
                    color: "#6a9eec",
                    textAlign: "justify"
                }}
            >
                类型:.png<br />
                大小:20kb以内<br />
                尺寸:210*247
            </div>
        </div>
    );
    const uploadButton6 = (
        <div>
            <UploadOutlined style={{

                fontSize: "30px",
                color: "rgb(0 99 246)",
            }} />
            <div
                style={{
                    // marginTop: 8,
                    fontSize: "10px",
                    color: "#6a9eec",
                    textAlign: "justify"
                }}
            >
                类型:.png<br />
                大小:100kb以内<br />
                尺寸:600*508
            </div>
        </div>
    );

    return (
        <div className='bodys'>
            <Spin spinning={loadas} >
            {/* <Breadcrumb separator=">" style={{ position: "relative" }}>
                <Breadcrumb.Item>
                    <a onClick={() => {
                        navigate(`/background/activity`)
                    }}>活动列表</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a >首页编辑({paname})</a>
                </Breadcrumb.Item>
            </Breadcrumb> */}
            <br />

            <Form
                form={form}
                onFinish={onFinish}
                name="wrap"
                labelCol={{
                    flex: '130px',
                }}
                labelAlign="left"
                // labelWrap
                wrapperCol={{
                    flex: 1,
                }}
                colon={false}
            >
                <Form.Item label="背景图" name="file1" rules={[
                    {
                        required: true,
                        validator: async (a, names) => {
                            console.log(names)
                            if (!fileList || fileList.length < 1) {
                                return Promise.reject(new Error('背景图不能为空'));
                            } else {
                                return Promise.resolve()
                            }
                        },
                    },
                ]}
                >
                    <div>
                        <Upload
                            action={axios.defaults.baseURL+'/upload'}
                            listType="picture-card"
                            headers={{
                                'Authorization': window.localStorage.getItem("token") || '',
                                'X-Requested-With': null

                            }}

                            accept="image/*"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleChange}
                        >
                            {fileList.length >= 1 ? null : uploadButton}
                        </Upload>

                        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img
                                alt="example"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal>
                    </div>

                </Form.Item>
                <Form.Item label="查看排行榜按钮" name="file2"
                    rules={[
                        {
                            required: true,
                            validator: async (a, names) => {
                                console.log(names)
                                if (!fileList2 || fileList2.length < 1) {
                                    return Promise.reject(new Error('查看排行榜按钮不能为空'));
                                } else {
                                    return Promise.resolve()
                                }
                            },
                        },
                    ]}
                >
                    <div>
                        <Upload
                            action={axios.defaults.baseURL+'/upload'}
                            listType="picture-card"
                            headers={{
                                'Authorization': window.localStorage.getItem("token") || '',
                                'X-Requested-With': null

                            }}

                            accept="image/*"
                            fileList={fileList2}
                            onPreview={handlePreview}
                            onChange={handleChange2}
                        >
                            {fileList2.length >= 1 ? null : uploadButton2}
                        </Upload>

                        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img
                                alt="example"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal>
                    </div>
                </Form.Item>
                <Form.Item label="规则按钮" name="file3"
                    rules={[
                        {
                            required: true,
                            validator: async (a, names) => {
                                console.log(names)
                                if (!fileList3 || fileList3.length < 1) {
                                    return Promise.reject(new Error('规则按钮不能为空'));
                                } else {
                                    return Promise.resolve()
                                }
                            },
                        },
                    ]}>
                    <div>
                        <Upload
                            action={axios.defaults.baseURL+'/upload'}
                            listType="picture-card"
                            headers={{
                                'Authorization': window.localStorage.getItem("token") || '',
                                'X-Requested-With': null

                            }}

                            accept="image/*"
                            fileList={fileList3}
                            onPreview={handlePreview}
                            onChange={handleChange3}
                        >
                            {fileList3.length >= 1 ? null : uploadButton3}
                        </Upload>

                        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img
                                alt="example"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal></div>
                </Form.Item>


                <Form.Item label="中奖记录按钮" name="file7"
                    // rules={[
                    //     {
                    //         required: true,
                    //         validator: async (a, names) => {
                    //             console.log(names)
                    //             if (!fileList3 || fileList3.length < 1) {
                    //                 return Promise.reject(new Error('规则按钮不能为空'));
                    //             } else {
                    //                 return Promise.resolve()
                    //             }
                    //         },
                    //     },
                    // ]}
                    >
                    <div>
                        <Upload
                            action={axios.defaults.baseURL+'/upload'}
                            listType="picture-card"
                            headers={{
                                'Authorization': window.localStorage.getItem("token") || '',
                                'X-Requested-With': null

                            }}

                            accept="image/*"
                            fileList={fileList7}
                            onPreview={handlePreview}
                            onChange={handleChange7}
                        >
                            {fileList7.length >= 1 ? null : uploadButton2}
                        </Upload>

                        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img
                                alt="example"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal></div>
                </Form.Item>
                <Form.Item label="分数详情按钮" name="file8"
                    // rules={[
                    //     {
                    //         required: true,
                    //         validator: async (a, names) => {
                    //             console.log(names)
                    //             if (!fileList3 || fileList3.length < 1) {
                    //                 return Promise.reject(new Error('规则按钮不能为空'));
                    //             } else {
                    //                 return Promise.resolve()
                    //             }
                    //         },
                    //     },
                    // ]}
                    >
                    <div>
                        <Upload
                            action={axios.defaults.baseURL+'/upload'}
                            listType="picture-card"
                            headers={{
                                'Authorization': window.localStorage.getItem("token") || '',
                                'X-Requested-With': null

                            }}

                            accept="image/*"
                            fileList={fileList8}
                            onPreview={handlePreview}
                            onChange={handleChange8}
                        >
                            {fileList8.length >= 1 ? null : uploadButton2}
                        </Upload>

                        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img
                                alt="example"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal></div>
                </Form.Item>

                <Form.Item label="开启按钮" name="file4"
                    rules={[
                        {
                            required: true,
                            validator: async (a, names) => {
                                console.log(names)
                                if (!fileList4 || fileList4.length < 1) {
                                    return Promise.reject(new Error('开启按钮不能为空'));
                                } else {
                                    return Promise.resolve()
                                }
                            },
                        },
                    ]}>
                    <div>
                        <Upload
                            action={axios.defaults.baseURL+'/upload'}
                            listType="picture-card"
                            headers={{
                                'Authorization': window.localStorage.getItem("token") || '',
                                'X-Requested-With': null

                            }}
                            accept="image/*"

                            fileList={fileList4}
                            onPreview={handlePreview}
                            onChange={handleChange4}
                        >
                            {fileList4.length >= 1 ? null : uploadButton4}
                        </Upload>

                        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img
                                alt="example"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal></div>
                </Form.Item>

                <Form.Item label="答题后成绩弹窗" name="file6"
                    rules={[
                        {
                            required: true,
                            validator: async (a, names) => {
                                console.log(names)
                                if (!fileList6 || fileList6.length < 1) {
                                    return Promise.reject(new Error('答题后成绩弹窗不能为空'));
                                } else {
                                    return Promise.resolve()
                                }
                            },
                        },
                    ]}>
                    <div>
                        <Upload
                            action={axios.defaults.baseURL+'/upload'}
                            listType="picture-card"
                            headers={{
                                'Authorization': window.localStorage.getItem("token") || '',
                                'X-Requested-With': null

                            }}
                            accept="image/*"

                            fileList={fileList6}
                            onPreview={handlePreview}
                            onChange={handleChange6}
                        >
                            {fileList6.length >= 1 ? null : uploadButton6}
                        </Upload>

                        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img
                                alt="example"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal></div>
                </Form.Item>



                <Form.Item label="IP图片" name="file5"
                    rules={[
                        {
                            required: true,
                            validator: async (a, names) => {
                                console.log(names)
                                if (!fileList5 || fileList5.length < 1) {
                                    return Promise.reject(new Error('IP图片不能为空'));
                                } else {
                                    return Promise.resolve()
                                }
                            },
                        },
                    ]}>
                    <div style={{ width: '600px' }}>
                        <Upload
                            action={axios.defaults.baseURL+'/upload'}
                            listType="picture-card"
                            headers={{
                                'Authorization': window.localStorage.getItem("token") || '',
                                'X-Requested-With': null

                            }}
                            accept="image/*"

                            fileList={fileList5}
                            onPreview={handlePreview}
                            onChange={handleChange5}
                        >
                            {fileList5.length >= 1 ? null : uploadButton5}
                        </Upload>

                        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img
                                alt="example"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal>
                    </div>
                    {/* <Button type="primary" ghost style={{position: "relative",display:"inline-block",left:'110px',top:'-38px'}}>预览</Button> */}
                    <Radio.Group onChange={onChanges} value={tk}>
                        <Radio value={1}>预览姓名弹框</Radio>
                        <Radio value={2}>预览成绩弹框</Radio>
                        <Radio value={3}>预览排行榜弹框</Radio>
                        <Radio value={4}>取消弹框预览</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label=" ">
                    <Button type="primary" htmlType="submit">
                        上传
                    </Button>
                </Form.Item>
            </Form>
            <div className='phone' id='phone'>
                <div className='bgz'>
                    <img src={require('../../../assets/dtbtn.png')} className="btn1" id='btn1' />
                    <img src={require('../../../assets/phbbtn.png')} className="btn2" id='btn2' />
                    <img src={require('../../../assets/gzbtn.png')} className="btn3" id="btn3" />
                    <img src='' className="btn8" id="btn8" />
                    <img src='' className="btn7" id='btn7' />
                    
                </div>
                {tk != 4 && <div className='mengcs'>
                    {tk == 1 && <div className='bgzs'>
                        <img src={xrimg} className="xiaoren" />
                        <img src={require('../../../assets/ku.png')} className="xmk" />
                    </div>}
                    {tk == 2 && <div className='cjk'>
                        <img src={bgimg} className="defen" />
                        <img src={xrimg} className="xiaorens" />
                    </div>}
                    {tk == 3 && <div className="bgaz">
                        <img src={xrimg} className="xiaor" />
                        <img src={require('../../../assets/k3.png')} className="sphb" />
                    </div>}
                </div>}
            </div>
            </Spin>
        </div>
    );
};

