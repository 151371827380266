// import { } from 'antd';
import React, { Component, useEffect, useState } from 'react'
import zhCN from 'antd/lib/locale/zh_CN';
import moment from "moment";
import "moment/locale/zh-cn";
import { UploadOutlined } from '@ant-design/icons';
// moment.locale("zh-cn");
import { Menu, Upload, Switch, Form, Spin, DatePicker, Input, Button, ConfigProvider, message, Space, Radio, Breadcrumb, Checkbox, Row, Col } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
// import "./details.css"
import { getActivityInfo } from "../../../../api/api.js"; //获取活动详情
import { activityBasicUpdate } from "../../../../api/api.js"; //保存基本配置
import { ActivityUpdate } from "../../../../api/api.js"; //保存基本配置
import { BrowserRouter, Link, Routes, Route, Outlet } from "react-router-dom" //引入路由模块
// import Na from "./configuration"
import * as XLSX from 'xlsx';
// import {  } from '@ant-design/icons';
import { useNavigate, useLocation } from "react-router-dom" //引入路由模块
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const formItemLayout = {
  
    labelCol: {
      span: 3,
    },
    wrapperCol: {
      span: 21,
    },
  
};
const formItemLayouts = {

  span: 4,


};
const formItemLayouts2 = {

  span: 6,


};
const formItemLayoutsa = {

  span: 8,


};

export default function Details() {
  const navigate = useNavigate();
  const location = useLocation()
  let totoken = window.localStorage.getItem("token");
  // console.log(totoken)
  if (totoken == null || totoken == "") {
    // alert(1)
    navigate("/")
  }
  const error = (a) => {
    message.error(a);
  };

  // let [loads, sloads] = useState(false)//加载状态
  // let [mold, smold] = useState('mai1')//基本设置按钮加载状态
  let [load1, sload1] = useState(false)//基本设置按钮加载状态
  let [load2, sload2] = useState(false)//高级设置按钮加载状态
  let [tyt1, styt1] = useState(true)//随机题目的开关
  let [tyt2, styt2] = useState(true)//答题次数限制的开关
  let [tyt3, styt3] = useState(true)//答案随机的开关
  let [tyt4, styt4] = useState(true)//答案解析的开关
  let [tyt5, styt5] = useState(true)//答题时间的开关
  let [tyt6, styt6] = useState(true)//跳转链接的开关
  let [tyt7, styt7] = useState(true)//答题后标语的开关
  let [tyt8, styt8] = useState(true)//姓名输入的开关
  let [tyt9, styt9] = useState(true)//排行榜的开关
  let [tyt10, styt10] = useState(true)//部门配置的开关
  let [tyt11, styt11] = useState(true)//答案随机的开关
  let [tyt12, styt12] = useState(true)//口令的开关
  let [tyt13, styt13] = useState(true)//答题时间统计的开关
  let [tyt14, styt14] = useState(true)//分数累计的开关

  let [compDepart, scompDepart] = useState('')//公司或部门

  let [tyt15, styt15] = useState([])//部门列表中的搜索框提示文案
  let [tyt17, styt17] = useState([])//部门列表中的其他提示文案


  let [tyt_else, styt_else] = useState([])//公司列表中的其他提示文案
  let [tyt_search, styt_search] = useState([])//公司列表中的其他提示文案

  
  let [tyt16, styt16] = useState([])//试卷回顾



  let [uiji, suiji] = useState('')//答案随机的开关

  let [timesuiji, stimesuiji] = useState('')//时间限制的类型




  let [dataAnalysis, sdataAnalysis] = useState({})
  // 获取页面参数
  function hqcorid(key, Url) {
    var str = Url;
    str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
    // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
    var arr = str.split("&");
    // console.log(666666,arr)
    var obj = new Object();
    // 将每一个数组元素以=分隔并赋给obj对象
    for (var i = 0; i < arr.length; i++) {
      var tmp_arr = arr[i].split("=");
      obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
    }
    // console.log("1234564,", obj[key]);
    return obj[key];
  }
  let pageid = hqcorid("keid", location.search)
  let paname = hqcorid("name", location.search)
  //使用生命周期的钩子

  useEffect(() => {
    // console.log("挂载完成,更新完成")
    acquire()
  }, [])
  // 获取活动详情
  function acquire() {
    if (pageid) {
      sload1(true)
      // id=(hqcorid("id",location.search)).replace(/@@/g,"%")

      getActivityInfo(`id=${pageid}`).then((res) => {
        // console.log(res)
        if (res.code == 0) {
          console.log(res)
          sdataAnalysis(res.data.info)
          basicSettings(res.data.info)
        } else {
          error('未知错误获取详情失败，刷新页面重试')
          return
        }

      })
    } else {
      error('无法获取活动id，请退出重试')
    }
  }
  const success = (e) => {
    message.success(e);
  };
  const [form2] = Form.useForm();

  // 高级设置赋值
  function basicSettings(a) {
    // let data={
    //   id:pageid,
    //   // sw1(随机题目配置)-------------------------------------------------
    //   random:v.switch1?2:1,//随机开关 1关闭 2开启
    //   single_choice:v.switch1a?v.switch1a:null,//单选数量
    //   multiple_choice:v.switch1b?v.switch1b:null,//多选数量
    //   judge:v.switch1c?v.switch1c:null,//判断数量
    //   // sw2(答题次数限制)-------------------------------------------------
    //   count_status:v.switch2?2:1,//是否开启答题次数 1 不开启 2开启
    //   count:v.switch2a?v.switch2a:null,//答题次数
    //   // sw3(答案随机)-------------------------------------------------

    //   // sw4(显示答案解析)-------------------------------------------------
    //   analysis_status:v.switch4?2:1,//是否开启问题解析 1不需要 2需要
    //   // sw5(答题时间限制)-------------------------------------------------
    //   time_status:v.switch5?2:1,//是否开启倒计时 1不需要 2需要
    //   time:v.switch5a?v.switch5a:null,////倒计时时长
    //   // sw6(跳转链接)-------------------------------------------------
    //   draw_status:v.switch6?2:1,//是否跳转链接 0不开启 1开启
    //   draw_url:v.switch6a?v.switch6a:null,//抽奖链接
    //   draw_fraction:v.switch6b?v.switch6b:null,//抽奖分值
    //   draw_button:v.switch6c?v.switch6c:null,//抽奖按钮文案
    //   // sw7(答题后标语)-------------------------------------------------
    //   prompt_status:v.switch7?2:1,//答题后标语 1不开启 2开启
    //   prompt_score:v.switch7a?v.switch7a:null,//及格分值
    //   prompt_arrive:v.switch7b?v.switch7b:null,//及格文案
    //   prompt_not:v.switch7c?v.switch7c:null,//不及格文案
    //   // sw8(姓名输入)-------------------------------------------------
    //   name_status:v.switch8?2:1,// 是否需要填写名称 1不需要 2 需要
    //   mztx:v.switch8a?v.switch8a:null,//昵称填写提示文
    //   jgxz:v.switch8b?v.switch8b:null,//选择列表提示文
    //   jgtx:v.switch8c?v.switch8c:null,//填写列表提示文
    //   // sw9(排行榜)-------------------------------------------------
    //   ranking:v.switch9?2:1,//是否开启排行榜 1 不开启 2 开启
    //   ranking_day:v.switch9a?(v.switch9a.indexOf('A')!=-1?2:1):1,//是否开启日排行榜 1 不开启 2 开启
    //   ranking_all:v.switch9a?(v.switch9a.indexOf('B')!=-1?2:1):1,//是否开启总排行榜 1 不开启 2 开启
    //   ranking_department:v.switch9a?(v.switch9a.indexOf('C')!=-1?2:1):1,//是否开启部门排行榜 1 不开启 2 开启
    //   // sw10(部门配置)-------------------------------------------------
    //   department_type:v.switch10?2:1,//是否开启部门 1不开启 2开启
    //   else:v.switch10a?(v.switch10a.indexOf('A')!=-1?2:1):1,//是否开启部门其他 1不开启 2开启
    //   department:bbmm,//部门列表

    // }
    let datas = []
    if (a.department != null && a.department.length != 0) {
      for (var i = 0; i < a.department.length; i++) {
        datas.push(a.department[i].name)
      }
    }

    let datasa = []
    if (a.company != null && a.company.length != 0) {
      for (var i = 0; i < a.company.length; i++) {
        datasa.push(a.company[i].name)
      }
    }
    // console.log(datas)
    let ppa = []
    if (a.ranking_day == 2) {
      ppa.push("A")
    }
    if (a.ranking_all == 2) {
      ppa.push("B")
    }
    if (a.ranking_department == 2) {
      ppa.push("C")
    }
    if (a.ranking_score_all == 2) {
      ppa.push("D")
    }

    form2.setFieldsValue({
      code_status: a.code_status == 2 ? true : false,
      code_content: a.code_content,
      code_prompt: a.code_prompt,
      switch1: a.random == 2 ? true : false,
      switch1ss: a.random_type == 1 ? 'mail' : a.random_type == 2 ? 'offline' : '',

      switch1a: a.single_choice,
      switch1b: a.multiple_choice,
      switch1c: a.judge,
      switch1d: a.random_num,

      switch2: a.count_status == 2 ? true : false,
      switch2a: a.count,
      count_day: a.count_day == 2 ? (["A"]) : [],

      switch4: a.analysis_status == 2 ? true : false,

      switch5: a.time_status == 2 ? true : false,
      time_type: a.time_type == 1 ? 'mail' : a.time_type == 2 ? 'offline' : '',
      switch5a: a.time,

      switch6: a.draw_status == 1 ? true : false,
      switch6a: a.draw_url,
      switch6b: a.draw_fraction,
      switch6c: a.draw_button,

      switch7: a.prompt_status == 2 ? true : false,
      switch7a: a.prompt_score,
      switch7b: a.prompt_arrive,
      switch7c: a.prompt_not,

      switch8: a.name_status == 2 ? true : false,
      switch8a: a.mztx,
      switch8b: a.jgxz,
      switch8c: a.jgtx,

      switch9: a.ranking_status == 2 ? true : false,
      switch9a: ppa,

      switch10: a.department_type == 2 ? true : false,
      switch10a: a.else == 2 ? (["A"]) : [],
      department_search: a.department_search == 2 ? (["A"]) : [],
      department_search_copy: a.department_search_copy,
      login_status: a.login_status == 2 ? (["A"]) : [],
      users: datas,

      switch11: a.shuffle_status == 2 ? true : false,
      ranking_time: a.ranking_time == 2 ? true : false,
      score_details: a.score_details == 2 ? true : false,

      review_type: a.review_type == 2 ? true : false,

      department_company:a.department_company==1?"1":a.department_company==2?"2":"",
      company_xz:a.company_xz,
      company_else:a.company_else == 2 ? (["A"]) : [],
      company_tx:a.company_tx,
      company_search: a.company_search == 2 ? (["A"]) : [],
      company_search_copy: a.company_search_copy,
      company: datasa,
      company_department_tx:a.company_department_tx,
    })

    scompDepart(a.department_company==1?"1":a.department_company==2?"2":"")//部门或者公司
    styt_else(a.company_else == 2 ? (["A"]) : [])
    styt_search(a.company_search == 2 ? (["A"]) : [])

    styt1(a.random == 2 ? true : false)
    styt2(a.count_status == 2 ? true : false)
    // styt3()
    styt4(a.analysis_status == 2 ? true : false)
    styt5(a.time_status == 2 ? true : false)
    styt6(a.draw_status == 1 ? true : false)
    styt7(a.prompt_status == 2 ? true : false)
    styt8(a.name_status == 2 ? true : false)
    styt9(a.ranking_status == 2 ? true : false)
    styt10(a.department_type == 2 ? true : false)
    styt11(a.shuffle_status == 2 ? true : false)
    styt12(a.code_status == 2 ? true : false)
    styt13(a.ranking_time == 2 ? true : false)
    styt14(a.score_details == 2 ? true : false)
    styt15(a.department_search == 2 ? (["A"]) : [])

    styt17(a.else == 2 ? (["A"]) : [])


    styt16(a.review_type == 2 ? true : false)


    sload1(false)

    suiji(a.random_type == 1 ? 'mail' : a.random_type == 2 ? 'offline' : '')
    stimesuiji(a.time_type == 1 ? 'mail' : a.time_type == 2 ? 'offline' : '')
  }

  // 高级设置题交按钮
  const onFinish2 = v => {
    // console.log(v)




    let bbmm = null
    let bbmms = null

    if (v.users) {
      if (v.users.length != 0) {
        bbmm = []
        for (var i = 0; i < v.users.length; i++) {
          bbmm.push({
            id: i + 1,
            name: v.users[i]
          })
        }
        bbmm = JSON.stringify(bbmm)
      } else {
        bbmm = JSON.stringify([])
        if (v.switch10a.indexOf('A') == -1) {
          error('部门配置 中的 （手动填写）和 （添加部门） 不能同时不勾选和不添加，最少要编辑其中一个。')
          return
        }
      }
    }
    if (v.company) {
      if (v.company.length != 0) {
        bbmms = []
        for (var i = 0; i < v.company.length; i++) {
          bbmms.push({
            id: i + 1,
            name: v.company[i]
          })
        }
        bbmms = JSON.stringify(bbmms)
      } else {
        bbmms = JSON.stringify([])
        if (v.company_else.indexOf('A') == -1) {
          error('公司配置 中的 （手动填写）和 （添加公司） 不能同时不勾选和不添加，最少要编辑其中一个。')
          return
        }
      }
    }

    // return
    if (v.switch10) {
      if (!v.switch8) {
        error(' 部门配置或公司配置 需在开启姓名输入的时候才会生效')
        return
      }
    }

    sload2(true)
    // console.log(bbmm)
    // return
    let data = {
      id: pageid,
      // sw8(口令输入)-------------------------------------------------
      code_status: v.code_status ? 2 : 1,// 是否需要填写名称 1不需要 2 需要
      code_content: v.code_content ? v.code_content : null,//昵称填写提示文
      code_prompt: v.code_prompt ? v.code_prompt : null,//选择列表提示文

      // sw1(随机题目配置)-------------------------------------------------
      random: v.switch1 ? 2 : 1,//随机开关 1关闭 2开启
      random_type: v.switch1ss ? (v.switch1ss == 'mail' ? 1 : v.switch1ss == 'offline' ? 2 : '') : null,
      single_choice: v.switch1a ? v.switch1a : null,//单选数量
      multiple_choice: v.switch1b ? v.switch1b : null,//多选数量
      judge: v.switch1c ? v.switch1c : null,//判断数量
      random_num: v.switch1d ? v.switch1d : null,//随机数量

      // sw2(答题次数限制)-------------------------------------------------
      count_status: v.switch2 ? 2 : 1,//是否开启答题次数 1 不开启 2开启
      count: v.switch2a ? v.switch2a : null,//答题次数
      count_day: v.count_day ? (v.count_day.indexOf('A') != -1 ? 2 : 1) : 1,//是否开启部门其他 1不开启 2开启
      // sw3(答案随机)-------------------------------------------------

      // sw4(显示答案解析)-------------------------------------------------
      analysis_status: v.switch4 ? 2 : 1,//是否开启问题解析 1不需要 2需要
      // sw5(答题时间限制)-------------------------------------------------
      time_status: v.switch5 ? 2 : 1,//是否开启倒计时 1不需要 2需要
      time_type: v.time_type ? (v.time_type == 'mail' ? 1 : v.time_type == 'offline' ? 2 : '') : null,//1是总时间 2是单题

      time: v.switch5a ? v.switch5a : null,////倒计时时长
      // sw6(跳转链接)-------------------------------------------------
      draw_status: v.switch6 ? 1 : 0,//是否跳转链接 0不开启 1开启
      draw_url: v.switch6a ? v.switch6a : null,//抽奖链接
      draw_fraction: v.switch6b ? v.switch6b : null,//抽奖分值
      draw_button: v.switch6c ? v.switch6c : null,//抽奖按钮文案
      // sw7(答题后标语)-------------------------------------------------
      prompt_status: v.switch7 ? 2 : 1,//答题后标语 1不开启 2开启
      prompt_score: v.switch7a ? v.switch7a : null,//及格分值
      prompt_arrive: v.switch7b ? v.switch7b : null,//及格文案
      prompt_not: v.switch7c ? v.switch7c : null,//不及格文案
      // sw8(姓名输入)-------------------------------------------------
      name_status: v.switch8 ? 2 : 1,// 是否需要填写名称 1不需要 2 需要
      mztx: v.switch8a ? v.switch8a : null,//昵称填写提示文
      jgxz: v.switch8b ? v.switch8b : null,//部门选择列表提示文
      jgtx: v.switch8c ? v.switch8c : null,//部门填写列表提示文

      login_status: v.login_status ? (v.login_status.indexOf('A') != -1 ? 2 : 1) : 1,
      // sw9(排行榜)-------------------------------------------------
      ranking_status: v.switch9 ? 2 : 1,//是否开启排行榜 1 不开启 2 开启
      ranking_day: v.switch9a ? (v.switch9a.indexOf('A') != -1 ? 2 : 1) : 1,//是否开启日排行榜 1 不开启 2 开启
      ranking_all: v.switch9a ? (v.switch9a.indexOf('B') != -1 ? 2 : 1) : 1,//是否开启总排行榜 1 不开启 2 开启
      ranking_department: v.switch9a ? (v.switch9a.indexOf('C') != -1 ? 2 : 1) : 1,//是否开启部门排行榜 1 不开启 2 开启
      ranking_score_all: v.switch9a ? (v.switch9a.indexOf('D') != -1 ? 2 : 1) : 1,//是否开启部门排行榜 1 不开启 2 开启

      // sw10(部门配置)-------------------------------------------------
      department_type: v.switch10 ? 2 : 1,//是否开启部门或公司配置 1不开启 2开启
      department_company:v.department_company?(v.department_company=="1"?1:2):null,//部门或公司 1部门 2公司
      company_xz: v.company_xz ? v.company_xz : null,//公司选择列表提示文
      
      company_else: v.company_else ? (v.company_else.indexOf('A') != -1 ? 2 : 1) : 1,//是否开启公司其他 1不开启 2开启
      company_tx: v.company_tx ? v.company_tx : null,//公司填写列表提示文
      company_search: v.company_search ? (v.company_search.indexOf('A') != -1 ? 2 : 1) : 1,//是否开启搜索框其他 1不开启 2开启
      company_search_copy: v.company_search_copy ? v.company_search_copy : null,//搜索框提示文案
      company: bbmms,//公司列表
      company_department_tx:v.company_department_tx ? v.company_department_tx : null,//公司下的部门提示文案
      else: v.switch10a ? (v.switch10a.indexOf('A') != -1 ? 2 : 1) : 1,//是否开启部门其他 1不开启 2开启
      department_search: v.department_search ? (v.department_search.indexOf('A') != -1 ? 2 : 1) : 1,//是否开启搜索框其他 1不开启 2开启
      department_search_copy: v.department_search_copy ? v.department_search_copy : null,//搜索框提示文案

      department: bbmm,//部门列表

      // sw11(答案随机)-------------------------------------------------
      shuffle_status: v.switch11 ? 2 : 1,//是否开启答案随机 1不开启 2开启
      // 答题时间统计(答案随机)-------------------------------------------------
      ranking_time: v.ranking_time ? 2 : 1,//是否开启答案随机 1不开启 2开启
      // 分数累计-------------------------------------------------
      score_details: v.score_details ? 2 : 1,//是否开启答案随机 1不开启 2开启

      // 试卷回顾-------------------------------------------------
      review_type: v.review_type ? 2 : 1,//是否开启答案随机 1不开启 2开启
    }
    console.log(data)
    ActivityUpdate(data).then((res => {
      console.log(res)

      if (res.code == 0) {
        // refresh()
        sload2(false)
        success("保存成功")
        acquire()
        // stcf(false)
      }
    }))
  }


  const handleBeforeUpload = (event,a) => {

    // return
    const file = event
    const reader = new FileReader();
    reader.onload = (e) => {
      const workbook = XLSX.read(e.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      const firstColumnData = json.map((row) => row['名称']);
      // console.log(firstColumnData)
      // setData(firstColumnData);
      if(a=='1'){
        form2.setFieldsValue({
          users: firstColumnData,
        })
      }else{
        form2.setFieldsValue({
          company: firstColumnData,
        })
      }

    };
    reader.readAsBinaryString(file);
  };
  // const [uploading, setUploading] = useState(false);
  const props = {
    beforeUpload: (file) => {

      handleBeforeUpload(file,'1')
      return false;
    },

  };
  const propsa = {
    beforeUpload: (file) => {

      handleBeforeUpload(file,'2')
      return false;
    },

  };



  return (

    <div className='bodys'>
      <Spin spinning={load1} >
        {/* 高级设置*/}

        <div style={{ position: "relative", top: "10px" }}><ConfigProvider locale={zhCN}>
          <Form labelAlign={'right'} name="from2" {...formItemLayout} form={form2} onValuesChange={(a, b) => { }} onFinish={onFinish2} autoComplete="off">
            {/* ----------1------------------------------------------------------------------------------------------- */}
            <Form.Item name="code_status" label="口令输入" valuePropName="checked">
              <Switch onChange={(e) => { styt12(e) }} />
            </Form.Item>

            {tyt12 == true &&
              <div>
                <Form.Item labelCol={formItemLayouts} label="口令" name="code_content" rules={[{ required: true, message: '口令不能为空' }]}>
                  <Input placeholder="口令" style={{ width: '40%' }} showCount maxLength={12} />
                </Form.Item>
                <Form.Item labelCol={formItemLayouts} label="口令提示文案" name="code_prompt" rules={[{ required: true, message: '口令提示文案不能为空' }]}>
                  <Input placeholder="请输入口令提示文案" style={{ width: '40%' }} showCount maxLength={12} />
                </Form.Item>
              </div>
            }
            {/* ---------------9-------------------------------------------------------------------------------------- */}
            <hr style={{ border: 'none', borderTop: '2px dashed rgb(249 203 203)',marginTop:'10px',marginBottom:'10px' }} />
            <Form.Item name="switch1" label="随机题目配置" valuePropName="checked" >
              <Switch onChange={(e) => { styt1(e) }} />
            </Form.Item>
            {tyt1 == true &&
              <div>
                <Form.Item  labelCol={formItemLayouts} name="switch1ss" label="随机类型" rules={[{ required: true, message: '随机类型不能为空' }]}>
                  <Radio.Group onChange={(e) => { suiji(e.target.value); console.log(e.target.value) }} >
                    <Radio value="mail">自定义单选,多选,判断,数量</Radio>
                    <Radio value="offline">随机单选,多选,判断,数量</Radio>
                    {/* <Radio value="maof"> 邮寄+线下 </Radio> */}
                  </Radio.Group>
                </Form.Item>
                {uiji != 'offline' && <>
                  <Form.Item labelCol={formItemLayouts} label="单选" name="switch1a" rules={[{ required: true, message: '单选题随机题目数量不能为空（如果没有请输入0）' }]}>
                    <Input type="number" placeholder="单选题随机题目数量（如果没有请输入0）" style={{ width: '40%' }} />
                  </Form.Item>
                  <Form.Item labelCol={formItemLayouts} label="多选" name="switch1b" rules={[{ required: true, message: '多选题随机题目数量不能为空（如果没有请输入0）' }]}>
                    <Input type="number" placeholder="多选题随机题目数量（如果没有请输入0）" style={{ width: '40%' }} />
                  </Form.Item>
                  <Form.Item labelCol={formItemLayouts} label="判断" name="switch1c" rules={[{ required: true, message: '判断题随机题目数量不能为空（如果没有请输入0）' }]}>
                    <Input type="number" placeholder="判断题随机题目数量（如果没有请输入0）" style={{ width: '40%' }} />
                  </Form.Item>
                </>}
                {uiji != 'mail' && <>
                  <Form.Item labelCol={formItemLayouts} label="随机数量" name="switch1d" rules={[{ required: true, message: '判断题随机题目数量不能为空（如果没有请输入0）' }]}>
                    <Input type="number" placeholder="请输入随机题目数量" style={{ width: '40%' }} />
                  </Form.Item>
                </>}
              </div>
            }
            {/*--------------11------------------------------------------------------------------------------------------*/}
            <hr style={{ border: 'none', borderTop: '2px dashed rgb(249 203 203)',marginTop:'10px',marginBottom:'10px' }} />
            <Form.Item name="switch11" label="答案随机" valuePropName="checked">


              <Switch onChange={(e) => { styt11(e) }} />
            </Form.Item>
            {/* -------------2---------------------------------------------------------------------------------------- */}
            <hr style={{ border: 'none', borderTop: '2px dashed rgb(249 203 203)',marginTop:'10px',marginBottom:'10px' }} />
            <Form.Item name="ranking_time" label="答题时间统计" valuePropName="checked">


              <Switch onChange={(e) => { styt13(e) }} />
            </Form.Item>
            <Col span={20} offset={1} style={{ color: 'red', marginTop: '10px' }}>注意：1. 开启后将统计用户的答题时间，排行榜将根据分数和时间来排列。分数越高，时间越短，排在越前面。</Col>
            <Col span={20} offset={1} style={{ color: 'red', marginTop: '10px' }}>注意：2. 开启后 分数详情 和 排行榜 会显示用户的答题时间</Col>
            {/* -------------2---------------------------------------------------------------------------------------- */}
            <hr style={{ border: 'none', borderTop: '2px dashed rgb(249 203 203)',marginTop:'10px',marginBottom:'10px' }} />
            <Form.Item name="review_type" label="试卷回顾" valuePropName="checked">


              <Switch onChange={(e) => { styt16(e) }} />
            </Form.Item>
            {/* <div style={{color:'red',marginTop:'10px'}}>注意：开启后会展示用户</div> */}
            {/* <div style={{color:'red',marginTop:'10px'}}>注意：2. 开启后 分数详情 和 排行榜 会显示用户的答题时间</div> */}
            {/* <hr style={{border:'none',borderTop:'1px dashed rgb(236 3 3)',}}/>
<Form.Item name="ranking_time" label="累积分值" valuePropName="checked">

  
              <Switch onChange={(e) => { styt13(e) }} />
            </Form.Item>
<div style={{color:'red',marginTop:'10px'}}>注意：开启后将在首页展示用户的累计分数</div> */}

            {/* -------------2---------------------------------------------------------------------------------------- */}
            <hr style={{ border: 'none', borderTop: '2px dashed rgb(249 203 203)',marginTop:'10px',marginBottom:'10px' }} />

            <Form.Item name="switch2" label="答题次数限制" valuePropName="checked">
              <Switch onChange={(e) => { styt2(e) }} />
            </Form.Item>

            {tyt2 == true &&
              <div>
                <Form.Item labelCol={formItemLayouts} label="答题次数" name="switch2a" rules={[{ required: true, message: '答题次数不能为空' }]}>
                  <Input type="number" placeholder="请输入答题次数" style={{ width: '40%' }} />
                </Form.Item>


                <Form.Item labelCol={formItemLayouts} name="count_day" label="当天" >
                  <Checkbox.Group style={{ width: '100%' }}>
                    <Row>
                      <Col span={8}>
                        <Checkbox
                          value="A"
                          style={{
                            lineHeight: '32px',
                          }}
                        >
                          （勾选后上面答题次数则为每天的答题次数）
                        </Checkbox>
                      </Col>

                    </Row>
                  </Checkbox.Group>
                </Form.Item>


                <Col span={20} offset={1} style={{ color: 'red', marginTop: '10px' }}>注意：如果不勾选（当天）上面设置的答题次数 则为用户在活动期间的总答题次数，勾选 则为用户在活动期间每天的答题次数</Col>
              </div>
            }

            {/* --------------3--------------------------------------------------------------------------------------- */}
            {/* <hr style={{border:'none',borderTop:'1px dashed rgb(236 3 3)',}}/>
            <Form.Item name="switch3" label="答案随机配置" valuePropName="checked">
              <Switch onChange={(e) => { styt3(e) }} />
            </Form.Item> */}

            {/* ---------------4-------------------------------------------------------------------------------------- */}
            <hr style={{ border: 'none', borderTop: '2px dashed rgb(249 203 203)',marginTop:'10px',marginBottom:'10px' }} />
            <Form.Item name="switch4" label="显示答案解析" valuePropName="checked">
              <Switch onChange={(e) => { styt4(e) }} />
            </Form.Item>

            {/* --------------5--------------------------------------------------------------------------------------- */}
            <hr style={{ border: 'none', borderTop: '2px dashed rgb(249 203 203)',marginTop:'10px',marginBottom:'10px' }} />

            <Form.Item name="switch5" label="答题时间限制" valuePropName="checked">
              <Switch onChange={(e) => { styt5(e) }} />
            </Form.Item>

            {tyt5 == true &&
              <div>
                <Form.Item labelCol={formItemLayouts} name="time_type" label="类型" rules={[{ required: true, message: '类型不能为空' }]}>
                  <Radio.Group onChange={(e) => { stimesuiji(e.target.value); console.log(e.target.value) }} >
                    <Radio value="mail">总时间限制</Radio>
                    <Radio value="offline">单题时间限制</Radio>
                    {/* <Radio value="maof"> 邮寄+线下 </Radio> */}
                  </Radio.Group>
                </Form.Item>
                <Form.Item labelCol={formItemLayouts} label="答题时间(秒)" name="switch5a" rules={[{ required: true, message: '答题时间不能为空' }]}>
                  <Input type="number" placeholder="请输入答题时间(单位秒)" style={{ width: '40%' }} />
                </Form.Item>

              </div>
            }

            {/* ---------------6-------------------------------------------------------------------------------------- */}
            <hr style={{ border: 'none', borderTop: '2px dashed rgb(249 203 203)',marginTop:'10px',marginBottom:'10px' }} />

            <Form.Item name="switch6" label="跳转链接" valuePropName="checked">
              <Switch onChange={(e) => { styt6(e) }} />
            </Form.Item>

            {tyt6 == true &&
              <div>
                <Form.Item labelCol={formItemLayouts} label="跳转链接" name="switch6a" rules={[{ required: true, message: '跳转链接不能为空' }]}>
                  <Input placeholder="请输入跳转链接" style={{ width: '40%' }} />
                </Form.Item>
                <Form.Item labelCol={formItemLayouts} label="分值配置" name="switch6b" rules={[{ required: true, message: '分值不能为空' }]}>
                  <Input type="number" placeholder="请输入大于等于多少分值才可跳转" style={{ width: '40%' }} />
                </Form.Item>
                <Form.Item labelCol={formItemLayouts} label="按钮文案" name="switch6c" rules={[{ required: true, message: '按钮文案不能为空' }]}>
                  <Input placeholder="请输入按钮文案" style={{ width: '40%' }} showCount maxLength={7} />
                </Form.Item>
              </div>
            }

            {/* ---------------7-------------------------------------------------------------------------------------- */}
            <hr style={{ border: 'none', borderTop: '2px dashed rgb(249 203 203)',marginTop:'10px',marginBottom:'10px' }} />

            <Form.Item name="switch7" label="答题后标语" valuePropName="checked">
              <Switch onChange={(e) => { styt7(e) }} />
            </Form.Item>

            {tyt7 == true &&
              <div>
                <Form.Item labelCol={formItemLayouts} label="及格分值" name="switch7a" rules={[{ required: true, message: '分值不能为空' }]}>
                  <Input type="number" placeholder="请输入及格分值" style={{ width: '40%' }} />
                </Form.Item>
                <Form.Item labelCol={formItemLayouts} label="及格文案" name="switch7b" rules={[{ required: true, message: '及格文案不能为空' }]}>
                  <Input placeholder="请输入及格文案" style={{ width: '40%' }} showCount maxLength={20} />
                </Form.Item>

                <Form.Item labelCol={formItemLayouts} label="不及格文案" name="switch7c" rules={[{ required: true, message: '不及格文案不能为空' }]}>
                  <Input placeholder="请输入不及格文案" style={{ width: '40%' }} showCount maxLength={20} />
                </Form.Item>
              </div>
            }
            {/* ---------------8-------------------------------------------------------------------------------------- */}
            <hr style={{ border: 'none', borderTop: '2px dashed rgb(249 203 203)',marginTop:'10px',marginBottom:'10px' }} />

            <Form.Item name="switch9" label="排行榜" valuePropName="checked">
              <Switch onChange={(e) => { styt9(e) }} />
            </Form.Item>

            {tyt9 == true &&
              <div>
                <Form.Item labelCol={formItemLayouts} name="switch9a" label="排行榜类型" rules={[{ required: true, message: '请选择排行榜展示类型（最少1个）' }]}>
                  <Checkbox.Group style={{ width: '35%' }}>
                    <Row>
                      <Col span={6}>
                        <Checkbox
                          value="A"
                          style={{
                            lineHeight: '32px',
                          }}
                        >
                          日排行
                        </Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox
                          value="B"
                          style={{
                            lineHeight: '32px',
                          }}
                        >
                          总排行
                        </Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox
                          value="C"
                          style={{
                            lineHeight: '32px',
                          }}
                        >
                          部门排行
                        </Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox
                          value="D"
                          style={{
                            lineHeight: '32px',
                          }}
                        >
                          总分排行
                        </Checkbox>
                      </Col>

                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </div>
            }
            {/* -------------2---------------------------------------------------------------------------------------- */}
            <hr style={{ border: 'none', borderTop: '2px dashed rgb(249 203 203)',marginTop:'10px',marginBottom:'10px' }} />
            <Form.Item name="score_details" label="分数累计" valuePropName="checked">


              <Switch onChange={(e) => { styt14(e) }} />
            </Form.Item>
            <Col span={20} offset={1} style={{ color: 'red', marginTop: '10px' }}>注意：开启后将在首页展示  分数详情  按钮</Col>
            {/* ---------------9-------------------------------------------------------------------------------------- */}

            <hr style={{ border: 'none', borderTop: '2px dashed rgb(249 203 203)',marginTop:'10px',marginBottom:'10px' }} />

            <Form.Item name="switch8" label="姓名输入" valuePropName="checked">
              <Switch onChange={(e) => { styt8(e) }} />
            </Form.Item>

            {tyt8 == true &&
              <div>
                <Form.Item labelCol={formItemLayouts} label="姓名提示文案" name="switch8a" rules={[{ required: true, message: '姓名提示文案不能为空' }]}>
                  <Input placeholder="姓名填写提示文案" style={{ width: '40%' }} showCount maxLength={12} />
                </Form.Item>
                <Form.Item labelCol={formItemLayouts} name="login_status" label="多端登录" >
                  <Checkbox.Group style={{ width: '100%' }} onChange={(e) => { }}>
                    <Row>
                      <Col span={8}>
                        <Checkbox
                          value="A"
                          style={{
                            lineHeight: '32px',
                          }}
                        >
                          （勾选后可在pc端登录使用）
                        </Checkbox>
                      </Col>

                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </div>
            }
            {/* ---------------10-------------------------------------------------------------------------------------- */}
            <hr style={{ border: 'none', borderTop: '1px dashed rgb(184 184 184)',marginTop:'20px',marginBottom:'20px' }} />

            <Form.Item name="switch10" label="部门或公司配置" valuePropName="checked">


              <Switch onChange={(e) => { styt10(e) }} />
            </Form.Item>
            
            {tyt10 == true &&
              <>
                           
                <Form.Item labelCol={formItemLayouts} name="department_company" label="类型" rules={[{ required: true, message: '类型不能为空' }]}>
                  <Radio.Group onChange={(e) => {
                    scompDepart(e.target.value)
                    // srant(e.target.value) ;console.log(e.target.value)
                  }}>
                    <Radio value="1"> 部门 </Radio>
                    <Radio value="2"> 公司 </Radio>
                    {/* <Radio value="maof"> 邮寄+线下 </Radio> */}
                  </Radio.Group>
                </Form.Item>
  
                {compDepart == '1' &&
                  <>
           
           <Form.Item label="部门提示文案" labelCol={formItemLayouts} name="switch8b" rules={[{ required: true, message: '部门提示文案不能为空' }]}>
                    <Input placeholder="部门选择提示文案" style={{ width: '40%' }} showCount maxLength={12} />
                  </Form.Item>

                    <Form.Item labelCol={formItemLayouts} name="switch10a" label="手动填写" >
                      <Checkbox.Group style={{ width: '100%' }} onChange={(e) => { styt17(e) }}>
                        <Row>
                          <Col span={8}>
                            <Checkbox
                              value="A"
                              style={{
                                lineHeight: '32px',
                              }}
                            >
                              （勾选后用户可以手动填写部门）
                            </Checkbox>
                          </Col>

                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                    {tyt17.indexOf('A') != -1 &&
                      <>
                        <Form.Item labelCol={formItemLayouts2} label="其他提示文案" name="switch8c" rules={[{ required: true, message: '其他提示文案不能为空' }]}>
                          <Input placeholder="用户选择 其他 提示文案" style={{ width: '40%' }} showCount maxLength={12} />
                        </Form.Item>
                      </>
                    }
                    <Form.Item labelCol={formItemLayouts} name="department_search" label="搜索框" >
                      <Checkbox.Group style={{ width: '100%' }} onChange={(e) => { styt15(e) }}>
                        <Row>
                          <Col span={8}>
                            <Checkbox
                              value="A"
                              style={{
                                lineHeight: '32px',
                              }}
                            >
                              （勾选展示搜索框，方便快速选择）
                            </Checkbox>
                          </Col>

                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                    {tyt15.indexOf('A') != -1 &&
                      <>
                        <Form.Item labelCol={formItemLayouts2} label="搜索提示文案" name="department_search_copy" rules={[{ required: true, message: '提示文案不能为空' }]}>
                          <Input placeholder="请输入提示文案" style={{ width: '40%' }} showCount maxLength={12} />
                        </Form.Item>
                      </>
                    }


                    <div style={{ width: '70%',marginTop:'30px' }}>
                      <Form.List name="users"
                      //  rules={[{ required: true, message: `部门不能为空` }]}
                      >
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Space
                                key={key}
                                style={{
                                  display: 'flex',
                                  marginBottom: 5,
                                  width: '80%',

                                }}
                                align="baseline"
                                className='inps'
                              >

                                <Form.Item
                                labelCol={formItemLayoutsa}
                                  {...restField}
                                  style={{ width: '100%', marginLeft: '0px' }}
                                  // style={{ width: '0%' }}
                                  name={[name]}
                                  rules={[{ required: true, message: `部门${name + 1}不能为空` }]}
                                  label={`部门${name + 1}:`}

                                >
                                  <Input placeholder={`请输入部门${name + 1}`} style={{ width: '95%', marginLeft: '9px' }} />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                              </Space>
                            ))}

                            {/* <div style={{ width: '80%', position: 'relative', }}> */}
                              {/* <div style={{ width: "80%", position: "relative", }}> */}
                                <Form.Item wrapperCol={{ span:10, offset: 6 }} >
                                  <Button ty1e="dashed" onClick={() => add()} block icon={<PlusOutlined />} >
                                    添加部门
                                  </Button>

                                  <Form.ErrorList errors={errors} />
                                </Form.Item>
                              {/* </div>
                            </div> */}

                          </>
                        )}
                      </Form.List>

                    </div>
                    <Col span={20} offset={1} style={{ color: 'red', marginTop: '10px' }}>
                    <Upload maxCount={1} {...props} >
                      <Button icon={<UploadOutlined />} style={{ marginTop: '20px', }}>批量导入部门</Button>
                    </Upload></Col>
                  </>
                }

                {compDepart == '2' &&
                  <>
                    <Form.Item labelCol={formItemLayouts} label="公司提示文案" name="company_xz" rules={[{ required: true, message: '公司提示文案不能为空' }]}>
                      <Input placeholder="公司选择提示文案" style={{ width: '40%' }} showCount maxLength={12} />
                    </Form.Item>

                    <Form.Item labelCol={formItemLayouts} label="部门提示文案" name="company_department_tx" rules={[{ required: true, message: '部门提示文案不能为空' }]}>
                    <Input placeholder="请填写部门提示文案" style={{ width: '40%' }} showCount maxLength={50} />
                  </Form.Item>
                    <Form.Item labelCol={formItemLayouts} name="company_else" label="手动填写" >
                      <Checkbox.Group style={{ width: '100%' }} onChange={(e) => { styt_else(e) }}>
                        <Row>
                          <Col span={8}>
                            <Checkbox
                              value="A"
                              style={{
                                lineHeight: '32px',
                              }}
                            >
                              （勾选后用户可以手动填写公司）
                            </Checkbox>
                          </Col>

                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                    {tyt_else.indexOf('A') != -1 &&
                      <>
                        <Form.Item labelCol={formItemLayouts2} label="其他提示文案" name="company_tx" rules={[{ required: true, message: '其他提示文案不能为空' }]}>
                          <Input placeholder="用户选择 其他 提示文案" style={{ width: '40%' }} showCount maxLength={12} />
                        </Form.Item>
                      </>
                    }
                    <Form.Item labelCol={formItemLayouts} name="company_search" label="搜索框" >
                      <Checkbox.Group style={{ width: '100%' }} onChange={(e) => { styt_search(e) }}>
                        <Row>
                          <Col span={8}>
                            <Checkbox
                              value="A"
                              style={{
                                lineHeight: '32px',
                              }}
                            >
                              （勾选展示搜索框，方便快速选择）
                            </Checkbox>
                          </Col>

                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                    {tyt_search.indexOf('A') != -1 &&
                      <>
                        <Form.Item labelCol={formItemLayouts2} label="搜索提示文案" name="company_search_copy" rules={[{ required: true, message: '提示文案不能为空' }]}>
                          <Input placeholder="请输入提示文案" style={{ width: '40%' }} showCount maxLength={12} />
                        </Form.Item>
                      </>
                    }


                    <div style={{ width: '70%',marginTop:'30px' }}>
                      <Form.List name="company"
                      //  rules={[{ required: true, message: `部门不能为空` }]}
                      >
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Space
                                key={key}
                                style={{
                                  display: 'flex',
                                  marginBottom: 5,
                                  width: '80%',

                                }}
                                align="baseline"
                                className='inps'
                              >

                                <Form.Item
                                labelCol={formItemLayoutsa}
                                  {...restField}
                                  style={{ width: '100%', marginLeft: '0px' }}
                                  // style={{ width: '0%' }}
                                  name={[name]}
                                  rules={[{ required: true, message: `公司${name + 1}不能为空` }]}
                                  label={`公司${name + 1}:`}

                                >
                                  <Input placeholder={`请输入公司${name + 1}`} style={{ width: '95%', marginLeft: '9px' }} />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                              </Space>
                            ))}

                              <Form.Item wrapperCol={{ span:10, offset: 6 }} >
                                  <Button ty1e="dashed" onClick={() => add()} block icon={<PlusOutlined />} >
                                    添加公司
                                  </Button>

                                  <Form.ErrorList errors={errors} />
                                </Form.Item>

                          </>
                        )}
                      </Form.List>

                    </div>
                    <Col span={20} offset={1} style={{ color: 'red', marginTop: '10px' }}>
                    <Upload maxCount={1} {...propsa} >
                      <Button icon={<UploadOutlined />} style={{ marginTop: '20px', }}>批量导入公司</Button>
                    </Upload></Col>
                    
                  </>
                }
                 <Col span={20} offset={1} style={{ color: 'red', marginTop: '10px' }}>注意：部门或公司批量导入模板找不到了怎么办 ？<span style={{ fontWeight: "bold", fontSize: '20px', position: 'relative', top: '1px' }}>→</span>    <Button type="link" href='https://res.xiaoxxx.com/urlGather/20230602f4cd87c4a81c766f524936.xls' style={{ padding: '4px 5px', }} >点我下载</Button></Col>
                    <Col span={20} offset={1} style={{ color: 'red', marginTop: '10px' }}>注意：1.（ 部门配置或公司配置 ）需在开启姓名输入的时候才会生效</Col>
                    {/* <div style={{color:'red',marginTop:'10px'}}>注意：2.（ 部门配置 ）中的（添加部门）不能以阿拉伯数字开头。</div> */}
                    <Col span={20} offset={1} style={{ color: 'red', marginTop: '10px' }}>注意：2.（ 部门配置或公司配置 ）中的 （手动填写）和 （添加部门/添加公司） 不能同时不勾选和不添加，最少要编辑其中一个。</Col>
              </>
            }
            {/* {tyt10 == true &&
    
            }
            {tyt10 == false&&1==2 &&
     
            } */}
            {/* <Form.Item label="活动名称" name="usernamec" rules={[{ required: true, message: '问题不能为空' }]}>
            <Input placeholder="请输入活动名称" showCount maxLength={240} style={{ width: '40%' }} />
          </Form.Item> */}

            <hr style={{ border: 'none', borderTop: '2px dashed rgb(249 203 203)',marginTop:'10px',marginBottom:'10px' }} />


            {/* ---------------保存-------------------------------------------------------------------------------------- */}


            <Form.Item wrapperCol={{ ...formItemLayout.wrapperCol, offset: 3 }}>
              <Button type="primary" loading={load2} htmlType="submit">
                保存
              </Button>
            </Form.Item>
          </Form></ConfigProvider >
        </div>

      </Spin>
    </div>

  );
}

// export default App;