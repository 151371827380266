import React, { Component, useEffect, useState } from "react";
import {
  BrowserRouter,
  useNavigate,
  useLocation,
  Link,
  Routes,
  Route,
  Outlet,
} from "react-router-dom"; //引入路由模块
import zhCN from "antd/lib/locale/zh_CN";
// import './configuration.css'
import {
  Divider,
  Select,
  Table,
  Modal,
  Button,
  ConfigProvider,
  Popconfirm,
  message,
  Input,
  Space,
} from "antd";
// import Question from "./ces"
import axios from "axios";
import { getDrawLog } from "../../../../api/api.js"; //获取列表
import { warehouseDelete } from "../../../../api/api.js"; //删除接口
import { excelImport } from "../../../../api/api.js"; //文件导入
const { Option } = Select;
// import { Input, Space } from 'antd';
// import React from 'react';
const { Search } = Input;
export default function Na() {
  const navigate = useNavigate();
  let totoken = window.localStorage.getItem("token");
  // console.log(totoken)
  if (totoken == null || totoken == "") {
    // alert(1)
    navigate("/");
  }
  // 获取页面参数
  function hqcorid(key, Url) {
    var str = Url;
    str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
    // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
    var arr = str.split("&");
    // console.log(666666,arr)
    var obj = new Object();
    // 将每一个数组元素以=分隔并赋给obj对象
    for (var i = 0; i < arr.length; i++) {
      var tmp_arr = arr[i].split("=");
      obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
    }
    // console.log("1234564,", obj[key]);
    return obj[key];
  }
  const location = useLocation();

  let pageid = hqcorid("keid", location.search);
  let paname = hqcorid("name", location.search);
  // const location = useLocation()
  const columns = [
    {
      title: `序号`,
      align: "center",
      width: 70,
      render: (e, x, i) => (
        <div>
          {" "}
          {i + 1 != 10 && dqym - 1 == 0 && <div>{i + 1}</div>}
          {i + 1 != 10 && dqym - 1 != 0 && (
            <div>
              {dqym - 1}
              {i + 1}
            </div>
          )}
          {i + 1 == 10 && <div>{dqym}0</div>}
        </div>
      ),
      // dataIndex: 'name',
    },

    {
      title: "类型",
      dataIndex: "",
      width: 150,
      align: "center",

      filters: [
        {
          text: "已填写地址(邮寄)",
          value: "2",
        },
        {
          text: "已填写花名(线下)",
          value: "4",
        },
        {
          text: "未填写",
          value: "1",
        },
        // {
        //   text: "未抽奖",
        //   value: "3",
        // },
        {
          text: "未中奖",
          value: "5",
        },

        // {
        //   text: '已填写花名(线下)',
        //   value: '5',
        // },
      ],
      // filterDropdown:(e)=>{
      //   console.log(e)
      // },
      // onFilter: (value, record) =>{console.log(value,record)},

      // onFilter: (value, record) => {console.log(value)},

      // sorter: (a, b) => a.name.length - b.name.length,
      // sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      ellipsis: true,

      render: (e) => (
        <div>
          {e.status == 1 ? (
            <span style={{ color: "#FAAD14" }}>未填写</span>
          ) : e.status == 2 ? (
            <span style={{ color: "#52C41A" }}>已填写地址(邮寄)</span>
          ) : e.status == 3 ? (
            <span style={{ color: "#FF4D4F" }}>未抽奖</span>
          ) : e.status == 4 ? (
            <span style={{ color: "rgb(67 149 224)" }}>已填写花名(线下)</span>
          ) : e.status == 5 ? (
            <span style={{ color: "rgb(172 167 167)" }}>未中奖</span>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      title: "公司",
      align: "center",
      // width: 100,
      dataIndex: "",
      render: (a, e, b) => (
        <div
          style={{
            color:
              e.status == 1
                ? "#FAAD14"
                : e.status == 2
                ? "#52C41A"
                : e.status == 3
                ? "#FF4D4F"
                : e.status == 4
                ? "rgb(67 149 224)"
                : e.status == 5
                ? "rgb(172 167 167)"
                : "",
          }}
        >
          {e.company_id&&e.company_id!='' ? e.company_id : "无"}
        </div>
      ),
      // width: 150,
    },
    {
      title: "部门",
      align: "center",
      // width: 100,
      dataIndex: "",
      render: (a, e, b) => (
        <div
          style={{
            color:
              e.status == 1
                ? "#FAAD14"
                : e.status == 2
                ? "#52C41A"
                : e.status == 3
                ? "#FF4D4F"
                : e.status == 4
                ? "rgb(67 149 224)"
                : e.status == 5
                ? "rgb(172 167 167)"
                : "",
          }}
        >
          {e.department_id ? e.department_id : "无"}
        </div>
      ),
      // width: 150,
    },
    {
      title: "姓名",
      align: "center",

      dataIndex: "",
      // width: 150,
      render: (a, e, b) => (
        <div
          style={{
            color:
              e.status == 1
                ? "#FAAD14"
                : e.status == 2
                ? "#52C41A"
                : e.status == 3
                ? "#FF4D4F"
                : e.status == 4
                ? "rgb(67 149 224)"
                : e.status == 5
                ? "rgb(172 167 167)"
                : "",
          }}
        >
          {e.username ? e.username : ""}
        </div>
      ),
    },
    {
      title: "地址中的姓名 或 花名",
      align: "center",
      dataIndex: "",
      width: 180,
      render: (a, e, b) => (
        <div
          style={{
            color:
              e.status == 1
                ? "#FAAD14"
                : e.status == 2
                ? "#52C41A"
                : e.status == 3
                ? "#FF4D4F"
                : e.status == 4
                ? "rgb(67 149 224)"
                : e.status == 5
                ? "rgb(172 167 167)"
                : "",
          }}
        >
          {e.name ? e.name : "无"}
        </div>
      ),
    },
    {
      title: "手机号",
      align: "center",

      dataIndex: "",
      // width: 150,
      render: (a, e, b) => (
        <div
          style={{
            color:
              e.status == 1
                ? "#FAAD14"
                : e.status == 2
                ? "#52C41A"
                : e.status == 3
                ? "#FF4D4F"
                : e.status == 4
                ? "rgb(67 149 224)"
                : e.status == 5
                ? "rgb(172 167 167)"
                : "",
          }}
        >
          {e.tel ? e.tel : "无"}
        </div>
      ),
    },
    {
      title: "地址",
      align: "center",

      dataIndex: "",
      render: (a, e, b) => (
        <div
          style={{
            color:
              e.status == 1
                ? "#FAAD14"
                : e.status == 2
                ? "#52C41A"
                : e.status == 3
                ? "#FF4D4F"
                : e.status == 4
                ? "rgb(67 149 224)"
                : e.status == 5
                ? "rgb(172 167 167)"
                : "",
          }}
        >
          {e.address ? e.address : "无"}
        </div>
      ),
    },
    {
      title: "奖品名称",
      align: "center",

      dataIndex: "",
      render: (a, e, b) => (
        <div
          style={{
            color:
              e.status == 1
                ? "#FAAD14"
                : e.status == 2
                ? "#52C41A"
                : e.status == 3
                ? "#FF4D4F"
                : e.status == 4
                ? "rgb(67 149 224)"
                : e.status == 5
                ? "rgb(172 167 167)"
                : "",
          }}
        >
          {e.draw_name ? e.draw_name : "无"}
        </div>
      ),
    },
    {
      title: "奖品图片",
      align: "center",

      dataIndex: "",
      render: (a, e, b) => (
        <div
          style={{
            color:
              e.status == 1
                ? "#FAAD14"
                : e.status == 2
                ? "#52C41A"
                : e.status == 3
                ? "#FF4D4F"
                : e.status == 4
                ? "rgb(67 149 224)"
                : e.status == 5
                ? "rgb(172 167 167)"
                : "",
          }}
        >
          {e.draw_img ? (
            <img className="imgs" style={{ height: "50px" }} src={e.draw_img} />
          ) : (
            "无"
          )}
        </div>
      ),
    },
    {
      title: "抽奖时间",
      align: "center",
      // width: 100,
      dataIndex: "",
      render: (a, e, b) => (
        <div
          style={{
            color:
              e.status == 1
                ? "#FAAD14"
                : e.status == 2
                ? "#52C41A"
                : e.status == 3
                ? "#FF4D4F"
                : e.status == 4
                ? "rgb(67 149 224)"
                : e.status == 5
                ? "rgb(172 167 167)"
                : "",
          }}
        >
          {e.draw_date ? e.draw_date : "无"}
        </div>
      ),
      // width: 150,
    },
  ]; //定义表格的title

  const success = (e) => {
    message.success(e);
  };
  const error = (a) => {
    message.error(a);
  };
  let [gao, sgao] = useState(500);
  window.onresize = function () {
    let boss = document.getElementsByClassName("body")[0].offsetHeight - 158;
    sgao(boss);
  }; //设置表格的高度

  // 刷新页面
  function refresh() {
    hqlb(dqym);
    usejz(true);
    // ssel([])
  }

  //使用生命周期的钩子
  // console.log(useEffect)
  useEffect(() => {
    // console.log("挂载完成,更新完成")
    window.sessionStorage.clear(); //清除缓存
    setTimeout(() => {
      let boss = document.getElementsByClassName("body")[0].offsetHeight - 158;
      sgao(boss);
    }, 1);
    hqlb(1);

    // console.log(location)
    return function () {
      // console.log("即将卸载")
      window.sessionStorage.clear(); //清除缓存
    };
  }, []);

  let [sj, usesj] = useState([]); //所有数据
  let [jz, usejz] = useState(true); //加载状态
  //  let [ys,uys]=useState([1]) //页数是否存储过

  // let [tc,stc]=useState(false) //  题目预览弹窗显示或隐藏
  // let [tcnr,stcnr]=useState([]) //  题目预览弹窗内容

  let [math, smath] = useState({}); //页数及数量

  function hqlb(i) {
    getDrawLog(
      `page=${i}&pageSize=10&status=${
        window.sessionStorage.getItem("leix") == null ||
        window.sessionStorage.getItem("leix") == ""
          ? 0
          : window.sessionStorage.getItem("leix")
      }&activity_id=${pageid}&keywords=${
        window.sessionStorage.getItem("word") == null
          ? ""
          : window.sessionStorage.getItem("word")
      }`
    ).then((res) => {
      console.log(res);
      usesj(res.data.lists);
      smath(res.data);
      usejz(false);
      if (res.data.lists.length == 0 && i != 1) {
        hqlb(1);
      }
    });
  }

  let [dqym, sdqym] = useState(1); //当前页面的索引

  // 筛选或分页的时候触发
  function onChanges(e, i, s, c) {
    if (c.action == "paginate") {
      sdqym(e.current);

      hqlb(e.current);
    } else {
      if (i[1] == null) {
        console.log(i);
        window.sessionStorage.setItem("leix", 0);
      } else {
        window.sessionStorage.setItem("leix", i[1].toString());
      }
      sdqym(1);
      hqlb(1);
    }
  }

  // 排序
  // function handleChange(value) {
  //   // console.log(leix)
  //   // console.log(`selected ${value}`);
  //   if(value!=undefined){
  //     window.sessionStorage.setItem("zhut", value);
  //   }else{
  //     window.sessionStorage.setItem("zhut", "");

  //   }

  //       sdqym(1)
  //       hqlb(1)
  //       ssel([])
  //       usejz(true);

  // }
  // // 点击删除按钮
  // function start(){
  //     // console.log(sel)
  //     // console.log(sel.toString())
  //   // return
  //   warehouseDelete(`id=${sel.toString()}`).then((res)=>{
  //         // console.log(res)
  //         if(res.code==0){
  //           refresh()
  //           success("批量删除成功")

  //         }
  //       }
  //       )

  // sscjz(true)
  //   setTimeout(() => {
  //     sscjz(false)

  //   }, 1000);
  // }

  const onSearch = (value) => {
    console.log(value);
    window.sessionStorage.setItem("word", value);
    usejz(true);
    sdqym(1);
    hqlb(1);
  };
  // const rowSelection = {
  //   selectedRowKeys:sel,
  //   onChange:onSelectChange,

  // };
  // const hasSelected = sel.length > 0;
  return (
    <div className="body">
      {/* 弹窗 */}
      {/* <Modal
          title={tcnr.title}
          style={{ top: '10%' }}
          visible={tc}
          closable={false}
          footer={[<div key={1} style={{padding:0}}>
            <Button type="primary" style={{  }} onClick={() => stc(false)}>
            关闭
          </Button>  
          </div>]}
        >
    

                {tcnr.option_a!=null && <div  style={{marginBottom:"6px"}}>A:{tcnr.option_a}</div>} 
                {tcnr.option_b!=null && <div  style={{marginBottom:"6px"}}>B:{tcnr.option_b}</div>}       
                {tcnr.option_c!=null && <div  style={{marginBottom:"6px"}}>C:{tcnr.option_c}</div>}       
                {tcnr.option_d!=null && <div  style={{marginBottom:"6px"}}>D:{tcnr.option_d}</div>}       
                {tcnr.option_e!=null && <div  style={{marginBottom:"6px"}}>E:{tcnr.option_e}</div>}       
  

        <div style={{marginTop:"18px",fontWeight:"bold"}}>正确答案：{tcnr.true}</div>
        <div style={{marginTop:"18px"}}><span style={{fontWeight:"bold"}}>解析：</span>{tcnr.analysis}</div>

        </Modal> */}
      {/* 顶部的按钮 */}
      <div style={{ marginBottom: 5, marginTop: 5, position: "relative" }}>
        <Button
          type="primary"
          style={{ marginLeft: 0 }}
          onClick={() => {
            const linkNode = document.createElement("a");
            linkNode.download = "fileName"; //a标签的download属性规定下载文件的名称
            linkNode.style.display = "none";
            linkNode.href = `${axios.defaults.baseURL}/exportDraw?activity_id=${pageid}`;
            document.body.appendChild(linkNode);
            linkNode.click(); //模拟在按钮上的一次鼠标单击
            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          }}
        >
          导出所有
        </Button>
        <Button
          type="primary"
          style={{ marginLeft: 5 }}
          onClick={() => {
            const linkNode = document.createElement("a");
            linkNode.download = "fileName"; //a标签的download属性规定下载文件的名称
            linkNode.style.display = "none";
            linkNode.href = `${axios.defaults.baseURL}/exportDraw?activity_id=${pageid}&status=2`;
            document.body.appendChild(linkNode);
            linkNode.click(); //模拟在按钮上的一次鼠标单击
            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          }}
        >
          导出邮寄中奖
        </Button>
        <Button
          type="primary"
          style={{ marginLeft: 5 }}
          onClick={() => {
            const linkNode = document.createElement("a");
            linkNode.download = "fileName"; //a标签的download属性规定下载文件的名称
            linkNode.style.display = "none";
            linkNode.href = `${axios.defaults.baseURL}/exportDraw?activity_id=${pageid}&status=4`;
            document.body.appendChild(linkNode);
            linkNode.click(); //模拟在按钮上的一次鼠标单击
            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          }}
        >
          导出线下中奖
        </Button>
        <Button
          type="primary"
          style={{ marginLeft: 5 }}
          onClick={() => {
            const linkNode = document.createElement("a");
            linkNode.download = "fileName"; //a标签的download属性规定下载文件的名称
            linkNode.style.display = "none";
            linkNode.href = `${axios.defaults.baseURL}/exportDraw?activity_id=${pageid}&status=1`;
            document.body.appendChild(linkNode);
            linkNode.click(); //模拟在按钮上的一次鼠标单击
            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          }}
        >
          导出中奖后未填写
        </Button>
        {/* <Button
          type="primary"
          style={{ marginLeft: 5 }}
          onClick={() => {
            const linkNode = document.createElement("a");
            linkNode.download = "fileName"; //a标签的download属性规定下载文件的名称
            linkNode.style.display = "none";
            linkNode.href = `${axios.defaults.baseURL}/exportDraw?activity_id=${pageid}&status=3`;
            document.body.appendChild(linkNode);
            linkNode.click(); //模拟在按钮上的一次鼠标单击
            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          }}
        >
          导出有资格未抽奖
        </Button> */}
        <Button
          type="primary"
          style={{ marginLeft: 5 }}
          onClick={() => {
            const linkNode = document.createElement("a");
            linkNode.download = "fileName"; //a标签的download属性规定下载文件的名称
            linkNode.style.display = "none";
            linkNode.href = `${axios.defaults.baseURL}/exportDraw?activity_id=${pageid}&status=5`;
            document.body.appendChild(linkNode);
            linkNode.click(); //模拟在按钮上的一次鼠标单击
            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          }}
        >
          导出未抽中
        </Button>
        <Space
          direction="vertical"
          style={{ position: "absolute", right: "0" }}
        >
          <Input.Search
            allowClear
            placeholder="请输入姓名或手机号"
            style={{
              width: "100%",
            }}
            // enterButton="Search"
            enterButton
            // size="large"

            onSearch={onSearch}
            // defaultValue="0571"
          />
        </Space>
        {/* <h2 style={{position:"absolute",right:0,top:0,fontWeight:"bold",color:""}}>题库列表</h2> */}
      </div>
      {/* 表格数据 */}
      <ConfigProvider locale={zhCN}>
        <Table
          columns={columns}
          dataSource={sj}
          scroll={{ y: gao }}
          className="fromt"
          loading={jz}
          pagination={{
            pageSize: 10,
            total: math.allCounts,
            showTotal: (total) => `共 ${math.allCounts} 条数据`,
            current: dqym,
          }}
          onChange={(e, i, s, c) => {
            usejz(true);
            onChanges(e, i, s, c);
          }}
          // rowSelection={rowSelection}

          rowKey="id"
        />
      </ConfigProvider>
    </div>
  );
}

// ReactDOM.render(

//   mountNode,
// );
