import React, { Component } from 'react'

import Wd from "./pages/background/background";
import Home from "./pages/home";

import { BrowserRouter, HashRouter, Routes, Route, Outlet } from "react-router-dom" //引入路由模块

// 引入 axios
import axios from 'axios';

// 配置请求基础路径
// axios.defaults.baseURL = 'https://h5api.xiaoxxx.cn/h5/ANT_II/Admin';
// axios.defaults.baseURL = 'http://test.api.antrt.xiaoxxx.cn/h5/ANT_II/Admin';
axios.defaults.baseURL = 'https://api.antrt.xiaoxxx.cn/h5/ANT_II/Admin';


export default class App extends Component {
  render() {
    return (
      <div>

        <HashRouter>
          <Routes>
            <Route path='/background/*' element={<Wd />} />
            <Route path='/' element={<Home />} />
          </Routes>
        </HashRouter>
        <Outlet />
      </div>
    )
  }
}
