// import { } from 'antd';
import React, { Component, useEffect, useState } from 'react'
import zhCN from 'antd/lib/locale/zh_CN';
import moment from "moment";
import "moment/locale/zh-cn";
// moment.locale("zh-cn");
import { Menu, Switch, Form,Spin, DatePicker, Input, Button, ConfigProvider, message, Space, Radio, Breadcrumb, Checkbox, Row, Col } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
// import "./details.css"
import { getActivityInfo } from "../../../../api/api.js"; //获取活动详情
import { activityBasicUpdate } from "../../../../api/api.js"; //保存基本配置
import { ActivityUpdate } from "../../../../api/api.js"; //保存基本配置
import { BrowserRouter, Link, Routes, Route,Outlet } from "react-router-dom" //引入路由模块
// import Na from "./configuration"


// 引入编辑器组件
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'




// import {  } from '@ant-design/icons';
import { useNavigate, useLocation } from "react-router-dom" //引入路由模块
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const formItemLayout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 20,
  },
};
export default function Details() {
  const navigate = useNavigate();
  const location = useLocation()
  let totoken = window.localStorage.getItem("token");
  // console.log(totoken)
  if (totoken == null || totoken == "") {
    // alert(1)
    navigate("/")
  }
  const error = (a) => {
    message.error(a);
  };
  const success = (e) => {
    message.success(e);
  };

  let [load1, sload1] = useState(false)//基本设置按钮加载状态
  let [load, sload] = useState(false)//基本设置按钮加载状态
  



  let [dataAnalysis,sdataAnalysis]=useState({})
    // 获取页面参数
    function hqcorid(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      // console.log(666666,arr)
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      // console.log("1234564,", obj[key]);
      return obj[key];
    }
    let pageid=hqcorid("keid",location.search)
let paname=hqcorid("name",location.search)
//使用生命周期的钩子

useEffect(()=>{
  // console.log("挂载完成,更新完成")
  acquire()
},[])
// 获取活动详情
function acquire(){
  if(pageid){
    sload(true)
    // id=(hqcorid("id",location.search)).replace(/@@/g,"%")
    
    getActivityInfo(`id=${pageid}`).then((res)=>{
      // console.log(res)
      if(res.code==0){
      console.log(res)
      sdataAnalysis(res.data.info)
      basicSetting(res.data.info)
      }else{
        error('未知错误获取详情失败，刷新页面重试')
        return
      }
    })
  }else{
    error('无法获取活动id，请退出重试')
  }
  }
const [form1] = Form.useForm();

  // 基本设置赋值
function basicSetting(a){
  form1.setFieldsValue({      
      title:a.title,
      editorState:BraftEditor.createEditorState(a.explain),
      s_time:(a.start_time&&a.end_time)?[moment(a.start_time ), moment(a.end_time)]:"",
      // s_time:[{_d:'Thu Oct 13 2022 16:55:00 GMT+0800 (中国标准时间)'},{_d:'Thu Oct 13 2022 22:55:00 GMT+0800 (中国标准时间)'}]
  })
  sload(false)
  }
  // 基本设置题交按钮
  const onFinish1 = values => {

    sload1(true)
    // console.log(formatDate(values.s_time[0]._d))
    console.log(values)
    let data={
      id:pageid,
      title:values.title,
      explain:values.editorState.toHTML(),
      start_time:values.s_time?formatDate(values.s_time[0]._d):'',
      end_time:values.s_time?formatDate(values.s_time[1]._d):'',
    }
    activityBasicUpdate(data).then((res)=>{
      // console.log(res)
      if(res.code==0){
      console.log(res)
      // sdataAnalysis(res.data.info)

      success("保存成功")
      acquire()
    sload1(false)
      }else{
        error('未知错误获取详情失败，刷新页面重试')
        return
      }
    })
  }
  // 时间格式转换
 function formatDate (d) {
    var date = new Date(d);
    var YY = date.getFullYear() + '-';
    var MM =
      (date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1) + '-';
    var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var hh =
      (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var mm =
      (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
      ':';
    var ss =
      date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return YY + MM + DD + ' ' + hh + mm + ss;
  }
 

  let [editorState, seditorState] = useState(null)//基本设置按钮加载状态



  return (
    
    <div className='bodys' style={{height:"100%"}}>
      {/* 基本设置 */}
      <Spin spinning={load} >
        <div style={{ position: "relative", top: "10px" }}>
          <ConfigProvider locale={zhCN}>
          <Form form={form1} labelAlign={'right'} name="from1"   {...formItemLayout} onValuesChange={(a, b) => {}} onFinish={onFinish1} autoComplete="off">
            <Form.Item label="活动名称" name="title" rules={[{ required: true, message: '问题不能为空' }]}>
              <Input placeholder="请输入活动名称"  style={{ width: '40%' }} />
            </Form.Item>



            <Form.Item label="活动时间" name="s_time" >
              <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
            </Form.Item>
            <Form.Item label="活动规则" name="editorState" >

              {/* <TextArea rows={4} placeholder="请输入活动规则" value={""} onChange={(e) => { }} style={{ width: '40%' }} /> */}
              <BraftEditor
 
 value={editorState}
 placeholder="请输入活动规则"
 onChange={(a)=>{seditorState(a)}}
 onSave={()=>{console.log(1,editorState.toHTML())}}
 excludeControls={['media',"list-ul","list-ol"]}
 style={{ width: '60%', height: 400,marginLeft: 0,marginRight: 0, overflow: "hidden" ,boxShadow: 'inset 0 1px 3px rgba(0,0,0,.1),inset 0px -1px 3px rgba(0,0,0,.1)'}}
contentStyle={{boxShadow: 'inset 0 1px 3px rgba(0,0,0,.1)'}}
/>

            </Form.Item>
            <Form.Item wrapperCol={{ ...formItemLayout.wrapperCol, offset: 2 }}>
              <Button type="primary" loading={load1} htmlType="submit">
                保存
              </Button>
            </Form.Item>
          </Form></ConfigProvider >
        </div>
  </Spin>
  {/* <div style={{width:"500px"}}>

  </div> */}

    </div>
   
  );
}

