// import { } from 'antd';
import React, { Component, useEffect, useState } from 'react'
import zhCN from 'antd/lib/locale/zh_CN';
import moment from "moment";
import "moment/locale/zh-cn";
// moment.locale("zh-cn");
import { Menu, Switch, Form,Spin, DatePicker, Input, Button, ConfigProvider, message, Space, Radio, Breadcrumb, Checkbox, Row, Col } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
// import "./details.css"
import { getActivityInfo } from "../../../../api/api.js"; //获取活动详情
import { updateDeploy } from "../../../../api/api.js"; //保存基本配置
import { ActivityUpdate } from "../../../../api/api.js"; //保存基本配置
import { BrowserRouter, Link, Routes, Route,Outlet } from "react-router-dom" //引入路由模块
// import Na from "./configuration"

// import {  } from '@ant-design/icons';
import { useNavigate, useLocation } from "react-router-dom" //引入路由模块
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const formItemLayout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 21,
  },
};
const formItemLayouts = {

  span: 4,


};
const formItemLayoutsa = {

  span: 8,


};
export default function Details() {
  const navigate = useNavigate();
  const location = useLocation()
  let totoken = window.localStorage.getItem("token");
  // console.log(totoken)
  if (totoken == null || totoken == "") {
    // alert(1)
    navigate("/")
  }
  const error = (a) => {
    message.error(a);
  };
  const success = (e) => {
    message.success(e);
  };

  let [load1, sload1] = useState(false)//基本设置按钮加载状态
  let [load, sload] = useState(false)//基本设置按钮加载状态
  let [tyt, styt] = useState(true)//答题次数限制的开关
  let [rant, srant] = useState('')//答题次数限制的开关
  let [lottery, slottery] = useState('')//答题次数限制的开关

  


  let [dataAnalysis,sdataAnalysis]=useState({})
    // 获取页面参数
    function hqcorid(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      // console.log(666666,arr)
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      // console.log("1234564,", obj[key]);
      return obj[key];
    }
    let pageid=hqcorid("keid",location.search)
let paname=hqcorid("name",location.search)
//使用生命周期的钩子

useEffect(()=>{
  // console.log("挂载完成,更新完成")
  acquire()
},[])
// 获取活动详情
function acquire(){
  if(pageid){
    sload(true)
    // id=(hqcorid("id",location.search)).replace(/@@/g,"%")
    
    getActivityInfo(`id=${pageid}`).then((res)=>{
      // console.log(res)
      if(res.code==0){
      console.log(res)
      sdataAnalysis(res.data.info)
      basicSetting(res.data.info)
      }else{
        error('未知错误获取详情失败，刷新页面重试')
        return
      }
    })
  }else{
    error('无法获取活动id，请退出重试')
  }
  }
const [form1] = Form.useForm();

  // 基本设置赋值
function basicSetting(a){
  // online_prompt:values.grant4?values.grant4:null,
  // offline_prompt:values.grant1?values.grant1:null,
  // offline_mark:values.grant2?values.grant2:null,
  // offline_mark_prompt:values.grant3?values.grant3:null,
  form1.setFieldsValue({     
    issue:a.drawStatus==2?true:false,
    aper:a.draw_type==1?"apple":a.draw_type==2?"pear":"",
    winning_record:a.winning_record==2?(["A"]):[],
    score:a.draw_score,
    grant_type:a.grant_type==1?"mail":a.grant_type==2?"offline":a.grant_type==3?"maof":'',
    grant4:a.online_prompt,
    grant1:a.offline_prompt,
    grant2:a.offline_mark,
    grant3:a.offline_mark_prompt,
    lottery_method:a.lottery_method==1?"1":a.lottery_method==2?"2":'',
    draw_score:a.draw_score,
    draw_stage_score:a.draw_stage_score?a.draw_stage_score:null,
    

      // title:a.title,
      // explain:a.explain,
      // s_time:(a.start_time&&a.end_time)?[moment(a.start_time ), moment(a.end_time)]:"",
      // s_time:[{_d:'Thu Oct 13 2022 16:55:00 GMT+0800 (中国标准时间)'},{_d:'Thu Oct 13 2022 22:55:00 GMT+0800 (中国标准时间)'}]
  })
  styt(a.drawStatus==2?true:false)
  srant(a.grant_type==1?"mail":a.grant_type==2?"offline":a.grant_type==3?"maof":'')
  slottery(a.lottery_method==1?"1":a.lottery_method==2?"2":'')
  sload(false)
  }
  // 基本设置题交按钮
  const onFinish1 = values => {
    console.log(values)
    // return
    sload1(true)
    // console.log(formatDate(values.s_time[0]._d))
    // console.log(values)
    let data={
      activity_id:pageid,
      drawStatus:values.issue?2 : 1,
      winning_record:values.winning_record?(values.winning_record.indexOf('A')!=-1?2:1):1,//是否展示中奖记录 1不开启 2开启
      draw_type:values.aper?(values.aper=="apple"?1:2):null,
      grant_type:values.grant_type?(values.grant_type=="mail"?1:values.grant_type=="offline"?2:3):null,
      online_prompt:values.grant4?values.grant4:null,
      offline_prompt:values.grant1?values.grant1:null,
      offline_mark:values.grant2?values.grant2:null,
      offline_mark_prompt:values.grant3?values.grant3:null,
      lottery_method:values.lottery_method?(values.lottery_method=="1"?1:values.lottery_method=="2"?2:null):null,
      // online_prompt:values.grant4?values.grant4:null,
      // draw_score:values.score!="0"?(values.score?values.score:null):"0",
      draw_score:values.score!="0"?(values.score?values.score:null):"0",
      draw_stage_score:values.draw_stage_score?JSON.stringify(values.draw_stage_score):null,
      // JSON.stringify(bbmm)

    }
    updateDeploy(data).then((res)=>{
      // console.log(res)
      if(res.code==0){
      console.log(res)
      // sdataAnalysis(res.data.info)

      success("保存成功")
      acquire()
    sload1(false)
      }else{
        sload1(false)
        error(res.message)
        return
      }
    })
  }
  // 时间格式转换
 function formatDate (d) {
    var date = new Date(d);
    var YY = date.getFullYear() + '-';
    var MM =
      (date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1) + '-';
    var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var hh =
      (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var mm =
      (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
      ':';
    var ss =
      date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return YY + MM + DD + ' ' + hh + mm + ss;
  }
 

  return (
    
    <div className='bodys' style={{height:"100%"}}>
      {/* 基本设置 */}
      <Spin spinning={load} >
        <div style={{ position: "relative", top: "10px" }}>
          <ConfigProvider locale={zhCN}>
          <Form form={form1} labelAlign={'right'} name="from1"   {...formItemLayout} onValuesChange={(a, b) => {}} onFinish={onFinish1} autoComplete="off">
          <Form.Item name="issue" label="开启抽奖" valuePropName="checked" >
              <Switch onChange={(e) => {styt(e) }} />
            </Form.Item>
            {tyt &&
              <>
        

                  <Form.Item labelCol={formItemLayouts} name="winning_record" label="中奖记录" >
                    <Checkbox.Group style={{ width: '100%' }}>
                      <Row>
                        <Col span={8}>
                          <Checkbox
                            value="A"
                            style={{
                              lineHeight: '32px',
                            }}
                          >
                            （勾选后将在首页展示中奖记录）
                          </Checkbox>
                        </Col>
  
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
        

            <Form.Item labelCol={formItemLayouts} name="aper" label="抽奖方式" rules={[{ required: true, message: '抽奖方式不能为空' }]}>
          <Radio.Group>
            <Radio value="apple"> 直接抽奖 </Radio>
            <Radio value="pear"> 幸运转盘 </Radio>
          </Radio.Group>
        </Form.Item>  


        <Form.Item labelCol={formItemLayouts} name="grant_type" label="兑换方式" rules={[{ required: true, message: '兑换方式不能为空' }]}>
          <Radio.Group onChange={(e) => { srant(e.target.value) ;console.log(e.target.value)}}>
            <Radio value="mail"> 邮寄 </Radio>
            <Radio value="offline"> 线下 </Radio>
            <Radio value="maof"> 邮寄+线下 </Radio>
          </Radio.Group>
        </Form.Item>
        {rant!='offline' && <>

          <Form.Item labelCol={formItemLayouts} label="邮寄规则" name="grant4" rules={[{ required: true, message: '邮寄规则不能为空' }]}>
                    <Input placeholder="请输入邮寄规则" style={{ width: '40%' }} />
          </Form.Item>
          </>}
          {rant!="mail"  && <>
        <Form.Item labelCol={formItemLayouts} label="线下规则" name="grant1" rules={[{ required: true, message: '线下规则不能为空' }]}>
                  <Input placeholder="请输入线下规则" style={{ width: '40%' }} />
        </Form.Item>
        <Form.Item labelCol={formItemLayouts} label="线下登记标题" name="grant2" rules={[{ required: true, message: '线下登记标题不能为空' }]}>
                  <Input placeholder="请输入线下登记标题" style={{ width: '40%' }} />
        </Form.Item>
        <Form.Item labelCol={formItemLayouts} label="线下登记提示" name="grant3" rules={[{ required: true, message: '线下登记提示不能为空' }]}>
                  <Input placeholder="请输入线下登记提示" style={{ width: '40%' }} />
        </Form.Item>
        </>}

{/* -------------2---------------------------------------------------------------------------------------- */}
{/* 
<Form.Item labelCol={formItemLayouts} name="ranking_time" label="中奖记录" valuePropName="checked">

  
              <Switch onChange={(e) => {  }} />
            </Form.Item>
<div style={{color:'red',marginTop:'10px'}}>注意：开启后将在首页展示用户的中奖记录</div> */}
{/* -------------2---------------------------------------------------------------------------------------- */}

        
        <Form.Item labelCol={formItemLayouts} name="lottery_method" label="分数界限" rules={[{ required: true, message: '兑换方式不能为空' }]}>
          <Radio.Group onChange={(e) => { slottery(e.target.value) ;console.log(e.target.value)}}>
            <Radio value="1">单次分数达标抽奖</Radio>
            <Radio value="2">累计分数分段达标抽奖</Radio>
          </Radio.Group>
        </Form.Item>

        {lottery=='1' && <>
        <Form.Item labelCol={formItemLayouts} label="分值" name="score" rules={[{ required: true, message: '分值界限不能为空' }]}>
                  <Input type="number" placeholder="请输入达到多少分可进行抽奖" style={{ width: '40%' }} />
        </Form.Item>

        </>}
        {lottery=='2' && <>

              <div style={{ width: '70%',marginTop:'20px',marginBottom:'20px' }}>
                     <Form.List name="draw_stage_score" 
                     rules={[{ required: true, message: `抽奖分段未添加` }]}
                     >
        {(fields, { add, remove },{errors}) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  display: 'flex',
                  marginBottom: 5,
                  width: '80%',

                }}
                align="baseline"
                className='inps'
              >
  
                <Form.Item labelCol={formItemLayoutsa}
                  {...restField}
                  style={{ width: '100%',marginLeft:'0px' }}
                  // style={{ width: '0%' }}
                  name={[name]}
                  rules={[{ required: true, message: `抽奖分段${name+1}不能为空` }]}
                  label={`分段${name+1}:`}
                
                >
                  <Input placeholder={`请输入抽奖分段${name+1}`} style={{ width: '95%',marginLeft:'9px' }}/>
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            {/* <div style={{ width: '80%',position:'relative',}}>
              <div style={{width:"80%",position:"relative",left:"calc(50% + 53px)",transform:"translate(-50%)"}}> */}
            <Form.Item wrapperCol={{ span:10, offset: 6 }}>
              <Button ty1e="dashed" onClick={() => add()} block icon={<PlusOutlined />} >
                添加抽奖分段
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
            {/* </div>
            </div> */}
            <Col span={20} offset={4} style={{ color: 'red', marginTop: '10px' }}>注意：累计分数分段达标抽奖 请按照从小到大的是顺序填写</Col>
          </>
        )}
      </Form.List>

              </div>

    

        </>}











       
        

        <br/>
        </>}
            {/* <Form.Item labelCol={formItemLayouts} label="活动名称" name="title" rules={[{ required: true, message: '问题不能为空' }]}>
              <Input placeholder="请输入活动名称"  style={{ width: '40%' }} />
            </Form.Item>



            <Form.Item labelCol={formItemLayouts} label="活动时间" name="s_time" >
              <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
            </Form.Item>
            <Form.Item labelCol={formItemLayouts} label="活动规则" name="explain" >

              <TextArea rows={4} placeholder="请输入活动规则" value={""} onChange={(e) => { }} style={{ width: '40%' }} />


            </Form.Item> */}
            <Form.Item labelCol={formItemLayouts} wrapperCol={{ ...formItemLayout.wrapperCol, offset: 3 }}>
            <Button type="link"  style={{textAlign:"left",position:"relative",left:"-20px",top:"-10px",marginBottom:"0px"}}>
              <span style={{textDecoration:"underline"}} onClick={()=>{
                // console.log(1)
                navigate(`/background/details/prize?keid=${pageid}&name=${paname}`)
              }}>编辑奖品信息</span>
            </Button>
            <Col  style={{ textAlign:"left",position:"relative",left:"-20px",top:"-10px",marginBottom:"20px",color: 'red', marginTop: '0px' }}>注意：如果当前是‘幸运转盘’则只会选择奖品列表中的前6个奖品</Col>
            <br/>
              <Button type="primary" loading={load1} htmlType="submit">
                保存
              </Button>
            </Form.Item>
          </Form></ConfigProvider >
        </div>
  </Spin>
    </div>
   
  );
}

