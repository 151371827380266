import React, { Component,useEffect,useState} from 'react'


import { Form, Input, Button,message, Space,Radio ,Breadcrumb,Checkbox, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import "./compile.css"
import { questionCreate } from "../../../api/api.js"; //新增
import { questionUpdate } from "../../../api/api.js"; //编辑
import { questionContent } from "../../../api/api.js"; //获取题目详情


import {useNavigate,useLocation} from "react-router-dom" //引入路由模块

export default function Bna() {
  const navigate = useNavigate();
  const location = useLocation()
  let totoken = window.localStorage.getItem("token");
  // console.log(totoken)
  if(totoken==null || totoken==""){
    // alert(1)
    navigate("/")
  }

  let [increaseda,usincreaseda]=useState(0)//单选选项数据
  let [increasedb,usincreasedb]=useState(0)//多选选项数据


// let [pattern,spattern]=useState(false)//当前的模式（添加或者修改）
let spattern=false
let zpid=null

  // // 获取页面参数
  // function hqcorid(key, Url) {
  //   var str = Url;
  //   str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
  //   // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
  //   var arr = str.split("&");
  //   // console.log(666666,arr)
  //   var obj = new Object();
  //   // 将每一个数组元素以=分隔并赋给obj对象
  //   for (var i = 0; i < arr.length; i++) {
  //     var tmp_arr = arr[i].split("=");
  //     obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
  //   }
  //   // console.log("1234564,", obj[key]);
  //   return obj[key];
  // }
  let pageid=hqcorid("keid",location.search)
  let paname=hqcorid("name",location.search)
  // let tyee
  let redacts=hqcorid("type",location.search)
  let id
  let ids=hqcorid("id",location.search)
  function wang(){
    if(ids!=undefined && ids!="" && ids!=null){
      sboys("编辑")
    }else{
      sboys("新增")
    }
  if(hqcorid("id",location.search)){
    // id=(hqcorid("id",location.search)).replace(/@@/g,"%")
    
    questionContent(`id=${ids}&activity_id=${pageid}`).then((res)=>{
      // console.log(res)
      if(res.code==0){
      console.log(res)
        id=res.data.question
        
        tz()
        // console.log(ids)

      // })//多选选项数据
      }else{
        error('未知错误获取详情失败，刷新页面重试')
        return
      }
    })
  }else{
    ids=undefined
  }
  }
  // console.log(JSON.stringify(a).replace(/%/g,"$"))
  // console.log(id)
  // console.log(777,id)

  let [boys,sboys]=useState()


function name() {
      // let ss= window.location.hash.split('compile')[1]
  // console.log(ss)
  if(redacts!=undefined && redacts!="" && ids!=null){
    // return
    //  let redact=hqcorid("id",ss)
    // console.log(7777888,)
    
     
    //  console.log(666,redacts)
     zpid=ids
     if(redacts==1){
      return "a"
     }else if(redacts==2){
      return "b"
     }else if(redacts==3){
      return "c"
     }else{
         spattern=true
       return "a"
     
     }
    }else{
    // console.log(7777888,id)
      spattern=true
            // spattern(true)
      return "a"
    }

  }


  let [module,smodule]=useState(name())//题目模块顺序数据
  
  
  let [jzbtn,sjzbtn]=useState(false)//题目模块顺序数据
  
  


//使用生命周期的钩子
// console.log(useEffect)
useEffect(()=>{
  // console.log("挂载完成,更新完成")
  wang()


},[])
// console.log(JSON.stringify(a).replace(/%/g,"$"))
    // 获取页面参数
   function hqcorid(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj正确象
      for (var i = 0; i < arr.length; i++) {
        // console.log(arr)
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      return obj[key];
    }
    const [forma] = Form.useForm();
    const [formb] = Form.useForm();
    const [formc] = Form.useForm();

// 如果是编辑，给当前页面填值
function tz(){
  // return
// console.log(Form.useForm)
  // let ss= window.location.hash.split('compile')[1]
  // console.log(ss)
  if(id!=undefined && id!="" && id!=null){

    //  let redact=hqcorid("id",ss)
     let redacts=id
    //  console.log(redacts)
     if(redacts.type==1){
       let xuanx=[]
       if(redacts.option_a!=null){
         xuanx.push(redacts.option_a)
       }
       if(redacts.option_b!=null){
        xuanx.push(redacts.option_b)
      }
      if(redacts.option_c!=null){
        xuanx.push(redacts.option_c)
      }
      if(redacts.option_d!=null){
        xuanx.push(redacts.option_d)
      }
      if(redacts.option_e!=null){
        xuanx.push(redacts.option_e)
      }
      forma.setFieldsValue({      
        username:redacts.title,
        user:redacts.true,
        users:xuanx,
        fena:redacts.score,
        jiea:redacts.analysis,
      })
      usincreaseda(xuanx.length)
     }else if(redacts.type==2){
      let xuanx=[]
      if(redacts.option_a!=null){
        xuanx.push(redacts.option_a)
      }
      if(redacts.option_b!=null){
       xuanx.push(redacts.option_b)
     }
     if(redacts.option_c!=null){
       xuanx.push(redacts.option_c)
     }
     if(redacts.option_d!=null){
       xuanx.push(redacts.option_d)
     }
     if(redacts.option_e!=null){
       xuanx.push(redacts.option_e)
     }
    //  console.log(redacts.true.split(""))
    //  return
      formb.setFieldsValue({      
        usernameb:redacts.title,
        userb:redacts.true.split(""),
        usersb:xuanx,
        fenb:redacts.score,
        jieb:redacts.analysis,
      })
      usincreasedb(xuanx.length)
      
    }else if(redacts.type==3){
      formc.setFieldsValue({      
          usernamec:redacts.title,
        userc:redacts.true=="A" ? "1" : "0",
        fenc:redacts.score,
        jiec:redacts.analysis,
      })

    }
  }

}


const error = (a) => {
  message.error(a);
};
const success = (b) => {
  message.success(b);
};

// 单选题交按钮
  const onFinisha = values => {
    // console.log(values)
    if(values.users==undefined){
      error("选项不能为空")
      return
    }
    if(values.users.length<2){
      // console.log(1)
        error('选项不能少于两个')
      return
    }
    
    if(values.user.length>1){
      error('答案格式错误')
      return
    }
    if(spattern==false){
      questionUpdate(`analysis=${encodeURIComponent(values.jiea)}&score=${values.fena}&id=${zpid}&type=1&title=${encodeURIComponent(values.username)}&true=${values.user}&options=${encodeURIComponent(JSON.stringify(values.users))}&activity_id=${pageid}`).then((res)=>{
        // console.log(res)
        if(res.code==0){
          success("修改成功")
          navigate(`/background/details/configuration?keid=${pageid}&name=${paname}`)
        }else{
          error('未知错误请检查题目格式，或刷新页面重试')
        }

      })
    }else{
      // console.log(encodeURIComponent(values.username))
    questionCreate(`analysis=${encodeURIComponent(values.jiea)}&score=${values.fena}&type=1&title=${encodeURIComponent(values.username)}&true=${values.user}&options=${encodeURIComponent(JSON.stringify(values.users))}&activity_id=${pageid}`).then((res)=>{
      // console.log(res)
      // return
      if(res.code==0){
        success("添加成功")
        navigate(`/background/details/configuration?keid=${pageid}&name=${paname}`)
      }else{
        error('未知错误请检查题目格式，或刷新页面重试')
      }
    })
  }
  };
  // 多选题交按钮
  const onFinishb = values => {
    // console.log(values)
    if(values.usersb==undefined){
      error("选项不能为空")
      return
    }
    if(values.usersb.length<2){
      // console.log(1)
        error('选项不能少于两个')
      return
    }
    if(values.userb.length>(values.usersb.length)){
      error('答案格式错误,请检查你上传的题目，选项不能超过五个,请删除重新编辑')
      return
    }
    if(spattern==false){
      questionUpdate(`analysis=${encodeURIComponent(values.jieb)}&score=${values.fenb}&id=${zpid}&type=2&title=${encodeURIComponent(values.usernameb)}&true=${(values.userb).join("")}&options=${JSON.stringify(values.usersb)}&activity_id=${pageid}`).then((res)=>{
        // console.log(res)
        if(res.code==0){
          success("修改成功")
          navigate(`/background/details/configuration?keid=${pageid}&name=${paname}`)
        }else{
          error('未知错误请检查题目格式，或刷新页面重试')
        }
      })
    }else{
    // console.log('Received values of form:', values);
    questionCreate(`analysis=${encodeURIComponent(values.jieb)}&score=${values.fenb}&type=2&title=${encodeURIComponent(values.usernameb)}&true=${(values.userb).join("")}&options=${encodeURIComponent(JSON.stringify(values.usersb))}&activity_id=${pageid}`).then((res)=>{
      // console.log(res)
      if(res.code==0){
        success("添加成功")
        navigate(`/background/details/configuration?keid=${pageid}&name=${paname}`)
      }else{
        error('未知错误请检查题目格式，或刷新页面重试')
      }
    })
  }
  };
  // 判断题交按钮
  const onFinishc = values => {
    if(values.userc.length>1){
      error('答案格式错误')
      return
    }
    // console.log('Received values of form:', values);
    let option=["正确","错误"]
    if(spattern==false){
      questionUpdate(`analysis=${encodeURIComponent(values.jiec)}&score=${values.fenc}&id=${zpid}&type=3&title=${encodeURIComponent(values.usernamec)}&true=${values.userc==1 ? "A" : "B"}&options=${encodeURIComponent(JSON.stringify(option))}&activity_id=${pageid}`).then((res)=>{
        // console.log(res)
        if(res.code==0){
          success("修改成功")
          navigate(`/background/details/configuration?keid=${pageid}&name=${paname}`)
        }else{
          error('未知错误请检查题目格式，或刷新页面重试')
        }
      })
    }else{
    questionCreate(`analysis=${encodeURIComponent(values.jiec)}&score=${values.fenc}&type=3&title=${encodeURIComponent(values.usernamec)}&true=${values.userc==1 ? "A" : "B"}&options=${encodeURIComponent(JSON.stringify(option))}&activity_id=${pageid}`).then((res)=>{
      // console.log(res)
      if(res.code==0){
        success("添加成功")
        navigate(`/background/details/configuration?keid=${pageid}&name=${paname}`)
      }else{
        error('未知错误请检查题目格式，或刷新页面重试')
      }
    })
  }
  };

  // 模块切换
  function onChange(e) {

    // console.log(`radio checked:${e.target.value}`);
    smodule(e.target.value)
  }

  return (
    <div className='bodys'>  
     <Breadcrumb separator=">" style={{position:"relative",top:"3px"}}>
     <Breadcrumb.Item>
      <a  onClick={()=>{
        navigate(`/background/details/configuration?keid=${pageid}&name=${paname}`)
      }}>题目设置</a>
    </Breadcrumb.Item>

    <Breadcrumb.Item>
      <a >({paname})  { boys}</a>
    </Breadcrumb.Item>
  </Breadcrumb>  
    <Radio.Group onChange={onChange}  value={module} style={{marginTop:'8px'}}>
      <Radio.Button value="a">单选题</Radio.Button>
      <Radio.Button value="b" style={{ marginLeft: 20 }}>多选题</Radio.Button>
      <Radio.Button value="c" style={{ marginLeft: 20 }}>判断题</Radio.Button>
    </Radio.Group>
  <br/>
  <br/>
  <br/>
{/* 单选模块 */}
{
module=="a" ?
// 为单选时候显示这个模块
    <Form name="dynamic_form_nest_item" form={forma}  onValuesChange={(a,b)=>{ ;if(a.users!=undefined){usincreaseda(a.users.length)}}} onFinish={onFinisha}  autoComplete="off">
        <Form.Item label="问题" name="username" rules={[{ required: true ,message: '问题不能为空'}]}>
          <Input placeholder="请输入问题"  showCount maxLength={90}  style={{ width: '60%'}} />
        </Form.Item>
      

      <Form.List name="users"  >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key}  className="inps" style={{ display: 'flex', marginBottom: 8,width: '100%' }} align="baseline">
                <Form.Item
                  {...restField}
                  name={[name]}
                  rules={[{ required: true, message: '选项不能为空' }]}
                  label={`选项${name==0 ? 'A' : name==1 ? "B" : name==2 ? "C" : name==3 ? "D" :"E"}`}
                >
                  <Input placeholder="请输入"  showCount maxLength={45}  />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button type="dashed" style={{ width: '30%',marginLeft:"62px",}} disabled={increaseda>4} onClick={(e) => {add();}} block icon={<PlusOutlined />}>
                新增选项
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      {/* <Form.Item label="答案" name="user" rules={[{ required: true,message: '答案不能为空' }]}>
          <Input placeholder="输入大写字母"  style={{ width: '60%'}} />
        </Form.Item> */}
       <Form.Item name="user" label="答案" rules={[{ required: true,message: '答案不能为空' }]}>
        <Radio.Group>
          {increaseda>0 && <Radio value="A">A</Radio>}
          {increaseda>1 && <Radio value="B">B</Radio>}
          {increaseda>2 && <Radio value="C">C</Radio>}
          {increaseda>3 && <Radio value="D">D</Radio>}
          {increaseda>4 && <Radio value="E">E</Radio>}
        </Radio.Group>
      </Form.Item>

        <Form.Item label="分值" name="fena" rules={[{ required: true,message: '分值不能为空' }]}>
          <Input placeholder="输入分值" style={{ width: '60%'}} />
        </Form.Item>
        <Form.Item label="解析" name="jiea" >
          <Input placeholder="输入解析" style={{ width: '60%'}} />
        </Form.Item>
      <Form.Item>
        
        <Button type="primary" loading={jzbtn} htmlType="submit">
          单选题提交
        </Button>
      </Form.Item>
    </Form>
: 
module=="b" ?
// 为多选时候显示这个模块
    <Form name="dynamic_form_nest_itemb" form={formb}  onValuesChange={(a,b)=>{if(a.usersb!=undefined){usincreasedb(a.usersb.length)}}} onFinish={onFinishb}  autoComplete="off">
  <Form.Item label="问题" name="usernameb" rules={[{ required: true ,message: '问题不能为空'}]}>
    <Input placeholder="请输入问题"  showCount maxLength={90}  style={{ width: '60%'}} />
  </Form.Item>


<Form.List name="usersb"  >
  {(fields, { add, remove }) => (
    <>
      {fields.map(({ key, name, ...restField }) => (
        <Space key={key}  className="inps" style={{ display: 'flex', marginBottom: 8,width: '100%' }} align="baseline">
          <Form.Item
            {...restField}
            name={[name]}
            rules={[{ required: true, message: '选项不能为空' }]}
            label={`选项${name==0 ? 'A' : name==1 ? "B" : name==2 ? "C" : name==3 ? "D" :"E"}`}
          >
            <Input placeholder="请输入"  showCount maxLength={45} />
          </Form.Item>
          <MinusCircleOutlined onClick={() => remove(name)} />
        </Space>
      ))}
      <Form.Item>
        <Button type="dashed" style={{ width: '30%',marginLeft:"62px",}} disabled={increasedb>4} onClick={(e) => {add();}} block icon={<PlusOutlined />}>
          新增选项
        </Button>
      </Form.Item>
    </>
  )}
</Form.List>
{/* <Form.Item label="答案" name="userb" rules={[{ required: true,message: '答案不能为空' }]}>
    <Input placeholder="输入大写字母(多个答案直接请用+号隔开)" style={{ width: '60%'}} />
  </Form.Item> */}
         <Form.Item name="userb" label="答案" rules={[{ required: true,message: '答案不能为空' }]}>
        {/* <Radio.Group>
          {increasedb>0 && <Radio.Button value="A">A</Radio.Button>}
          {increasedb>1 && <Radio.Button value="B">B</Radio.Button>}
          {increasedb>2 && <Radio.Button value="C">C</Radio.Button>}
          {increasedb>3 && <Radio.Button value="D">D</Radio.Button>}
          {increasedb>4 && <Radio.Button value="E">E</Radio.Button>}
        </Radio.Group> */}
     <Checkbox.Group style={{width:"30%"}}>
    <Row>
     {increasedb>0 && <Col span={8}><Checkbox value="A">A</Checkbox></Col>}
     {increasedb>1 && <Col span={8}><Checkbox value="B">B</Checkbox></Col>}
     {increasedb>2 && <Col span={8}><Checkbox value="C">C</Checkbox></Col>}
     {increasedb>3 && <Col span={8}><Checkbox value="D">D</Checkbox></Col>}
     {increasedb>4 && <Col span={8}><Checkbox value="E">E</Checkbox></Col>}
    </Row>
  </Checkbox.Group>
      </Form.Item>
  <Form.Item label="分值" name="fenb" rules={[{ required: true,message: '分值不能为空' }]}>
          <Input placeholder="输入分值" style={{ width: '60%'}} />
        </Form.Item>
        <Form.Item label="解析" name="jieb" >
          <Input placeholder="输入解析" style={{ width: '60%'}} />
        </Form.Item>
<Form.Item>
  <Button type="primary" loading={jzbtn} htmlType="submit">
    多选题提交
  </Button>
</Form.Item>
    </Form>
:
// 为判断时候显示这个模块
    <Form name="dynamic_form_nest_itemc" form={formc}  onValuesChange={(a,b)=>{ }} onFinish={onFinishc}  autoComplete="off">
<Form.Item label="问题" name="usernamec" rules={[{ required: true ,message: '问题不能为空'}]}>
  <Input placeholder="请输入问题"  showCount maxLength={240}  style={{ width: '60%'}} />
</Form.Item>


{/* <Form.Item label="答案" name="userc" rules={[{ required: true,message: '答案不能为空' }]}>
  <Input placeholder="请输入 0 或 1 (0代表错误，1代表正确)" style={{ width: '60%'}} />
</Form.Item> */}
<Form.Item label="答案" name="userc" rules={[{ required: true,message: '答案不能为空' }]}>
        <Radio.Group>
          <Radio value="1">正确</Radio>
          <Radio value="0">错误</Radio>
        </Radio.Group>
</Form.Item>

<Form.Item label="分值" name="fenc" rules={[{ required: true,message: '分值不能为空' }]}>
          <Input placeholder="输入分值" style={{ width: '60%'}} />
        </Form.Item>
        <Form.Item label="解析" name="jiec" >
          <Input placeholder="输入解析" style={{ width: '60%'}} />
        </Form.Item>
<Form.Item>
<Button type="primary" loading={jzbtn} htmlType="submit">
  判断题提交
</Button>
</Form.Item>
    </Form>
    }
    </div>
  );
};

