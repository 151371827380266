// import { } from 'antd';
import React, { Component, useEffect, useState } from 'react'
import zhCN from 'antd/lib/locale/zh_CN';
import moment from "moment";
import "moment/locale/zh-cn";
// moment.locale("zh-cn");
import { Menu, Switch, Form,Spin, DatePicker, Input, Button, ConfigProvider, message, Space, Radio, Breadcrumb, Checkbox, Row, Col,Upload,Modal} from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import "./certificate.css"
import { certificateGet } from "../../../../api/api.js"; //获取活动详情
import { certificateUpdate } from "../../../../api/api.js"; //保存基本配置
import { ActivityUpdate } from "../../../../api/api.js"; //保存基本配置
import { BrowserRouter, Link, Routes, Route,Outlet } from "react-router-dom" //引入路由模块
// import Na from "./configuration"
import { UploadOutlined } from '@ant-design/icons';
// import {  } from '@ant-design/icons';
import { useNavigate, useLocation } from "react-router-dom" //引入路由模块
// 引入 axios
import axios from 'axios';
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const formItemLayout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 20,
  },
};
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
    });
export default function Details() {
  const navigate = useNavigate();
  const location = useLocation()
  let totoken = window.localStorage.getItem("token");
  // console.log(totoken)
  if (totoken == null || totoken == "") {
    // alert(1)
    navigate("/")
  }
  const error = (a) => {
    message.error(a);
  };
  const success = (e) => {
    message.success(e);
  };

  let [load1, sload1] = useState(false)//基本设置按钮加载状态
  let [load, sload] = useState(false)//基本设置按钮加载状态
  let [tyt, styt] = useState(true)//答题次数限制的开关
  let [rant, srant] = useState('')//答题次数限制的开关
  
// ---------------------------------------------------------------------------------------------------
const [previewVisible, setPreviewVisible] = useState(false);
const [previewImage, setPreviewImage] = useState('');
const [previewTitle, setPreviewTitle] = useState('');
const [fileList, setFileList] = useState([

]);
const [fileList2, setFileList2] = useState([

]);
const handleCancel = () => setPreviewVisible(false);
const handlePreview = async (file) => {
  if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
  }

  setPreviewImage(file.url || file.preview);
  setPreviewVisible(true);
  setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
};

const handleChange = ({ fileList: newFileList }) => {
  setFileList(newFileList); 
  form1.setFieldsValue({
      file1: newFileList
  })
  if(newFileList.length!=0){
    if (newFileList[0].response != null && newFileList[0].response != undefined) {
      let wang={
       file1:newFileList[0].response.data.url
      }
      spreview({...preview,...wang})
 }
  }

  
};
const handleChange2 = ({ fileList: newFileList }) => {
  setFileList2(newFileList);
  form1.setFieldsValue({
      file2: newFileList
  })
  if(newFileList.length!=0){

  if (newFileList[0].response != null && newFileList[0].response != undefined) {
    let wang={
     file2:newFileList[0].response.data.url
    }
    spreview({...preview,...wang})
}
  }
};
const uploadButton = (
  <div>
      <UploadOutlined style={{

          fontSize: "30px",
          color: "rgb(0 99 246)",
      }} />
      <div
          style={{
              // marginTop: 8,
              fontSize: "10px",
              color: "#6a9eec",
              textAlign: "justify"
          }}
      >
          类型:.jpg,.png<br />
          大小:500kb以内<br />
          尺寸:638*840
      </div>
  </div>
);
const uploadButton2 = (
  <div>
      <UploadOutlined style={{

          fontSize: "30px",
          color: "rgb(0 99 246)",
      }} />
      <div
          style={{
              // marginTop: 8,
              fontSize: "10px",
              color: "#6a9eec",
              textAlign: "justify"
          }}
      >
          类型:.png<br />
          大小:20kb以内<br />
          尺寸:174*自适应
      </div>
  </div>
);
// -------------------------------------------------------------------------------------------------
  let [dataAnalysis,sdataAnalysis]=useState({})
    // 获取页面参数
    function hqcorid(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      // console.log(666666,arr)
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      // console.log("1234564,", obj[key]);
      return obj[key];
    }
    let pageid=hqcorid("keid",location.search)
let paname=hqcorid("name",location.search)
//使用生命周期的钩子

useEffect(()=>{
  // console.log("挂载完成,更新完成")
  acquire()
},[])
// 获取活动详情
function acquire(){
  if(pageid){
    sload(true)
    // id=(hqcorid("id",location.search)).replace(/@@/g,"%")
    
    certificateGet(`id=${pageid}`).then((res)=>{
      // console.log(res)
      if(res.code==0){
      console.log(res)
      // if(res.data.lists==null){
      //   sload(false)
      //   return
      // }
      sdataAnalysis(res.data.lists)
      basicSetting(res.data.lists)
      }else{
        error('未知错误获取详情失败，刷新页面重试')
        return
      }
    })
  }else{
    error('无法获取活动id，请退出重试')
  }
  }
const [form1] = Form.useForm();
let [preview, spreview] = useState({})//预览赋值数据

  // 基本设置赋值
function basicSetting(a){
  // return
  if(a!=null){
    form1.setFieldsValue({     
      issue:a.status==2?true:false,
      grant1:a.title,
      grant2:a.name,
      grant3:a.content,
      grant4:a.explain,
      grant_type:a.type==1?"offline":a.type==2?"maof":'',
      score:a.score,
      file1: [{ url: a.background, status: "done", }],
      file2: [{ url: a.logo, status: "done", }],
    })
    setFileList([{
      url: a.background,
      status: "done",
  }])
  setFileList2([{
      url: a.logo,
      status: "done",
  }])
  styt(a.status==2?true:false)
  srant(a.type==1?"offline":a.type==2?"maof":'')
  }else{
    styt(false)
    srant('offline')
  }

  
   sload(false)
   if(a!=null){
    spreview(
      {     
        issue:a.status==2?true:false,
        grant1:a.title,
        grant2:a.name,
        grant3:a.content,
        grant4:a.explain,
        grant_type:a.type==1?"offline":a.type==2?"maof":'',
        score:a.score,
        file1:a.background,
        file2:a.logo,
      }
    )
   }

  }
  // 基本设置题交按钮
  const onFinish1 = values => {
    console.log(values)
// return
    sload1(true)
    // console.log(formatDate(values.s_time[0]._d))
    let backgrounds,logos
    if(values.file1&&values.file2){
      
      if (values.file1[0].response) {
        backgrounds = values.file1[0].response.data.url
    } else {
        backgrounds = values.file1[0].url
    }
    if (values.file2[0].response) {
      logos = values.file2[0].response.data.url
  } else {
      logos = values.file2[0].url
  }
    }
    console.log(123)
    let data={
      id:pageid,
      status:values.issue?2 : 1,
      title:values.grant1?values.grant1:null,
      name:values.grant2?values.grant2:null,
      content:values.grant3?values.grant3:null,
      type:values.grant_type?(values.grant_type=="offline"?1:2):null,
      explain:values.grant4?values.grant4:null,
      score:values.score!="0"?(values.score?values.score:null):"0",

      background:backgrounds,

      logo:logos,
 
      
      
    }
    certificateUpdate(data).then((res)=>{
      // console.log(res)
      if(res.code==0){
      console.log(res)
      // sdataAnalysis(res.data.info)

      success("保存成功")
      acquire()
    sload1(false)
      }else{
        sload1(false)
        error(res.message)
        return
      }
    })
  }

  // 预览负责
 function oncla(a,b){

  
  if((a.file1==undefined||a.file1==null)&&(a.file2==undefined||a.file2==null)){
    // console.log(a.file1)
    spreview({...preview,...a})
  }
 
  // console.log(preview)
 }

  return (
    
    <div className='bodys' style={{height:"100%"}}>
      {/* 基本设置 */}
      <Spin spinning={load} >
        <div style={{ position: "relative", top: "10px" }}>
          <ConfigProvider locale={zhCN}>
           <Form form={form1} labelAlign={'right'} name="from1"   {...formItemLayout} onValuesChange={(a, b) => {oncla(a,b)}} onFinish={onFinish1} autoComplete="off">
          <Form.Item name="issue" label="开启证书" valuePropName="checked" >
              <Switch onChange={(e) => {styt(e) }} />
            </Form.Item>
            {tyt &&
              <>

        <Form.Item label="证书标题" name="grant1" rules={[{ required: true, message: '证书标题不能为空' }]}>
                  <Input placeholder="请输入证书标题,可输入/n换行(/n只能使用一次)" style={{ width: '40%' }} />
        </Form.Item>
        <Form.Item label="证书名称" name="grant2" rules={[{ required: true, message: '证书名称不能为空' }]}>
                  <Input placeholder="请输入证书名称" style={{ width: '40%' }} />
        </Form.Item>
        <Form.Item label="证书内容" name="grant3" rules={[{ required: true, message: '证书内容不能为空' }]}>
                  <Input placeholder="请输入证书内容" style={{ width: '40%' }} />
        </Form.Item>

        <Form.Item label="分值界限" name="score" rules={[{ required: true, message: '分值界限不能为空' }]}>
        <Input type="number" placeholder="请输入达到多少分展示证书" style={{ width: '40%' }} />
        </Form.Item>
        <Form.Item name="grant_type" label="及格展示" rules={[{ required: true, message: '兑换方式不能为空' }]}>
          <Radio.Group onChange={(e) => { srant(e.target.value) ;console.log(e.target.value)}}>
            <Radio value="offline">展示分数</Radio>
            <Radio value="maof"> 展示文案</Radio>
          </Radio.Group>
        </Form.Item>
        {rant!='offline' && <>
        <Form.Item label="及格文案" name="grant4" rules={[{ required: true, message: '及格文案不能为空' }]}>
                  <Input placeholder="请输入及格文案" style={{ width: '40%' }} />
        </Form.Item>
          </>}
        <Form.Item label="证书背景" name="file1" rules={[
                    {
                        required: true,
                        validator: async (a, names) => {
                            console.log(names)
                            if (!fileList || fileList.length < 1) {
                                return Promise.reject(new Error('证书背景不能为空'));
                            } else {
                                return Promise.resolve()
                            }
                        },
                    },
                ]}
                >
                    <div>
                        <Upload
                            action={axios.defaults.baseURL+'/upload'}
                            listType="picture-card"
                            headers={{
                                'Authorization': window.localStorage.getItem("token") || '',
                                'X-Requested-With': null

                            }}

                            accept="image/*"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleChange}
                        >
                            {fileList.length >= 1 ? null : uploadButton}
                        </Upload>

                        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img
                                alt="example"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal>
                    </div>

        </Form.Item>
        <Form.Item label="证书Logo" name="file2"
                    rules={[
                        {
                            required: true,
                            validator: async (a, names) => {
                                console.log(names)
                                if (!fileList2 || fileList2.length < 1) {
                                    return Promise.reject(new Error('证书Logo不能为空'));
                                } else {
                                    return Promise.resolve()
                                }
                            },
                        },
                    ]}
                >
                    <div>
                        <Upload
                            action={axios.defaults.baseURL+'/upload'}
                            listType="picture-card"
                            headers={{
                                'Authorization': window.localStorage.getItem("token") || '',
                                'X-Requested-With': null

                            }}

                            accept="image/*"
                            fileList={fileList2}
                            onPreview={handlePreview}
                            onChange={handleChange2}
                        >
                            {fileList2.length >= 1 ? null : uploadButton2}
                        </Upload>

                        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img
                                alt="example"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal>
                    </div>
        </Form.Item>
        <br/>
        </>}
            <Form.Item wrapperCol={{ ...formItemLayout.wrapperCol, offset: 2 }}>
              <Button type="primary" loading={load1} htmlType="submit">
                保存
              </Button>
            </Form.Item>
           </Form>
          </ConfigProvider >
        </div>
  </Spin>
  {preview.issue &&
      <div className='phone' id='phone'>
      <div className='bgz'>
      </div>
      <div className='mengcs'>
          <div className='zs-box'>
              <div className='zs-border'>
                <div className='zs-openers'>
                      <img src={preview.file1} className='zs-bgOPeners'></img>
                      {preview.grant1 &&
                        <>
                          {preview.grant1.indexOf('/n')==-1 &&
                           <div className='t1-opener t1-family'>{preview.grant1}</div>
                          }
                          {preview.grant1.indexOf('/n')!=-1 &&
                           <div className='t1-opener t1-family'>
                            <span style={{fontWeight:"bold"}}>{preview.grant1.split('/n')[0]}</span>
                            <br/>
                            <span style={{fontWeight:"lighter",fontSize:"0.7rem"}}>{preview.grant1.split('/n')[1]}</span>
                           </div>
                          }
                        </>
                      }

                      
                      <div  className="t2-opener t2-family">{preview.grant2}</div>
                      {preview.grant3!=''&&preview.grant3!=undefined&&
                      <div  className="t3-opener t3-family">花名，{preview.grant3}</div>
                      }
                      
                      <div  className="t4-opener t4-family">{preview.grant_type=='offline'?'100分':preview.grant4}</div>
                      <img  src={preview.file2} alt="" className="logo-operer"></img>
                </div>
                <img src={require('../../../../assets/gb.png')} className='zs-gbBtn'></img>
              </div>
          </div>
      </div>
      {/* {tk != 4 && <div className='mengcs'>
          {tk == 1 && <div className='bgzs'>
              <img src={xrimg} className="xiaoren" />
              <img src={require('../../../assets/ku.png')} className="xmk" />
          </div>}
          {tk == 2 && <div className='cjk'>
              <img src={bgimg} className="defen" />
              <img src={xrimg} className="xiaorens" />
          </div>}
          {tk == 3 && <div class="bgaz">
              <img src={xrimg} className="xiaor" />
              <img src={require('../../../assets/k3.png')} className="sphb" />
          </div>}
      </div>} */}
</div>
  }

    </div>
   
  );
}

