// import { } from 'antd';
import React, { Component, useEffect, useState } from 'react'
import zhCN from 'antd/lib/locale/zh_CN';
import moment from "moment";
import "moment/locale/zh-cn";
// moment.locale("zh-cn");
import { Menu, Switch, Form,Spin, DatePicker, Input, Button, ConfigProvider, message, Space, Radio, Breadcrumb, Checkbox, Row, Col } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import "./details.css"
import { getActivityInfo } from "../../../api/api.js"; //获取活动详情
import { activityBasicUpdate } from "../../../api/api.js"; //保存基本配置
import { ActivityUpdate } from "../../../api/api.js"; //保存基本配置
import { BrowserRouter, Link, Routes, Route,Outlet } from "react-router-dom" //引入路由模块
import Na from "./configuration"
import Ad from "./set/advanced"//高级设置
import Ba from "./set/basic"//基本设置
import Bna from "./compile"
import Theme from "./theme"
import Nas from "./rankingList"
import Draw from "./set/draw"
import Prize from "./set/prize"
import Record from "./set/record"
import Database from "./database"
import Certificate from "./set/certificate"
import Share from "./set/share"




// import {  } from '@ant-design/icons';
import { useNavigate, useLocation } from "react-router-dom" //引入路由模块
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const formItemLayout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 20,
  },
};
export default function Details() {
  const navigate = useNavigate();
  const location = useLocation()
  let totoken = window.localStorage.getItem("token");
  // console.log(totoken)
  if (totoken == null || totoken == "") {
    // alert(1)
    navigate("/")
  }

    // 获取页面参数
    function hqcorid(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      // console.log(666666,arr)
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      // console.log("1234564,", obj[key]);
      return obj[key];
    }
    let pageid=hqcorid("keid",location.search)
let paname=hqcorid("name",location.search)
//使用生命周期的钩子
// const location = useLocation()

useEffect(()=>{
console.log(location.pathname.split("/")[3])
},[])






  const onClick = (e) => {
    console.log('click ', e);
    // smold(e.key)

    if(e.key=='mai1'){
      navigate(`/background/details/basic?keid=${pageid}&name=${paname}`)

    }
    if(e.key=="mai2"){
      navigate(`/background/details/configuration?keid=${pageid}&name=${paname}`)
    }
    if(e.key=='mai3'){
      navigate(`/background/details/advanced?keid=${pageid}&name=${paname}`)

    }
    if(e.key=='mai4'){
      navigate(`/background/details/theme?keid=${pageid}&name=${paname}`)

    }
    if(e.key=='mai5'){
      // navigate(`/background/details/rankingList?keid=${pageid}&name=${paname}`)
      // navigate(`/background/details/database?keid=${pageid}&name=${paname}`)
      navigate(`/background/details/database/rankingList?keid=${pageid}&name=${paname}`)


    }
    if(e.key=='mai6'){
      navigate(`/background/details/draw?keid=${pageid}&name=${paname}`)
    }
    if(e.key=='mai7'){
      navigate(`/background/details/certificate?keid=${pageid}&name=${paname}`)

    }
    if(e.key=='mai8'){
      navigate(`/background/details/share?keid=${pageid}&name=${paname}`)

    }
  };

  return (
    
    <div className='bodys' >
     {/* <Breadcrumb separator="/" style={{position:"absolute",top:"-5px"}}>
     <Breadcrumb.Item>
      <a  onClick={()=>{
        navigate(`/background/activity`)
      }}>活动列表</a>
    </Breadcrumb.Item>

    <Breadcrumb.Item>
      <a >{paname}</a>
    </Breadcrumb.Item>
  </Breadcrumb>   */}

      <Menu mode="horizontal" style={{marginTop:"0px"}} defaultSelectedKeys={()=>{
        if(location.pathname.split("/")){
       if(location.pathname.split("/")[3]=="basic"){
        return ['mai1']
       } 
       if(location.pathname.split("/")[3]=="configuration" || location.pathname.split("/")[3]=="compile"){
        return ['mai2']
       }
       if(location.pathname.split("/")[3]=="advanced"){
        return ['mai3']
       }  
       if(location.pathname.split("/")[3]=="theme"){
        return ['mai4']
       } 
       if(location.pathname.split("/")[3]=="rankingList" || location.pathname.split("/")[3]=='record' || location.pathname.split("/")[3]=='database'){
        return ['mai5']
       } 
       if(location.pathname.split("/")[3]=="draw" || location.pathname.split("/")[3]=='prize'){
        return ['mai6']
       }
       if(location.pathname.split("/")[3]=="certificate"){
        return ['mai7']
       }  
       if(location.pathname.split("/")[3]=="share"){
        return ['mai8']
       } 
      }
      }} onClick={onClick}>
        <Menu.Item key="mai1" icon={<MailOutlined />}>
          基本设置
        </Menu.Item>
        <Menu.Item key="mai2" icon={<MailOutlined />}>
          题目设置
        </Menu.Item>
        <Menu.Item key="mai3" icon={<MailOutlined />}>
          高级设置
        </Menu.Item>
        <Menu.Item key="mai6" icon={<MailOutlined />}>
          抽奖设置
        </Menu.Item>
        <Menu.Item key="mai7" icon={<MailOutlined />}>
          证书设置
        </Menu.Item>
        <Menu.Item key="mai8" icon={<MailOutlined />}>
          分享设置
        </Menu.Item>

        <Menu.Item key="mai4" icon={<MailOutlined />}>
          样式设置
        </Menu.Item>
        <Menu.Item key="mai5" icon={<MailOutlined />}>
          数据看板
        </Menu.Item>

      </Menu>
    <div className='boss'>

    
        <Routes>
            <Route path='/configuration/*' element={<Na/>}/>
            <Route path='/database/*' element={<Database/>}/>

            <Route path='/advanced' element={<Ad/>}/>
            <Route path='/basic' element={<Ba/>}/>
            <Route path='/compile' element={<Bna/>}/>
            <Route path='/theme' element={<Theme/>}/>
            <Route path='/draw' element={<Draw/>}/>
            <Route path='/certificate' element={<Certificate/>}/>
            <Route path='/share' element={<Share/>}/>

            {/* <Route path='/record' element={<Record/>}/> */}
            
            
            <Route path='/prize' element={<Prize/>}/>
            {/* <Route path='/rankingList' element={<Nas/>}/> */}

            
            {/* <Route path='/manage' element={<Nass/>}/> */}
            {/* <Route path='/cs' element={<Cs/>}/> */}
            {/* <Route path='/activity' element={<Activity/>}/> */}
            {/* <Route path='/theme' element={<Theme/>}/> */}
            {/* <Route path='/details' element={<Details/>}/> */}
        </Routes>
        <Outlet/>
</div>
    </div>
   
  );
}
 