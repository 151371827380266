import React, { Component, useEffect, useState } from "react";
import {
  BrowserRouter,
  useNavigate,
  useLocation,
  Link,
  Routes,
  Route,
  Outlet,
} from "react-router-dom"; //引入路由模块
import zhCN from "antd/lib/locale/zh_CN";
// import './configuration.css'
import {
  Divider,
  Table,
  Breadcrumb,
  Modal,
  Button,
  ConfigProvider,
  Popconfirm,
  Upload,
  message, Input,
  Form,
  Radio,
} from "antd";
// import Question from "./ces"
// 引入 axios
import axios from 'axios';

import { getDrawList } from "../../../../api/api.js"; //获取奖品列表
import { updateDraw } from "../../../../api/api.js"; //奖品修改
import { createDraw } from "../../../../api/api.js"; //奖品修改


// import { exports } from "../../../../api/api.js"; //导出数据
import './prize.css'
import { UploadOutlined } from '@ant-design/icons';

import userEvent from "@testing-library/user-event";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });
const formItemLayout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 20,
  },
};
export default function Na() {
  const navigate = useNavigate();
  let totoken = window.localStorage.getItem("token");
  // console.log(totoken)
  if (totoken == null || totoken == "") {
    // alert(1)
    navigate("/");
  }

  const location = useLocation();
  const [aum, saum] = useState(0);//概率总和
  const [typesOf, stypesOf] = useState(1);//类型 1是新增  2是编辑
  const columns = [
    {
      title: `序号`,
      align: "center",
      width: 60,
      render: (e, x, i) => <div>{i + 1}</div>,
      // dataIndex: 'name',
    },
    {
      title: "奖品名称",
      align: "center",

      // dataIndex: 'department_id',
      // width: 150,
      render: (e, x, i) => (
        <div>{x.name == "奖品名称" ? "未填写" : x.name}</div>
      ),
    },
    {
      title: "奖品图片",
      align: "center",

      // dataIndex: 'username',
      // width: 200,
      render: (e, x, i) => (
        <div>{x.img == "" ? "未填写" : <img className="imgs" src={x.img} />}</div>
      ),
    },
    {
      title: `抽奖概率(${aum}%)`,
      align: "center",
      // sorter:true,
      // dataIndex: 'probability',
      render: (e, x, i) => <div>{x.probability}%</div>,
      width: 150,
    },
    {
      title: "库存",
      align: "center",
      // sorter:true,
      dataIndex: "num",
      // width: 300,
    },
    {
      title: "类型",
      align: "center",
      // sorter:true,
      // dataIndex: 'time',
      // width: 300,
      render: (e, x, i) => (
        <div>{x.type == 1 ? "奖品" : x.type == 2 ? "谢谢参与" : "未填写"}</div>
      ),
    },
    {
      title: "操作",
      align: "center",
      // sorter:true,
      // dataIndex: 'submission_time',
      // width: 300,
      render: (e, x, i) => (
        <div>
          <Button
            style={{ marginBottom: 4 }}
            type="primary"
            ghost
            onClick={() => {
              form.setFieldsValue({
                title: '',
                // file1:x.img,
                file1: null,
                probability: '',
                quantity: "",
                aper: '',

              })
              setFileList([])

              single(x)
              sdepartment(true)

              console.log(x)
              if (x.img != "" && x.img != undefined && x.img != null) {
                setFileList([{
                  url: x.img,
                  status: "done",
                }])
              }

              form.setFieldsValue({
                title: x.name != "奖品名称" ? x.name : "",
                // file1:x.img,
                file1: [{ url: x.img, status: "done", }],
                probability: x.probability,
                quantity: x.num,
                aper: x.type == 1 ? "apple" : x.type == 2 ? "pear" : '',

              })
              stypesOf(2)
              // navigate(`/background/details/rankingList?keid=${b.id}&name=${b.title}`)
            }}
          >
            编辑
          </Button>
        </div>
      ),
    },
  ]; //定义表格的title

  const success = (e) => {
    message.success(e);
  };

  let [gao, sgao] = useState(500);
  window.onresize = function () {
    let boss = document.getElementsByClassName("body")[0].offsetHeight - 110;
    sgao(boss);
  }; //设置表格的高度

  // 刷新页面
  function refresh() {
    hqlb(1);
    usejz(true);
  }

  //使用生命周期的钩子
  // console.log(useEffect)
  useEffect(() => {
    // console.log("挂载完成,更新完成")

    setTimeout(() => {
      let boss = document.getElementsByClassName("body")[0].offsetHeight - 110;
      sgao(boss);
    }, 1);
    hqlb(1);

    // console.log(location)
  }, []);

  // 获取页面参数
  function hqcorid(key, Url) {
    var str = Url;
    str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
    // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
    var arr = str.split("&");
    // console.log(666666,arr)
    var obj = new Object();
    // 将每一个数组元素以=分隔并赋给obj对象
    for (var i = 0; i < arr.length; i++) {
      var tmp_arr = arr[i].split("=");
      obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
    }
    // console.log("1234564,", obj[key]);
    return obj[key];
  }
  let pageid = hqcorid("keid", location.search);
  let paname = hqcorid("name", location.search);

  const [fileList, setFileList] = useState([

  ]);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [ingle, single] = useState({});


  let [sj, usesj] = useState([]); //所有数据
  let [jz, usejz] = useState(true); //加载状态
  let [department, sdepartment] = useState(false); //加载状态
  const handleCancel = () => setPreviewVisible(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList); console.log(newFileList);
    form.setFieldsValue({
      file1: newFileList
    })
    // if (newFileList[0].response != null && newFileList[0].response != undefined) {
    //     document.getElementById("phone").style.backgroundImage = `url('${newFileList[0].response.data.url}')`
    // }
  };

  const [form] = Form.useForm();

  const uploadButton = (
    <div>
      <UploadOutlined style={{

        fontSize: "30px",
        color: "rgb(0 99 246)",
      }} />
      <div
        style={{
          // marginTop: 8,
          fontSize: "10px",
          color: "#6a9eec",
          textAlign: "justify"
        }}
      >
        类型:.png<br />
        大小:500kb以内<br />
        尺寸:500*500
      </div>
    </div>
  );
  function hqlb(i) {
    getDrawList(`activity_id=${pageid}`).then((res) => {
      console.log(res);
      usesj(res.data.list);
      saum(res.data.v)

      usejz(false);
      if (i != 1 && res.data.lists.length == 0) {
        hqlb(1);
      }
    });
  }
  const onFinishs = (values) => {
    console.log('Received values of form:', values);
    let background
    if (values.file1[0].response) {
      background = values.file1[0].response.data.url
    } else {
      background = values.file1[0].url
    }
    // console.log(ingle)
    if (typesOf == 2) {
      let data = {
        id: ingle.id,//奖品id
        name: values.title,//奖品名称
        img: background,//奖品图片
        num: values.quantity,//奖品库存
        type: values.aper == "apple" ? 1 : 2,//奖品类型
        probability: values.probability,//奖品概率
      }
      // return

      updateDraw(data).then((res) => {
        console.log(res);
        if (res.code == 0) {
          sdepartment(false)
          refresh()
          success("修改成功")
        }
        // usesj(res.data.list);

        // usejz(false);
        // if (i != 1 && res.data.lists.length == 0) {
        //   hqlb(1);
        // }
      });
    } else {
      let data = {
        activity_id:pageid,
        // id: ingle.id,//奖品id
        name: values.title,//奖品名称
        img: background,//奖品图片
        num: values.quantity,//奖品库存
        type: values.aper == "apple" ? 1 : 2,//奖品类型
        probability: values.probability,//奖品概率
      }
      // return

      createDraw(data).then((res) => {
        console.log(res);
        if (res.code == 0) {
          sdepartment(false)
          refresh()
          success("新增成功")
        }
        // usesj(res.data.list);

        // usejz(false);
        // if (i != 1 && res.data.lists.length == 0) {
        //   hqlb(1);
        // }
      });
    }


  };
  return (
    <div className="body bodysa">
      <Breadcrumb
        separator=">"
        style={{
          position: "relative",
          top: "0px",
          marginTop: "5px",
          marginBottom: "5px",
        }}
      >
        <Breadcrumb.Item>
          <a
            onClick={() => {
              navigate(
                `/background/details/draw?keid=${pageid}&name=${paname}`
              );
            }}
          >
            抽奖设置
          </a>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <a>({paname}) 编辑奖品信息</a>
        </Breadcrumb.Item>
      </Breadcrumb>


      {/* ------------------------------------------------------------------------ */}
      {/* 部门编辑弹窗 */}
      <Modal
        title={`${typesOf == 1 ? '奖品新增' : '奖品编辑'}`}
        style={{ top: '10%', }}
        width={'1000px'}

        visible={department}
        closable={false}
        footer={[<div key={1} style={{ padding: 0 }} className='footers' id='footers'></div>]}
      >
        <div>
          <Form {...formItemLayout} name="dynamic_form_nest_item" form={form} onFinish={onFinishs} autoComplete="off">
            <Form.Item label="奖品名称" name="title" rules={[{ required: true, message: '奖品名称不能为空' }]}>
              <Input placeholder="请输入奖品名称" style={{ width: '40%' }} />
            </Form.Item>

            <Form.Item label="奖品图片" name="file1" rules={[

              {
                required: true,
                validator: async (a, names) => {
                  // console.log(names)
                  if (!fileList || fileList.length < 1) {
                    return Promise.reject(new Error('奖品图片不能为空'));
                  } else {
                    return Promise.resolve()
                  }
                },
              },
            ]}
            >
              <div>
                <Upload
                  action={axios.defaults.baseURL + '/upload'}
                  listType="picture-card"
                  headers={{
                    'Authorization': window.localStorage.getItem("token") || '',
                    'X-Requested-With': null

                  }}

                  accept="image/*"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>

                <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                  <img
                    alt="example"
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
              </div>

            </Form.Item>
            <Form.Item label="抽奖概率(%)" name="probability" rules={[{ required: true, message: '抽奖概率不能为空' }]}>
              <Input type="number" placeholder="请输入抽奖概率(0~100)" style={{ width: '40%' }} />
            </Form.Item>
            <Form.Item label="奖品数量" name="quantity" rules={[{ required: true, message: '奖品数量不能为空' }]}>
              <Input type="number" placeholder="请输入奖品数量" style={{ width: '40%' }} />
            </Form.Item>
            <Form.Item name="aper" label="类型" rules={[{ required: true, message: '类型不能为空' }]}>
              <Radio.Group>
                <Radio value="apple"> 奖品 </Radio>
                <Radio value="pear"> 谢谢参与 </Radio>
              </Radio.Group>
            </Form.Item><br />
            <Form.Item wrapperCol={{ ...formItemLayout.wrapperCol, offset: 3 }} >
              <Button type="primary" htmlType="submit" >
                保存
              </Button>
              <Button type="primary" onClick={() => { sdepartment(false); }} style={{ marginLeft: '10px' }}>
                取消
              </Button>

            </Form.Item>
          </Form>
        </div>
        {/* <div  style={{marginTop:"18px"}}>分值{tcnr.title}</div> */}

      </Modal>
      {/* ------------------------------------------------------------------------ */}

      <div style={{ textAlign: 'right', margin: '10px', marginRight: '110px' }}>
        <Button type="primary" style={{ marginLeft: 0 }} onClick={() => {
          form.setFieldsValue({
            title: '',
            // file1:x.img,
            file1: null,
            probability: '',
            quantity: "",
            aper: '',

          })
          setFileList([])

          sdepartment(true)
          stypesOf(1)

        }}>
          添加
        </Button>
      </div>

      {/* 表格数据 */}
      <ConfigProvider locale={zhCN}>
        <Table
          columns={columns}
          dataSource={sj}
          scroll={{ y: gao }}
          className="fromt"
          loading={jz}
          //   pagination={{ pageSize:10,total:math.allCounts,showTotal:total=> `共 ${math.allCounts} 条数据`,
          //   current:dqym
          // }}
          // onChange={(e,i,s,c)=>{usejz(true);  onChanges(e,i,s,c)}}
          // rowSelection={rowSelection}
          // pagination={{
          //   position: ["none", "none"],
          // }}
          pagination={false}
          rowKey="id"
        />
      </ConfigProvider>
    </div>
  );
  // }
}
// ReactDOM.render(

//   mountNode,
// );
