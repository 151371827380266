// import { } from 'antd';
import React, { Component, useEffect, useState } from 'react'
import zhCN from 'antd/lib/locale/zh_CN';
import moment from "moment";
import "moment/locale/zh-cn";
// moment.locale("zh-cn");
import { Menu, Switch, Form,Spin, DatePicker, Input, Button, ConfigProvider, message, Space, Radio, Breadcrumb, Checkbox, Row, Col,Upload,Modal} from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
// import "./details.css"
import { posterGet } from "../../../../api/api.js"; //获取活动详情
import { posterUpdate } from "../../../../api/api.js"; //保存基本配置
import { ActivityUpdate } from "../../../../api/api.js"; //保存基本配置
import { BrowserRouter, Link, Routes, Route,Outlet } from "react-router-dom" //引入路由模块
// import Na from "./configuration"
import { UploadOutlined } from '@ant-design/icons';
// import {  } from '@ant-design/icons';
import { useNavigate, useLocation } from "react-router-dom" //引入路由模块
// 引入 axios
import axios from 'axios';
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const formItemLayout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 20,
  },
};
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
    });
export default function Details() {
  const navigate = useNavigate();
  const location = useLocation()
  let totoken = window.localStorage.getItem("token");
  // console.log(totoken)
  if (totoken == null || totoken == "") {
    // alert(1)
    navigate("/")
  }
  const error = (a) => {
    message.error(a);
  };
  const success = (e) => {
    message.success(e);
  };

  let [load1, sload1] = useState(false)//基本设置按钮加载状态
  let [load, sload] = useState(false)//基本设置按钮加载状态
  let [tyt, styt] = useState(true)//答题次数限制的开关
  let [rant, srant] = useState('')//答题次数限制的开关
  
// ---------------------------------------------------------------------------------------------------
const [previewVisible, setPreviewVisible] = useState(false);
const [previewImage, setPreviewImage] = useState('');
const [previewTitle, setPreviewTitle] = useState('');
const [fileList, setFileList] = useState([

]);
const [fileList2, setFileList2] = useState([

]);
const handleCancel = () => setPreviewVisible(false);
const handlePreview = async (file) => {
  if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
  }

  setPreviewImage(file.url || file.preview);
  setPreviewVisible(true);
  setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
};

const handleChange = ({ fileList: newFileList }) => {
  setFileList(newFileList); console.log(newFileList);
  form1.setFieldsValue({
      file1: newFileList
  })
};
const handleChange2 = ({ fileList: newFileList }) => {
  setFileList2(newFileList); console.log(newFileList);
  form1.setFieldsValue({
      file2: newFileList
  })
};
const uploadButton = (
  <div>
      <UploadOutlined style={{

          fontSize: "30px",
          color: "rgb(0 99 246)",
      }} />
      <div
          style={{
              // marginTop: 8,
              fontSize: "10px",
              color: "#6a9eec",
              textAlign: "justify"
          }}
      >
          类型:.jpg,.png<br />
          大小:500kb以内<br />
          尺寸:638*976
      </div>
  </div>
);
const uploadButton2 = (
  <div>
      <UploadOutlined style={{

          fontSize: "30px",
          color: "rgb(0 99 246)",
      }} />
      <div
          style={{
              // marginTop: 8,
              fontSize: "10px",
              color: "#6a9eec",
              textAlign: "justify"
          }}
      >
          类型:.png<br />
          大小:20kb以内<br />
          尺寸:80*214
      </div>
  </div>
);
// -------------------------------------------------------------------------------------------------
  let [dataAnalysis,sdataAnalysis]=useState({})
    // 获取页面参数
    function hqcorid(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      // console.log(666666,arr)
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      // console.log("1234564,", obj[key]);
      return obj[key];
    }
    let pageid=hqcorid("keid",location.search)
let paname=hqcorid("name",location.search)
//使用生命周期的钩子

useEffect(()=>{
  // console.log("挂载完成,更新完成")
  acquire()
},[])
// 获取活动详情
function acquire(){
  if(pageid){
    sload(true)
    // id=(hqcorid("id",location.search)).replace(/@@/g,"%")
    
    posterGet(`id=${pageid}`).then((res)=>{
      // console.log(res)
      if(res.code==0){
      console.log(res)
      // if(res.data.lists==null){
      //   sload(false)
      //   return
      // }
      sdataAnalysis(res.data.lists)
      basicSetting(res.data.lists)
      }else{
        error('未知错误获取详情失败，刷新页面重试')
        return
      }
    })
  }else{
    error('无法获取活动id，请退出重试')
  }
  }
const [form1] = Form.useForm();

  // 基本设置赋值
function basicSetting(a){
  // return
  if(a!=null){
    form1.setFieldsValue({     
      issue:a.status==2?true:false,
      file1: [{ url: a.url, status: "done", }],

    })
    setFileList([{
      url: a.url,
      status: "done",
  }])

  styt(a.status==2?true:false)
  }else{
    styt(false)
  }

  
  sload(false)
  }
  // 基本设置题交按钮
  const onFinish1 = values => {
    console.log(values)
// return
    sload1(true)
    // console.log(formatDate(values.s_time[0]._d))
    let backgrounds
    if(values.file1){
      
      if (values.file1[0].response) {
        backgrounds = values.file1[0].response.data.url
    } else {
        backgrounds = values.file1[0].url
    }
    }
    console.log(123)
    let data={
      id:pageid,
      status:values.issue?2 : 1,
      url:backgrounds,
    }
    posterUpdate(data).then((res)=>{
      // console.log(res)
      if(res.code==0){
      console.log(res)
      // sdataAnalysis(res.data.info)

      success("保存成功")
      acquire()
    sload1(false)
      }else{
        sload1(false)
        error(res.message)
        return
      }
    })
  }
 

  return (
    
    <div className='bodys' style={{height:"100%"}}>
      {/* 基本设置 */}
      <Spin spinning={load} >
        <div style={{ position: "relative", top: "10px" }}>
          <ConfigProvider locale={zhCN}>
          <Form form={form1} labelAlign={'right'} name="from1"   {...formItemLayout} onValuesChange={(a, b) => {}} onFinish={onFinish1} autoComplete="off">
          <Form.Item name="issue" label="开启分享" valuePropName="checked" >
              <Switch onChange={(e) => {styt(e) }} />
            </Form.Item>
            {tyt &&
              <>

       
        <Form.Item label="分享海报" name="file1" rules={[
                    {
                        required: true,
                        validator: async (a, names) => {
                            console.log(names)
                            if (!fileList || fileList.length < 1) {
                                return Promise.reject(new Error('分享海报不能为空'));
                            } else {
                                return Promise.resolve()
                            }
                        },
                    },
                ]}
                >
                    <div>
                        <Upload
                            action={axios.defaults.baseURL+'/upload'}
                            listType="picture-card"
                            headers={{
                                'Authorization': window.localStorage.getItem("token") || '',
                                'X-Requested-With': null

                            }}

                            accept="image/*"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleChange}
                        >
                            {fileList.length >= 1 ? null : uploadButton}
                        </Upload>

                        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img
                                alt="example"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal>
                    </div>

        </Form.Item>
        <br/>
        </>}
            <Form.Item wrapperCol={{ ...formItemLayout.wrapperCol, offset: 2 }}>
              <Button type="primary" loading={load1} htmlType="submit">
                保存
              </Button>
            </Form.Item>
          </Form></ConfigProvider >
        </div>
  </Spin>
    </div>
   
  );
}

