import React, { Component,useEffect,useState} from 'react'
import { BrowserRouter, useNavigate,useLocation,Link, Routes, Route,Outlet } from "react-router-dom" //引入路由模块
import zhCN from 'antd/lib/locale/zh_CN';
import './configuration.css'
import { Divider, Table ,Select,Modal,Button,ConfigProvider, Popconfirm, message  } from 'antd';
import Question from "./ces"
import { getWarehouseLists } from "../../../api/api.js"; //获取列表
import {Import } from "../../../api/api.js"; //删除接口
const { Option } = Select;
export default function Na() { 
  
const navigate = useNavigate();
const location = useLocation()
const columns = [
  {
    title: `序号`,
    align:"center",
    width: 70,
    render:(e,x,i)=>(
      <div>
              {i+1!=10 && dqym-1==0 && (<div>{i+1}</div>) }
      {i+1!=10 && dqym-1!=0 && (<div>{dqym-1}{i+1}</div>) }
      {i+1==10 && (<div>{dqym}0</div>) }
      </div>
    )
    // dataIndex: 'name',
  },
  {
    title: '主题',
    align:"center",
    width: 100,
    dataIndex: 'theme',

    // width: 150,
  },
  {
    title:"类型",
    dataIndex: '',
    width: 100,
    filters: [
      {
        text: '单选',
        value: '1',
      },
      {
        text: '多选',
        value: '2',
      },
      {
        text: '判断',
        value: '3',
      },
    ],
    // filterDropdown:(e)=>{
    //   console.log(e)
    // },
    // onFilter: (value, record) =>{console.log(value,record)},

    // onFilter: (value, record) => {console.log(value)},

    // sorter: (a, b) => a.name.length - b.name.length,
    // sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
    ellipsis: true,


    render:(e)=>(
      <div>{e.type==1 ? '单选' : e.type==2 ? "多选" : "判断"}</div>
    )
  },
  {
    title: '问题',
    // align:"center",

    dataIndex: 'title',
    // width: 150,
  },
  {
    title: '编辑',
    align:"center",
    dataIndex: '',
    width: 200,
    key: "name",
    // sorter: true,
    render: (a,b,c) => (
      <div size="middle" >
          <Button type="primary"  ghost onClick={()=>{stc(true);stcnr(a)}}>查看选项</Button>&nbsp;
          {/* <Popconfirm
    title={`确认删除吗?`}
    onConfirm={()=>{
      warehouseDelete(`id=${a.id}`).then((res)=>{
        console.log(res)
        if(res.code==0){
          refresh()
          success("删除成功")
        }
      })
    }}
    onCancel={()=>{console.log("取消")}}
    okText="确认"
    cancelText="取消"
  >
          <Button type="primary" danger ghost onClick={()=>{console.log(b)}} >删除</Button>
          </Popconfirm> */}
      </div>
    ),
    
  },

];//定义表格的title

const success = (e) => {
  message.success(e);
};
let [gao,sgao]=useState(500)
let [gaos,sgaos]=useState(500)
window.onresize = function(){
    let boss=document.getElementsByClassName("kgd")[0].offsetHeight-160
    let bosss=document.getElementsByClassName("kgd")[0].offsetHeight-270
    sgao(boss)
    sgaos(bosss)
}//设置表格的高度

// 刷新页面
function  refresh() {

hqlb(dqym)
usejz(true);
ssel([])
}   
    // 获取页面参数
    function hqcorid(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      // console.log(666666,arr)
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      // console.log("1234564,", obj[key]);
      return obj[key];
    }
    let pageid=hqcorid("keid",location.search)
let paname=hqcorid("name",location.search)

//使用生命周期的钩子
// console.log(useEffect)
useEffect(()=>{
    // console.log("挂载完成,更新完成")

    window.sessionStorage.clear(); //清除缓存
   setTimeout(()=>{
        let boss=document.getElementsByClassName("kgd")[0].offsetHeight-160
        let bosss=document.getElementsByClassName("kgd")[0].offsetHeight-270
        sgao(boss)
        sgaos(bosss)
        
        },1)

        // alert(1)
    hqlb(1)
    // alert
    return function(){
      // console.log("即将卸载")
      window.sessionStorage.clear(); //清除缓存

      }
      
  },[])


 let [sj,usesj]=useState([])//所有数据
 let [jz,usejz]=useState(true) //加载状态
 let [ys,uys]=useState([1]) //页数是否存储过


let [tc,stc]=useState(false) //  题目预览弹窗显示或隐藏
let [tcnr,stcnr]=useState([]) //  题目预览弹窗内容

let [math,smath]=useState({})//页数及数量


function hqlb(i){

  //  return
  // 
      // if(i==1){
      //   usesj(xsj)  
      // }else{
      //   usesj(xsjs)  
  
      // }
      getWarehouseLists(`page=${i}&pageSize=10&type=${window.sessionStorage.getItem("leix")==null ? "" : window.sessionStorage.getItem("leix")}&theme=${window.sessionStorage.getItem("zhut")==null ? "" : window.sessionStorage.getItem("zhut")}`).then((res)=>{
        // console.log(res)
        usesj(res.data.list)  
        smath(res.data)
        usejz(false)
        if(res.data.list.length==0 && i!=1){
          hqlb(1)
        }
      })
  // leix=leix
  
  // 必须用深拷贝来改变赋值
  // let sjs = JSON.parse(JSON.stringify(sj))
  // console.log(sj)
  // usesj(sjs)  
  // setTimeout(()=>{
  // usejz(false)
  // },1000)
  
  }

let [dqym,sdqym]=useState(1) //当前页面的索引

// 筛选或分页的时候触发
function onChanges(e,i,s,c){
  // console.log(e,i,s,c)
  if(c.action=="paginate"){
    sdqym(e.current)
// let ysj=[1]
ssel([])

// if(ys.indexOf(e.current)==-1){
  hqlb(e.current)
//   ys.push(e.current)
//   uys(ys)
//   console.log(ys)
// }else{
//   usejz(false)
// }
// console.log(666,e.current,i)
  }else{
    // console.log("筛选")
    // console.log(i)
    if(i[2]==null){
      window.sessionStorage.setItem("leix", "");
      
    }else{
// leix=i[2].toString()
window.sessionStorage.setItem("leix", i[2].toString());

    }
    sdqym(1)
    hqlb(1)
    ssel([])
    // console.log(66666,i[2].toString())
  }
}
// 排序
function handleChange(value) {
  // console.log(leix)
  // console.log(`selected ${value}`);
  if(value!=undefined){
    window.sessionStorage.setItem("zhut", value);
  }else{
    window.sessionStorage.setItem("zhut", "");

  }

  

      sdqym(1)
      hqlb(1)
      ssel([])
      usejz(true); 
      
}
let [sel,ssel]=useState([]) //选中的数据

let [scjz,sscjz]=useState(false) //选中的数据
// let []=useState()
//多选是的时候触发
function onSelectChange(i,w){
  // console.log(i,w)
  ssel(i)


}
const error = (a) => {
  message.error(a);
};
// 点击确认按钮
function start(){
    // console.log(sel)
    if(sel.length==0){
      error("未选中")
      return
    }
    // console.log(sel.toString())
  // return
  usejz(true)
  Import(`id=${sel.toString()}&activity_id=${pageid}`).then((res)=>{
        // console.log(res)
        if(res.code==0){
          // refresh()
                    usejz(false)
          success("导入成功")
          // setTimeout(()=>{
  
          navigate(`/background/details/configuration?keid=${pageid}&name=${paname}`,{state:"true"})
          // },500)
        }else{
          error("未知原因导入失败,请检查网络，稍后重试")
          usejz(false)
        }
      })

sscjz(true)
  setTimeout(() => {
    sscjz(false)

  }, 1000);
}



const rowSelection = {
    selectedRowKeys:sel,
    onChange:onSelectChange,
    getCheckboxProps:(e)=>({
      disabled:e.zt==="no"
    })
  }; 
// const hasSelected = sel.length > 0;
  return (
    <Modal
    title={"题库导入"}
    style={{ top: '10%' }}
    width={"80%"}
    height={"80%"}
    className={"kgd"}
    visible={true}
    closable={false}
    footer={[<div key={1} style={{padding:0}}>
      <Button type="primary" style={{  }} onClick={()=>{start();}}>
      确认
    </Button>  
      <Button type="primary" style={{  }} onClick={()=>{navigate(`/background/details/configuration?keid=${pageid}&name=${paname}`,{replace:true});}}>
      关闭
    </Button>  
    </div>]}
>          <Select placeholder="全部主题" allowClear style={{ width: 120,position:"relative",top:"-12px"}} onChange={(w)=>{handleChange(w);}}>
            {math.theme && math.theme.map((b,i)=>{return(
         <Option key={i} value={`${b.theme}`}>{b.theme}</Option>

            )})}
          </Select>  
      {/* 弹窗 */}
        <Modal
          title={tcnr.title}
          style={{ top: '10%' }}
          visible={tc}
          closable={false}
          footer={[<div key={1} style={{padding:0}}>
            <Button type="primary" style={{  }} onClick={() => stc(false)}>
            关闭
          </Button>  
          </div>]}
        >
    

                {tcnr.option_a!=null && <div  style={{marginBottom:"6px"}}>A:{tcnr.option_a}</div>} 
                {tcnr.option_b!=null && <div  style={{marginBottom:"6px"}}>B:{tcnr.option_b}</div>}       
                {tcnr.option_c!=null && <div  style={{marginBottom:"6px"}}>C:{tcnr.option_c}</div>}       
                {tcnr.option_d!=null && <div  style={{marginBottom:"6px"}}>D:{tcnr.option_d}</div>}       
                {tcnr.option_e!=null && <div  style={{marginBottom:"6px"}}>E:{tcnr.option_e}</div>}       
  

        <div style={{marginTop:"18px",fontWeight:"bold"}}>正确答案：{tcnr.true}</div>
        <div style={{marginTop:"18px"}}><span style={{fontWeight:"bold"}}>解析：</span>{tcnr.analysis}</div>
        </Modal>
{sel.length>0 && 
        <span style={{ marginLeft: 8,position:"relative",top:"-5px" }}>
           { `选中了${sel.length}条`}
          </span>
          }
  <div style={{ height:`${gao}px`,overflowY:"auto" }} >

        {/* 表格数据 */}
<ConfigProvider  locale={zhCN}>
    <Table 
    columns={columns} 
    dataSource={sj} 
    scroll={{ y:gaos}}  
    className="fromt"
    loading={jz}
    pagination={{ pageSize:10,total:math.allCounts,showTotal:total=> `共 ${math.allCounts} 条数据`,
    current:dqym
  }}
    onChange={(e,i,s,c)=>{usejz(true);  onChanges(e,i,s,c)}}
    rowSelection={rowSelection} 

    rowKey="id"
    />
    </ConfigProvider >







    </div>
     </Modal>
      )
}

