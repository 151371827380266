import React, { Component,useEffect,useState} from 'react'
import { BrowserRouter, useNavigate,useLocation,Link, Routes, Route,Outlet } from "react-router-dom" //引入路由模块
import zhCN from 'antd/lib/locale/zh_CN';
import './configuration.css'
import { Divider,Select, Table,Breadcrumb ,Modal,Button,ConfigProvider, Popconfirm, message  } from 'antd';
import Question from "./ces"
import axios from 'axios';
import { getRankList } from "../../../api/api.js"; //获取排行榜列表
import { getDepartmentLists } from "../../../api/api.js"; //获取部门列表


import { clearRank } from "../../../api/api.js"; //删除接口
import { getData } from "../../../api/api.js"; //活动数据
import { exports } from "../../../api/api.js"; //导出数据
import { userLogs } from "../../../api/api.js"; // 数据看版答题详情接口

import userEvent from '@testing-library/user-event';
const { Option } = Select;
export default function Na() { 
  
const navigate = useNavigate();
let totoken = window.localStorage.getItem("token");
// console.log(totoken)
if(totoken==null || totoken==""){
  // alert(1)
  navigate("/")
}

const location = useLocation()
let [coresum,scoresum]=useState(1) //当前类型

let [details_pop_up,sdetails_pop_up]=useState(false) //是否显示弹窗
let [loading_state,sloading_state]=useState(false) //加载状态
let [detailed_data,sdetailed_data]=useState(false) //答题详情数据
let [detailed_current,sdetailed_current]=useState(1) //答题详情数据

let [epartmentList,separtmentList]=useState([]) //答题详情数据

let [department,sdepartment]=useState('') //答题详情数据



const columns = [
  {
    title: `序号`,
    align:"center",
    width: 60,
    render:(e,x,i)=>(
      <div>
              {i+1!=10 && dqym-1==0 && (<div>{i+1}</div>) }
      {i+1!=10 && dqym-1!=0 && (<div>{dqym-1}{i+1}</div>) }
      {i+1==10 && (<div>{dqym}0</div>) }
      </div>
    )
    // dataIndex: 'name',
  },
  {
    title: '公司',
    align:"center",

    // dataIndex: 'department_id',
    // width: 150,
    render:(e,x,i)=>(
      <div>{!x.company_id||x.company_id==""||x.company_id=="undefined" ? "无" : x.company_id}</div>
    )
    
  },
  {
    title: '部门',
    align:"center",

    // dataIndex: 'department_id',
    // width: 150,
    render:(e,x,i)=>(
      <div>{x.department_id=="" ? "无" : x.department_id}</div>
    )
    
  },
  {
    title: '姓名',
    align:"center",

    // dataIndex: 'username',
    // width: 150,
    render:(e,x,i)=>(
      <div>{x.user.username}</div>
    )
  },
  {
    title: '分数',
    align:"center",
    sorter:true,
    dataIndex: 'score',
    // width: 300,
  },
  {
    title: '答题次数',
    align:"center",
    // sorter:true,
    dataIndex: 'num',
    // width: 300,
  },
  {
    title: '答题时长(秒)',
    align:"center",
    // sorter:true,
    dataIndex: 'time',
    // width: 300,
  },
  {
    title: '可抽奖次数',
    align:"center",
    // sorter:true,
    dataIndex: 'draw_count',
    // width: 300,
  },
  {
    title: '实际抽奖次数',
    align:"center",
    // sorter:true,
    dataIndex: 'draw_log',
    // width: 300,
  },
  {
    title: '答题时间',
    align:"center",
    // sorter:true,
    dataIndex: 'updated_at',
    // width: 300,
  },
  

];//定义表格的title
const columns2 = [
  {
    title: `序号`,
    align:"center",
    width: 60,
    render:(e,x,i)=>(
      <div>
              {i+1!=10 && dqym-1==0 && (<div>{i+1}</div>) }
      {i+1!=10 && dqym-1!=0 && (<div>{dqym-1}{i+1}</div>) }
      {i+1==10 && (<div>{dqym}0</div>) }
      </div>
    )
    // dataIndex: 'name',
  },
  {
    title: '公司',
    align:"center",

    // dataIndex: 'department_id',
    // width: 150,
    render:(e,x,i)=>(
      <div>{!x.user.company_id||x.user.company_id==""||x.user.company_id=="undefined" ? "无" : x.user.company_id}</div>
    )
    
  },
  {
    title: '部门',
    align:"center",

    // dataIndex: 'department_id',
    // width: 150,
    render:(e,x,i)=>(
      <div>{x.user.department_id=="" ? "无" : x.user.department_id}</div>
    )
  },
  {
    title: '姓名',
    align:"center",

    // dataIndex: 'username',
    // width: 150,
    render:(e,x,i)=>(
      <div>{x.user.username}</div>
    )
  },
  {
    title: '累计分数',
    align:"center",
    sorter:true,
    dataIndex: 'total_score',
    // width: 300,
  },
  {
    title: '答题次数',
    align:"center",
    // sorter:true,
    dataIndex: 'num',
    // width: 300,
  },
  {
    title: '答题总时长(秒)',
    align:"center",
    // sorter:true,
    dataIndex: 'total_time',
    // width: 300,
  },
  {
    title: '可抽奖次数',
    align:"center",
    // sorter:true,
    dataIndex: 'draw_count',
    // width: 300,
  },
  {
    title: '实际抽奖次数',
    align:"center",
    // sorter:true,
    dataIndex: 'draw_log',
    // width: 300,
  },
  {
    title: '详情',
    align:"center",
    // width: 400,

    // sorter:true,
    // dataIndex: 'score',
    render: (a,b,c) => (
      <div size="middle" > 
      
          <Button style={{marginBottom:4}} type="primary"  ghost onClick={()=>{
            details_user(b)
                      // sclick(b)
                      // stimes(true)
                      // snumbe(b.count)
            
          }}>答题详情</Button>
      </div>
    ),
  },
  // {
  //   title: '答题时间',
  //   align:"center",
  //   // sorter:true,
  //   dataIndex: 'updated_at',
  //   // width: 300,
  // },

];//定义表格的title


const success = (e) => {
  message.success(e);
};

  let [gao,sgao]=useState(500)
    window.onresize = function(){
        let boss=document.getElementsByClassName("body")[0].offsetHeight-212
        sgao(boss)
    }//设置表格的高度

// 刷新页面
function  refresh() {

hqlb(dqym)
usejz(true);
ssel([])
}   


//使用生命周期的钩子
// console.log(useEffect)
useEffect(()=>{
    // console.log("挂载完成,更新完成")
    window.sessionStorage.clear(); //清除缓存
    setTimeout(()=>{
        let boss=document.getElementsByClassName("body")[0].offsetHeight-212
    sgao(boss)},1)
    hqlb(1)
    GetData()
    epartmentLists()
return function(){
  // console.log("即将卸载")
  window.sessionStorage.clear(); //清除缓存

  }
// console.log(location) 
  },[])


    // 获取页面参数
    function hqcorid(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      // console.log(666666,arr)
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      // console.log("1234564,", obj[key]);
      return obj[key];
    }
    let pageid=hqcorid("keid",location.search)
let paname=hqcorid("name",location.search)

 let [sj,usesj]=useState([])//所有数据
 let [jz,usejz]=useState(true) //加载状态
//  let [ys,uys]=useState([1]) //页数是否存储过


// let [tc,stc]=useState(false) //  题目预览弹窗显示或隐藏
// let [tcnr,stcnr]=useState([]) //  题目预览弹窗内容

let [math,smath]=useState({})//页数及数量
let [getDepartmen,sgetDepartmen]=useState('所有部门')//页数及数量


let sjx=1
function hqlb(i){
  // window.sessionStorage.setItem("ranking", 1);
  scoresum(window.sessionStorage.getItem("ranking")==null ? 1 : window.sessionStorage.getItem("ranking"))
//  return
// 
    // if(i==1){
    //   usesj(xsj)  
    // }else{
    //   usesj(xsjs)  

    // }

    getRankList(`page=${i}&pageSize=10&sort=${sjx}&activity_id=${pageid}&score=${window.sessionStorage.getItem("ranking")==null ? 1 : window.sessionStorage.getItem("ranking")}&department=${window.sessionStorage.getItem("tments") ? window.sessionStorage.getItem("tments") : ''}`).then((res)=>{
      console.log(res)
      if((res.data.score).toString()==(window.sessionStorage.getItem("ranking")==null ? 1 : window.sessionStorage.getItem("ranking")).toString()){
        usesj(res.data.lists)  
        smath(res.data)
        usejz(false)



        if( i!=1  && res.data.lists.length==0){
          hqlb(1)
        }
      }

    })

// 必须用深拷贝来改变赋值
// let sjs = JSON.parse(JSON.stringify(sj))
// console.log(sj)
// usesj(sjs)  
// setTimeout(()=>{
// usejz(false)
// },1000)

}

let [dqym,sdqym]=useState(1) //当前页面的索引

let [shuj,sshuj]=useState({}) //当前页面的索引

// 活动的数据信息
function GetData(){
  getData(`activity_id=${pageid}`).then((res)=>{
    // console.log(res)
    sshuj(res.data)
  })
}

// 筛选或分页的时候触发
function onChanges(e,i,s,c){
  console.log(e,i,s,c)
  if(c.action=="paginate"){
    sdqym(e.current)
// let ysj=[1]
ssel([])
if(s.order=="ascend"){
  // console.log("升序")
  sjx=2
 }else if(s.order=="descend"){
  sjx=1 
  // console.log("降序")
  }else{
    sjx=1
    // console.log("qx")
  }
// if(ys.indexOf(e.current)==-1){
  hqlb(e.current)
  
//   ys.push(e.current)
//   uys(ys)
//   console.log(ys)
// }else{
//   usejz(false)
// }
// console.log(666,e.current,i)
  }else if(s.field=="score" || s.field=="total_score"){
      // console.log("升降序",s)
        if(s.order=="ascend"){
          // console.log("升序")
          sjx=2
         }else if(s.order=="descend"){
          sjx=1 
          // console.log("降序")
          }else{
            sjx=1
            // console.log("qx")
          }
          hqlb(1)
          sdqym(1)
    // usejz(false)
    // console.log(66666,sj)
  }
}
let [sel,ssel]=useState([]) //选中的数据

let [scjz,sscjz]=useState(false) //选中的数据
// let []=useState()
//多选是的时候触发
function onSelectChange(i,w){
  // console.log(i,w)
  ssel(i)


}

// 点击删除按钮
function start(){

  clearRank({}).then((res)=>{
        // console.log(res)
        if(res.code==0){
          refresh()
          success("清空成功")
          
        }
      })
}
// 排序
function handleChange(value) {
  console.log(value)
  // // console.log(`selected ${value}`);
  if(value!=undefined){
    window.sessionStorage.setItem("ranking", value);
    scoresum(value)
  }else{
    window.sessionStorage.setItem("ranking", 1);
    scoresum(1)
  }

  

      sdqym(1)
      hqlb(1)
      ssel([])
      usesj([])
      usejz(true); 
      
}

// 选择相应的部门排序
function handleChanges(value) {
  console.log(value)
  // sdepartment()//当前部门名称
  // // console.log(`selected ${value}`);
  // return
  if(value!=undefined){
    window.sessionStorage.setItem("tments", value);
    sdepartment(value)
  }else{
    window.sessionStorage.setItem("tments", '');
    sdepartment('')
  }

  

      sdqym(1)
      hqlb(1)
      ssel([])
      usesj([])
      usejz(true); 
      
}

const columns3 = [
  {
    title: `序号`,
    align:"center",
    width: 60,
    
    dataIndex: 'xh',
  },
  {
    title: '姓名',
    dataIndex: 'username',
    // width: 150,
    align:"center",
  },
  // {
  //   title: '部门',
  //   dataIndex: 'department_id',
  //   // width: 150,
  //   align:"center",
  // },
  {
    title: '公司',
    align:"center",

    // dataIndex: 'department_id',
    // width: 150,
    render:(e,x,i)=>(
      <div>{!x.company_id||x.company_id==""||x.company_id=="undefined" ? "无" : x.company_id}</div>
    )
  },
  {
    title: '部门',
    align:"center",

    // dataIndex: 'department_id',
    // width: 150,
    render:(e,x,i)=>(
      <div>{x.department_id=="" ? "无" : x.department_id}</div>
    )
  },
  {
    title: '分数',
    align:"center",
    dataIndex: 'score',
  },
  {
    title: '答题用时(秒)',
    align:"center",
    width: 120,
    dataIndex: 'time',
  },
  {
    title: '答题时间',
    dataIndex: 'updated_at',
    // width: 150,
    align:"center",
  },
];

// 获取用户的答题详情
function details_user(value) {
  sloading_state(true)
  sdetails_pop_up(true)
  // console.log(value)
  userLogs(`user_id=${value.user_id}`).then((res)=>{
    // console.log(res)
   
    let data=[]
    for(var i=0;i<res.data.lists.length;i++){
      res.data.lists[i].xh=i+1
      data.push(res.data.lists[i])
    }
    sdetailed_data(data)
    // console.log(data);
    sloading_state(false)
    // sshuj(res.data)
  })
  // let [details_pop_up,sdetails_pop_up]=useState(false) //是否显示弹窗
  // let [loading_state,sloading_state]=useState(false) //加载状态
  // let [detailed_data,sdetailed_data]=useState(false) //答题详情数据
}
// 获取部门列表
function epartmentLists(){
  getDepartmentLists(`activity_id=${pageid}`).then((res)=>{
    console.log(res);
    let ceshi
    if(res.data.type==1){
      ceshi=JSON.stringify(res.data.department)
      sgetDepartmen('所有部门')
    }else{
      ceshi=JSON.stringify(res.data.company_lists)
      sgetDepartmen('所有公司')
    }
    
    let cess=JSON.parse(ceshi)
    let ce=[]
    for(var i=0;i<cess.length;i++){
      ce.push({value:cess[i].name,label:cess[i].name})
    }
    // ce.push({value:'其他',label:'其他'})
    // console.log(ce);
    separtmentList(ce)
  })
}



const hasSelected = sel.length > 0;
  return (
      <div className='body'>
          {/* 活动规则弹窗3 */}
          <Modal
          width='760px'
          className='modal_foot'
          title={`答题详情`}
          style={{ top: '10%' }}
          visible={details_pop_up}
          closable={true}
          onCancel={()=>{
            sdetails_pop_up(false) 
            sdetailed_data([])
            sdetailed_current(1)
          }}
          footer={[<div key={1} style={{padding:0}} className='footerb'></div>]}
        >
           <Table
    columns={columns3}
    dataSource={detailed_data}
    loading={loading_state}
    pagination={{
      pageSize: 10,
      current:detailed_current,
      showTotal:total=> `共 ${detailed_data.length} 条数据`,
      onChange:(a)=>{
        sdetailed_current(a)
      },
    }}
    scroll={{
      y: 300,
    }}
    rowKey={(a)=>{
      return a.xh
    }}
  />
        
        </Modal>

 {/* <Breadcrumb separator=">" style={{position:"relative",top:"-10px"}}>
    <Breadcrumb.Item>
      <a  onClick={()=>{
        navigate(`/background/activity`)
      }}>活动列表</a>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      <a 
      style={{display:"inline-block", maxWidth:"400px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:'hidden',position:"relative",top:"6px"}}
      >{paname}</a>
    </Breadcrumb.Item>
    
  </Breadcrumb> */}
      {/* 顶部的按钮 */}
          <div style={{display:"flex",justifyContent:"space-around", marginBottom: 12,marginTop: 0,position:"relative",height:"50px",backgroundColor:"#E6EFFF" }}>
          <div style={{justifyContent:"center", alignItems:"center",display:"flex", flexDirection:"column",}}>
            <div>今日参与人数</div>
            <div style={{color:'#3472FF', fontSize:"17px",fontWeight:"bold"}}>{shuj.today_users}</div>
          </div>
          <div style={{justifyContent:"center", alignItems:"center",display:"flex", flexDirection:"column",}}>
            <div>今日参与次数</div>
            <div style={{color:'#3472FF', fontSize:"17px",fontWeight:"bold"}}>{shuj.today_num}</div>
          </div>
          <div style={{justifyContent:"center", alignItems:"center",display:"flex", flexDirection:"column",}}>
            <div>总参与人数</div>
            <div style={{color:'#3472FF', fontSize:"17px",fontWeight:"bold"}}>{shuj.all_users}</div>
          </div>
          <div style={{justifyContent:"center", alignItems:"center",display:"flex", flexDirection:"column",}}>
            <div>总参与次数</div>
            <div style={{color:'#3472FF', fontSize:"17px",fontWeight:"bold"}}>{shuj.all_num}</div>
          </div>
         
{/* 
          <Button type="primary"  style={{ marginLeft: 0 }} onClick={()=>{ start()}}>
            清空排行榜
          </Button>      */}



{/* <h2 style={{position:"absolute",right:0,top:0,fontWeight:"bold",color:""}}>数据看板</h2> */}
          
        </div>
        <Select  value={coresum+''} placeholder="排行类型"  style={{ width: 130, marginLeft:0 }} onChange={(w)=>{handleChange(w);}}>
          {/* <Option value="qb">全部</Option> */}

             <Option value="1">单次答题排行</Option>
            <Option value="2">累计答题排行</Option>
            {/*<Option value="3">数据保护</Option>
            <Option value="4">征信</Option> */} 
            {/* {math.theme.map((b,i)=>
            <Option value="1">财富</Option>
            )} */}
            {/* {math.theme && math.theme.map((b,i)=>{return(
         <Option key={i} value={`${b.theme}`}>{b.theme}</Option>

            )})} */}
          </Select>

          <ConfigProvider  locale={zhCN}>
          <Select
          placeholder={getDepartmen} 
          allowClear
          
          style={{ width: 150, marginLeft: 10 }}
    showSearch
    // placeholder="Select a person"
    optionFilterProp="children"
    // onChange={onChange}
    onChange={(w)=>{handleChanges(w);}}
    // onSearch={onSearch}
    filterOption={(input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    }
    options={epartmentList}
  />
</ConfigProvider>
          {coresum!=2 &&
          <>
        <Button type="primary" style={{ marginLeft: 50 }} onClick={()=>{

const linkNode = document.createElement('a');
linkNode.download = 'fileName'; //a标签的download属性规定下载文件的名称
linkNode.style.display = 'none';
linkNode.href =`${axios.defaults.baseURL}/userExport?activity_id=${pageid}&type=1&department=${department?(department!=undefined&&department!=''?`${department}`:''):''}`
document.body.appendChild(linkNode);
linkNode.click();  //模拟在按钮上的一次鼠标单击
URL.revokeObjectURL(linkNode.href); // 释放URL 对象
document.body.removeChild(linkNode);
}}>
  导出&nbsp;
  <span  style={{color:'yellow'}}>
    {department?(department!=undefined&&department!=''?`(${department})`:'全部'):'全部'}
  </span>
 
&nbsp;单次答题排行数据
</Button>  
          </>
          }
       {coresum==2 &&
          <>
                    <Button type="primary" style={{ marginLeft: 50 }} onClick={()=>{
          const linkNode = document.createElement('a');
          linkNode.download = 'fileName'; //a标签的download属性规定下载文件的名称
          linkNode.style.display = 'none';
          linkNode.href =`${axios.defaults.baseURL}/userAllScoreExport?activity_id=${pageid}&type=1&department=${department?(department!=undefined&&department!=''?`${department}`:''):''}`
          document.body.appendChild(linkNode);
          linkNode.click();  //模拟在按钮上的一次鼠标单击
          URL.revokeObjectURL(linkNode.href); // 释放URL 对象
          document.body.removeChild(linkNode);
          }}>
            导出&nbsp;
  <span  style={{color:'yellow'}}>
    {department?(department!=undefined&&department!=''?`(${department})`:'全部'):'全部'}
  </span>
 
&nbsp;累计答题排行数据
          </Button>  
          </>
        }



        <Button type="primary" style={{ marginLeft: 10 }} onClick={()=>{

const linkNode = document.createElement('a');
linkNode.download = 'fileName'; //a标签的download属性规定下载文件的名称
linkNode.style.display = 'none';
linkNode.href =`${axios.defaults.baseURL}/export?activity_id=${pageid}&type=2`
document.body.appendChild(linkNode);
linkNode.click();  //模拟在按钮上的一次鼠标单击
URL.revokeObjectURL(linkNode.href); // 释放URL 对象
document.body.removeChild(linkNode);
}}>
            导出题目正确率
          </Button>   


{coresum!=2 && 
  <ConfigProvider  locale={zhCN}>
    <Table 
    columns={columns} 
    dataSource={sj} 
    scroll={{ y:gao}}  
    className="fromt"
    loading={jz}
    pagination={{ pageSize:10,total:math.allCounts,showTotal:total=> `共 ${math.allCounts} 条数据`,
    current:dqym
  }}
    onChange={(e,i,s,c)=>{usejz(true);  onChanges(e,i,s,c)}}
    // rowSelection={rowSelection} 

    rowKey={(a)=>{
      return a.id
    }}
    />
    </ConfigProvider >
}
{coresum==2 && 
  <ConfigProvider  locale={zhCN}>
    <Table 
    columns={columns2} 
    dataSource={sj} 
    scroll={{ y:gao}}  
    className="fromt"
    loading={jz}
    pagination={{ pageSize:10,total:math.allCounts,showTotal:total=> `共 ${math.allCounts} 条数据`,
    current:dqym
  }}
    onChange={(e,i,s,c)=>{usejz(true);  onChanges(e,i,s,c)}}
    // rowSelection={rowSelection} 

    rowKey={(a)=>{
      return a.user_id+'z'
    }}
    />
    </ConfigProvider >
}
        {/* 表格数据 */}


    <div style={{position:'absolute',bottom:'0px',display:'inline-block',color:'red',marginLeft:'10px',fontSize:"13px",}}>注意：如果 高级设置 中开启了（答题时间统计），用户列表将根据分数和时间来排列。分数越高，时间越短，排在越前面。</div>






    </div>
      )
}

// ReactDOM.render(
  
//   mountNode,
// );

