import React, { Component,useEffect,useState} from 'react'
import { BrowserRouter, useNavigate,useLocation,Link, Routes, Route,Outlet } from "react-router-dom" //引入路由模块
import zhCN from 'antd/lib/locale/zh_CN';
import './configuration.css'
import { Divider, Table ,Modal,Button,ConfigProvider,Breadcrumb, Popconfirm, message ,Input } from 'antd';
import Question from "./question"
import { getQuestionsLists } from "../../../api/api.js"; //获取列表
import { questionDelete } from "../../../api/api.js"; //删除接口
import { questionsScoreUpdate } from "../../../api/api.js"; //修改题目的分数接口
import { getExplain} from "../../../api/api.js"; //获取活动规则
import { explainUpdate } from "../../../api/api.js"; //修改活动规则


const { TextArea } = Input;
export default function Na() { 
  
const navigate = useNavigate();

let totoken = window.localStorage.getItem("token");
// console.log(totoken)
if(totoken==null || totoken==""){
  // alert(1)
  navigate("/")
}


const location = useLocation()
const columns = [
  {
    title: `序号`,
    align:"center",
    width: 60,
    render:(e,x,i)=>(
    <div>
      {i+1!=10 && dqym-1==0 && (<div>{i+1}</div>) }
      {i+1!=10 && dqym-1!=0 && (<div>{dqym-1}{i+1}</div>) }
      {i+1==10 && (<div>{dqym}0</div>) }

    </div>
     
    )
    // dataIndex: 'name',
  },
  {
    title:"类型",
    dataIndex: '',
    width: 100,
    align:"center",

    // filters: [
    //   {
    //     text: '单选',
    //     value: 'dx',
    //   },
    //   {
    //     text: '多选',
    //     value: 'dxx',
    //   },
    //   {
    //     text: '判断',
    //     value: 'pd',
    //   },
    // ],
    // // filterDropdown:(e)=>{
    // //   console.log(e)
    // // },
    // // onFilter: (value, record) =>{console.log(value,record)},

    // // onFilter: (value, record) => {console.log(value)},

    // // sorter: (a, b) => a.name.length - b.name.length,
    // // sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
    // ellipsis: true,


    render:(e)=>(
      <div>{e.type==1 ? '单选' : e.type==2 ? "多选" : "判断"}</div>
    )
  },
  {
    title: '问题',
    // align:"center",

    dataIndex: 'title',
    // width: 150,
  },

  {
    title: '分值',
    align:"center",
    // editable: true,
    dataIndex: 'score',
    width: 60,
    // shouldCellUpdate:(a,b)=>{
    //   console.log(a,b)
    // }
    render: (a,b,c) => (
      <div>
      <Button type="link" onClick={()=>{stcf(true);stcnr(b);sgrade(b.score)}}>{b.score}</Button>
      </div>)
  }, 
   {
    title: '正确率',
    align:"center",
    width: 80,

    dataIndex: 'probability',
    // width: 150,
    render: (a,b,c) => (
      <div>
      {b.probability}%
      </div>)
  },
  {
    title: '编辑',
    align:"center",
    dataIndex: '',
    width:300,
    key: "name",
    // sorter: true,
    render: (a,b,c) => (
      <div size="middle" >
          {/* <Button type="primary"  ghost onClick={()=>{stc(true);stcnr(a)}}>改分</Button>&nbsp; */}

          <Button type="primary"  ghost onClick={()=>{stc(true);stcnr(a)}}>查看选项</Button>&nbsp;
          <Button type="primary"  ghost onClick={()=>{
            // console.log(JSON.stringify(a).replace(/%/g,"$"))
            // console.log(paname)
            // return
            navigate(`/background/details/compile?id=${a.id}&type=${a.type}&keid=${pageid}&name=${paname}`);
            
            }}>编辑</Button>&nbsp;
          <Popconfirm
    title={`确认删除吗?`}
    onConfirm={()=>{
      questionDelete(`id=${a.id}&activity_id=${pageid}`).then((res)=>{
        // console.log(res)
        if(res.code==0){
          refresh()
          success("删除成功")
        }
      })
    }}
    onCancel={()=>{}}
    okText="确认"
    cancelText="取消"
  >
          <Button type="primary" danger ghost onClick={()=>{}} >删除</Button>
          </Popconfirm>
      </div>
    ),
    
  },

];//定义表格的title

const success = (e) => {
  message.success(e);
};
const error = (a) => {
  message.error(a);
};

  let [gao,sgao]=useState(500)
    window.onresize = function(){
        let boss=document.getElementsByClassName("body")[0].offsetHeight-165
        sgao(boss)
    }//设置表格的高度

// 刷新页面
function  refresh() {

hqlb(dqym)
usejz(true);
ssel([])
}   
    // 获取页面参数
   function hqcorid(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      // console.log(666666,arr)
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      // console.log("1234564,", obj[key]);
      return obj[key];
    }


    // let [pageid,spageid]=useState('')//页面id
    let [pagename,spagename]=useState("")//页面name

//使用生命周期的钩子
// console.log(useEffect)
let pageid=hqcorid("keid",location.search)
let paname=hqcorid("name",location.search)
useEffect(()=>{
    // console.log("挂载完成,更新完成")
    spagename(hqcorid("name",location.search))

// console.log(wa,name)
    setTimeout(()=>{
        let boss=document.getElementsByClassName("body")[0].offsetHeight-165
    sgao(boss)},1)
    hqlb(1)
    // hdhq()

    
    // usejz(true)
// console.log(location) 
if(location.state=="true"){
  location.state="false"
  usejz(true)
// console.log(location) 
}


  },[location])
  // 

  // 获取活动规则
  function hdhq() {
    getExplain().then((res)=>{
      // console.log(res)
      shdnr(res.data.list)
    })
  }

 let [sj,usesj]=useState([])//所有数据
 let [jz,usejz]=useState(true) //加载状态
 let [ys,uys]=useState([1]) //页数是否存储过

  
let [hdgz,shdgz]=useState(false) //  活动规则弹窗
let [hdnr,shdnr]=useState("") //  活动规则内容

let [tc,stc]=useState(false) //  题目预览弹窗显示或隐藏
let [tcf,stcf]=useState(false) //改分弹窗的显示和隐藏  
let [grade,sgrade]=useState([]) //  改分时候的默认分数


let [tcnr,stcnr]=useState([]) //  题目预览弹窗内容


let [math,smath]=useState({})//页数及数量
function hqlb(i){
  
//  return
// 
    // if(i==1){
    //   usesj(xsj)  
    // }else{
    //   usesj(xsjs)  

    // }
    getQuestionsLists(`page=${i}&pageSize=10&activity_id=${pageid}`).then((res)=>{
      // console.log(res)
      usesj(res.data.list)  
      smath(res.data)
      usejz(false)
      if(res.data.list.length==0 && i!=1){
        hqlb(1)
      }
    })

// 必须用深拷贝来改变赋值
// let sjs = JSON.parse(JSON.stringify(sj))
// console.log(sj)
// usesj(sjs)  
// setTimeout(()=>{
// usejz(false)
// },1000)

}

let [dqym,sdqym]=useState(1) //当前页面的索引

// 筛选或分页的时候触发
function onChanges(e,i,s,c){
  // console.log(e,i,s,c)
  if(c.action=="paginate"){
    sdqym(e.current)
// let ysj=[1]
ssel([])
// if(ys.indexOf(e.current)==-1){
  hqlb(e.current)
//   ys.push(e.current)
//   uys(ys)
//   console.log(ys)
// }else{
//   usejz(false)
// }
// console.log(666,e.current,i)
  }else{
    // console.log("筛选")
    usejz(false)
    // console.log(66666,sj)
  }
}
let [sel,ssel]=useState([]) //选中的数据

let [scjz,sscjz]=useState(false) //选中的数据
// let []=useState()
//多选是的时候触发
function onSelectChange(i,w){
  // console.log(i,w)
  ssel(i)


}

// 点击删除按钮
function start(){
    // console.log(sel)
    // console.log(sel.toString())
  // return
  questionDelete(`id=${sel.toString()}&activity_id=${pageid}`).then((res)=>{
        // console.log(res)
        if(res.code==0){
          refresh()
          success("批量删除成功")
          
        }
      })

sscjz(true)
  setTimeout(() => {
    sscjz(false)

  }, 1000);
}



const rowSelection = {
  selectedRowKeys:sel,
  onChange:onSelectChange,

}; 
const hasSelected = sel.length > 0;
  return (
      <div className='body' >
 {/* <Breadcrumb separator=">" style={{position:"relative",top:"-10px"}}>
    <Breadcrumb.Item>
      <a  onClick={()=>{
        navigate(`/background/activity`)
      }}>活动列表</a>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      <a 
      style={{display:"inline-block", maxWidth:"400px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:'hidden',position:"relative",top:"6px"}}
      >{pagename}</a>
    </Breadcrumb.Item>
    
  </Breadcrumb> */}

              {/* 活动规则弹窗3 */}
      <Modal
          title={`活动规则`}
          style={{ top: '10%' }}
          visible={hdgz}
          closable={false}
          footer={[<div key={1} style={{padding:0}}>
           <Button type="primary" style={{  }} onClick={() => {
            //  console.log(hdnr)
             if(hdnr!=""){
              explainUpdate(`explain=${hdnr}&activity_id=${pageid}`).then((res=>{
                // console.log(res)
              
                  if(res.code==0){
                    hdhq()
                    success("活动规则修改成功")
                    shdgz(false)
                }
              }))
             }else{
              error("未输入，保存失败")
             }
           }}>
            保存
          </Button>  
            <Button type="primary" style={{  }} onClick={() => shdgz(false)}>
            关闭
          </Button>  
          </div>]}
        >
            <TextArea rows={4} placeholder="请输入活动规则" value={hdnr} onChange={(e)=>{ shdnr(e.target.value)
            }} />
        
        </Modal>
      {/* 更改分数弹窗弹窗2 */}
      <Modal
          title={`正在更改 (${tcnr.title}) 的分数`}
          style={{ top: '10%' }}
          visible={tcf}
          closable={false}
          footer={[<div key={1} style={{padding:0}}>
           <Button type="primary" style={{  }} onClick={() => {
            //  console.log(grade)
             if(grade!=""){
              questionsScoreUpdate(`id=${tcnr.id}&score=${grade}&activity_id=${pageid}`).then((res=>{
                // console.log(res)
              
                  if(res.code==0){
                    refresh()
                    success("修改分数成功")
                    stcf(false)
                }
              }))
             }else{
              error("分数未输入，保存失败")
             }
           }}>
            保存
          </Button>  
            <Button type="primary" style={{  }} onClick={() => stcf(false)}>
            关闭
          </Button>  
          </div>]}
        >
          <Input placeholder="分数" type='number' value={grade} onChange={(e)=>{ sgrade(e.target.value)
            }}  />
        {/* <div  style={{marginTop:"18px"}}>分值{tcnr.title}</div> */}
        
        </Modal>


      {/* 查看选项弹窗1 */}
        <Modal
          title={tcnr.title}
          style={{ top: '10%' }}
          visible={tc}
          closable={false}
          footer={[<div key={1} style={{padding:0}}>
            <Button type="primary" style={{  }} onClick={() => stc(false)}>
            关闭
          </Button>  
          </div>]}
        >
    

                {tcnr.option_a!=null && <div  style={{marginBottom:"6px"}}>A:{tcnr.option_a}</div>} 
                {tcnr.option_b!=null && <div  style={{marginBottom:"6px"}}>B:{tcnr.option_b}</div>}       
                {tcnr.option_c!=null && <div  style={{marginBottom:"6px"}}>C:{tcnr.option_c}</div>}       
                {tcnr.option_d!=null && <div  style={{marginBottom:"6px"}}>D:{tcnr.option_d}</div>}       
                {tcnr.option_e!=null && <div  style={{marginBottom:"6px"}}>E:{tcnr.option_e}</div>}       
  

        <div style={{marginTop:"18px",fontWeight:"bold"}}>正确答案：{tcnr.true}</div>
        <div style={{marginTop:"18px"}}><span style={{fontWeight:"bold"}}>解析：</span>{tcnr.analysis}</div>
        {/* <div  style={{marginTop:"18px"}}>分值{tcnr.score}</div> */}
        </Modal>
      {/* 顶部的按钮 */}
          <div style={{ marginBottom: 10,marginTop: 10,position:"relative" }}>
          <Button type="primary" onClick={start} disabled={!hasSelected} loading={scjz}>
            删除
          </Button>

          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `选中了${sel.length}条` : ''}
          </span>
          <Button type="primary" style={{ marginLeft: 50 }} onClick={()=>{navigate(`/background/details/compile?keid=${pageid}&name=${paname}`)}}>
            添加
          </Button>         
           <Button type="primary" style={{ marginLeft: 20}}  onClick={()=>{navigate(`/background/details/configuration/question?keid=${pageid}&name=${paname}`);}}>
            题库导入
          </Button>
        <div style={{position:'relative',display:'inline-block',color:'red',marginLeft:'10px',fontSize:"13px",}}>注意：记得设置题目的 <span style={{fontWeight:'bold'}}>分值</span> ，如果没有设置分值前台是获取不到题目的。</div>

          {/* <Button type="primary" style={{ marginLeft: 20}}  onClick={()=>{shdgz(true)}}>
            活动规则
          </Button> */}
          {/* <h2 style={{position:"absolute",right:0,top:0,fontWeight:"bold",color:""}}>(<span style={{display:"inline-block", maxWidth:"189px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:'hidden',position:"relative",top:"8px"}}>{pagename}</span>)配置列表</h2> */}
          {/* <h2 style={{position:"absolute",right:0,top:0,fontWeight:"bold",color:""}}>配置列表</h2> */}
        
        </div>
        {/* 表格数据 */}
<ConfigProvider  locale={zhCN}>
    <Table 
    columns={columns} 
    dataSource={sj} 
    scroll={{ y:gao}}  
    className="fromt"
    loading={jz}
    pagination={{ pageSize:10,total:math.allCounts,showTotal:total=> `共 ${math.allCounts} 条数据`,
    current:dqym
  }}
    onChange={(e,i,s,c)=>{usejz(true);  onChanges(e,i,s,c)}}
    rowSelection={rowSelection} 

    rowKey="id"
    />
    </ConfigProvider >






    <Routes>
            <Route path='/question' element={<Question/>}/>
        </Routes>
      {/* </BrowserRouter> */}
 <Outlet/>
    </div>
      )
}

// ReactDOM.render(
  
//   mountNode,
// );

